import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {dealsRoutes} from './constants';


const Deals: FC = () => {
    return useRoutes(dealsRoutes);
};

export {Deals};
export default Deals;
