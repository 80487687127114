import {all, call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {getFullSiteListRequest, getOfferRequest, getRequestRequest, MainRoutesEnum, notificationListPushItem, PlacementRoutesPathEnum, setInitialAttachments} from 'core';
import {i18n} from 'i18n';
import {history} from 'index';
import {GtagCategoryEnum, GtagNameEnum, RequestPayloadType} from 'typings';
import {getEventDimension} from 'utils';
import {OfferListResponseInterface, RequestListResponseInterface} from './typings';
import {deleteOfferRequest, deleteRequestRequest, getOfferListRequest, getRequestListRequest, patchOfferRequest, patchRequestRequest, postOfferRequest, postRequestRequest} from './placements.api';
import {
    deleteOffer,
    deleteRequest,
    getOfferData,
    getOfferList,
    getRequestData,
    getRequestList,
    hidePlacementError,
    hidePlacementLoader,
    patchOffer,
    patchRequest,
    postOffer,
    postRequest,
    setOffer,
    setOfferList,
    setOfferListCount,
    setPlacementFormErrors,
    setRequest,
    setRequestList,
    setRequestListCount,
    setWebsiteList,
    showPlacementError,
    showPlacementLoader,
} from './placements.reducer';


function* deleteOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        yield call(deleteOfferRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been deleted successfully'),
            theme: ENotificationsThemes.success,
        }));
        yield put(getOfferList({queryParams: payload.payload.queryParams, controller: new AbortController()}));
    } finally {
        yield put(hidePlacementLoader());
    }
}

function* deleteRequestWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        yield call(deleteRequestRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been deleted successfully'),
            theme: ENotificationsThemes.success,
        }));
        yield put(getRequestList({queryParams: payload.payload.queryParams, controller: new AbortController()}));
    } finally {
        yield put(hidePlacementLoader());
    }
}

function* getOfferDataWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };

        const [websites, offerData] = yield all([
            call(getFullSiteListRequest, {}),
            ...(payload.payload.placementId ? [payload.payload.placementId && call(getOfferRequest, requestParams)] : []),
        ]);
        yield put(setWebsiteList(websites.results));
        yield put(setOffer(offerData));
        yield put(setInitialAttachments({attachments: offerData.uploaded_attachments}));
    } catch (error) {

    } finally {
        yield put(hidePlacementLoader());
    }
}

function* getOfferListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hidePlacementError());
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data: OfferListResponseInterface = yield call(getOfferListRequest, requestParams);
        if (data) {
            yield put(setOfferListCount(data.count));
            yield put(setOfferList(data.results));
            yield put(hidePlacementLoader());
        }
    } catch (error) {
        yield put(showPlacementError());
        yield put(hidePlacementLoader());
    }
}

function* getRequestDataWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };

        const [websites, requestData] = yield all([
            call(getFullSiteListRequest, {}),
            ...(payload.payload.placementId ? [payload.payload.placementId && call(getRequestRequest, requestParams)] : []),
        ]);
        yield put(setWebsiteList(websites.results));
        yield put(setRequest(requestData));
        yield put(setInitialAttachments({attachments: requestData.uploaded_attachments}));
    } catch (error) {

    } finally {
        yield put(hidePlacementLoader());
    }
}

function* getRequestListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hidePlacementError());
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data: RequestListResponseInterface = yield call(getRequestListRequest, requestParams);
        if (data) {
            yield put(setRequestListCount(data.count));
            yield put(setRequestList(data.results));
            yield put(hidePlacementLoader());
        }
    } catch (error) {
        yield put(showPlacementError());
        yield put(hidePlacementLoader());
    }
}

function* editOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        // yield put(clearPlacementFormErrors());//???
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
            data: payload.payload.data,
        };
        yield call(patchOfferRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been changed successfully'),
            theme: ENotificationsThemes.success,
        }));
        history.push(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_LIST}?status=active`);

    } catch (error) {
        yield put(setPlacementFormErrors(error));
    } finally {
        yield put(hidePlacementLoader());
    }
}

function* editRequestWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        // yield put(clearPlacementFormErrors());//???
        yield put(showPlacementLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
            data: payload.payload.data,
        };
        yield call(patchRequestRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been changed successfully'),
            theme: ENotificationsThemes.success,
        }));
        history.push(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}?status=active`);

    } catch (error) {
        yield put(setPlacementFormErrors(error));
    } finally {
        yield put(hidePlacementLoader());
    }
}

function* createOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        // yield put(clearPlacementFormErrors());//???
        yield put(showPlacementLoader());

        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        const offer = yield call(postOfferRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been added successfully'),
            theme: ENotificationsThemes.success,
        }));
        gtag('event', `${GtagNameEnum.CREATE_OFFER}${GtagNameEnum.WEBMASTER_OFFERS} - create success`, getEventDimension(GtagCategoryEnum.OFFERS, `${offer.name} - ${offer.id}`));
        history.push(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_LIST}?status=active`);

    } catch (error) {
        yield put(setPlacementFormErrors(error));
    } finally {
        yield put(hidePlacementLoader());
    }
}

function* createRequestWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        // yield put(clearPlacementFormErrors());//???
        yield put(showPlacementLoader());

        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        const request = yield call(postRequestRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Offer has been added successfully'),
            theme: ENotificationsThemes.success,
        }));
        gtag('event', `${GtagNameEnum.CREATE_OFFER}${GtagNameEnum.ADVERTISER_OFFERS} - create success`, getEventDimension(GtagCategoryEnum.OFFERS, `${request.name} - ${request.id}`));
        history.push(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}?status=active`);

    } catch (error) {
        yield put(setPlacementFormErrors(error));
    } finally {
        yield put(hidePlacementLoader());
    }
}


export function* offersWatcher() {
    yield takeEvery(deleteOffer.type, deleteOfferWorker);
    yield takeEvery(deleteRequest.type, deleteRequestWorker);
    yield takeEvery(getOfferData.type, getOfferDataWorker);
    yield takeEvery(getOfferList.type, getOfferListWorker);
    yield takeEvery(getRequestData.type, getRequestDataWorker);
    yield takeEvery(getRequestList.type, getRequestListWorker);
    yield takeEvery(patchOffer.type, editOfferWorker);
    yield takeEvery(patchRequest.type, editRequestWorker);
    yield takeEvery(postOffer.type, createOfferWorker);
    yield takeEvery(postRequest.type, createRequestWorker);
}
