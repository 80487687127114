import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {DealBtnConfirmModalComponentInterface} from '../../typings';


export const AcceptDealModal: FC<DealBtnConfirmModalComponentInterface> = ({handleCancelClick, handleConfirmClick, setShowModal, showModal}) => {
    const {t} = useTranslation();


    return (
        <Modal title={t('Are you sure you want to close the deal?')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <p>{t(`By accepting the executor's work, you are closing the deal. We'll transfer the funds on-hold in your account as payment for the services received.`)}</p>
            <div className="controls controls_align_justify">
                <Button theme={'stroke'} onClick={handleCancelClick} textContent={t('Back')}/>
                <Button theme={'confirm'} onClick={handleConfirmClick} textContent={t('Yes, accept')}/>
            </div>
        </Modal>
    );
};
