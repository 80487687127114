import {BalanceHistoryInterface, FinanceStoreInterface, PaymentRequestInterface} from './typings';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const defaultFinanceState: FinanceStoreInterface = {
    balanceHistoryList: [],
    isError: false,
    isLoading: false,
    isBtnLoading: false,
    isFormLoading: false,
    paymentRequestsList: [],
};

export const financeSlice = createSlice({
    name: 'finance',
    initialState: defaultFinanceState,
    reducers: {
        resetFinanceToInitialState: () => defaultFinanceState,
        hideFinanceError: state => ({...state, isError: false}),
        hideFinanceBtnLoader: state => ({...state, isBtnLoading: false}),
        hideFinanceFormLoader: state => ({...state, isFormLoading: false}),
        hideFinanceLoader: state => ({...state, isLoading: false}),
        showFinanceError: state => ({...state, isError: true}),
        showFinanceBtnLoader: state => ({...state, isBtnLoading: true}),
        showFinanceFormLoader: state => ({...state, isFormLoading: true}),
        showFinanceLoader: state => ({...state, isLoading: true}),
        setFinanceShowForm: (state, action: PayloadAction<boolean>) => ({...state, isShowForm: action.payload}),
        setBalanceTransactionList: (state, action: PayloadAction<BalanceHistoryInterface[]>) => ({...state, balanceHistoryList: action.payload}),
        setBalanceTransactionListCount: (state, action: PayloadAction<number>) => ({...state, balanceHistoryCount: action.payload}),
        setBalanceTransactionRequestsList: (state, action: PayloadAction<PaymentRequestInterface[]>) => ({...state, paymentRequestsList: action.payload}),
        setBalanceTransactionRequestsListCount: (state, action: PayloadAction<number>) => ({...state, paymentRequestsCount: action.payload}),
        setFinanceFormErrors: (state, action: PayloadAction<{ [key: string]: string[]; }>) => ({...state, formErrors: action.payload}),
        clearFinanceFormErrors: (state, action: PayloadAction<string>) => {
            const controlName = action.payload;
            const newFormError = controlName ? {...state.formErrors} : {};
            delete newFormError?.[controlName];
            return {...state, formErrors: newFormError};
        },

        getBalanceTransactionList(state, action: PayloadAction<{ queryParams: URLSearchParams, controller: AbortController }>) {},
        getBalanceTransactionRequestsList(state, action: PayloadAction<{ queryParams: URLSearchParams, controller: AbortController }>) {},
        postBalanceTransactionRequests(state, action: PayloadAction<{ data: any; updateDataMethod?: any; }>) {},
        postRegisterWithdrawal(state, action: PayloadAction<{ updateDataMethod?: any; }>) {},
    },
});

export const {
    clearFinanceFormErrors,
    getBalanceTransactionList,
    getBalanceTransactionRequestsList,
    hideFinanceBtnLoader,
    hideFinanceError,
    hideFinanceFormLoader,
    hideFinanceLoader,
    postBalanceTransactionRequests,
    postRegisterWithdrawal,
    resetFinanceToInitialState,
    setBalanceTransactionList,
    setBalanceTransactionListCount,
    setBalanceTransactionRequestsList,
    setBalanceTransactionRequestsListCount,
    setFinanceFormErrors,
    setFinanceShowForm,
    showFinanceError,
    showFinanceBtnLoader,
    showFinanceFormLoader,
    showFinanceLoader,
} = financeSlice.actions;

export const financeReducer = financeSlice.reducer;
