import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames/bind';
import {Dropdown, ESearchMainIcons, Search} from '@design-system/ui-kit';
import {ReactComponent as FilterIcon} from 'assets/images/icons/filter.svg';
import {Button} from 'components';
import {useMedia} from 'hooks';
import filterSearchSortStyle from './FilterSearchSort.module.scss';
import {SearchBlockComponentInterface} from './SearchBlockComponent.interface';


export const FilterSearchSortComponent: FC<SearchBlockComponentInterface> = ({AdditionalFilterComponent, filter, isSeparate, search, sort}) => {
    const {t} = useTranslation();
    const isNarrowScreen = useMedia();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const handleFilterToggleClick = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const fssBlockClassName = classNames(
        filterSearchSortStyle.fssBlock,
        {
            [filterSearchSortStyle.fssBlock_withAdditionalFilter]: !!AdditionalFilterComponent,
            [filterSearchSortStyle.fssBlock_separate]: isSeparate,
        },
    );


    return (
        <div className={fssBlockClassName}>
            {/*TODO: Dropdown className */}
            <Dropdown className={filterSearchSortStyle.fssBlockSort}
                      value={sort.value || ''}
                      placeholder={sort.placeholder || t('Sort by')}
                      uncheckOptionByClick
                      optionsListMaxHeight={sort.options?.length ? 300 : undefined}
                      onChange={sort.onChange}
                      options={sort.options}/>
            {isNarrowScreen && <Button className={filterSearchSortStyle.fssBlockFilterBtn}
                                       Icon={FilterIcon}
                                       type="button"
                                       textContent={t('Filters')}
                                       onClick={handleFilterToggleClick}/>}
            {!!AdditionalFilterComponent && <AdditionalFilterComponent className={filterSearchSortStyle.fssBlockSearch}/>}
            {!!search && <Search className={filterSearchSortStyle.fssBlockSearch}
                                 value={search.value || ''}
                                 onChange={search.onChange}
                                 debounceTime={300}
                                 placeholder={search.placeholder || t('Search')}
                                 mainIconName={ESearchMainIcons.search}/>}
            {isNarrowScreen && isFilterOpen && <div className={filterSearchSortStyle.fssBlockFilter}>{filter.component}</div>}
        </div>
    );
};
