import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, LoaderWithOverlay} from 'components';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, UserInterface, UserRoleEnum} from 'typings';
import {setCurrentStep} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {StepEnum} from '../../typings';
import {ProgressComponent} from '../progress/Progress.component';
import {SiteFormComponent} from '../siteForm/SiteForm.component';


export const Step1FormComponent: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {isLoading, formErrors, role} = useSelector((state: RootState) => state.onboardingReducer);


    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };


    return (
        <>
            <h1 className={`textHeaderMedium_05 ${onboardingStyle.title}`}>{t('Slow and steady wins the race')}</h1>
            <ProgressComponent step={1}/>
            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{t('Let\'s create a site for you')}</h2>
            <p className={onboardingStyle.text}>{t('Please fill out the required fields. You can always edit site info later.')}</p>

            <SiteFormComponent nextStep={StepEnum.STEP_2}/>

            <div className={`controls controls_align_justify ${onboardingStyle.controls}`}>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="back button"
                        eventName={`${user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer ? 'new website' : role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - add website`}
                        onClick={handleToggleStepClick(user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer ? StepEnum.WELCOME : StepEnum.WELCOME_USER)}
                        textContent={t('Back')}
                        theme={'stroke'}/>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="skip button"
                        eventName={`${user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer ? 'new website' : role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - add website`}
                        onClick={handleToggleStepClick(StepEnum.STEP_2)}
                        textContent={t('Skip')}
                        theme={'ghost_cancel'}/>
                <Button disabled={!!Object.keys(formErrors).length}
                        eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="next button"
                        eventName={`${user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer ? 'new website' : role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - add website`}
                        form={'onboardingSiteCreation'}
                        textContent={t('Next')}
                        type={'submit'}/>
            </div>
            {isLoading && <LoaderWithOverlay/>}
        </>
    );
};
