import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'components';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, UserInterface, UserRoleEnum} from 'typings';
import {setCurrentStep, setMethod} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {SiteTransferMethodEnum, StepEnum} from '../../typings';
import {OnboardingHeaderComponent} from '../header/OnboardingHeader.component';
import {RadioGroupComponent} from '../radioGroup/RadioGroup.component';


export const WelcomeUserComponent: FC = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {method, role} = useSelector((state: RootState) => state.onboardingReducer);

    const inputData = {
        [UserRoleEnum.IMPLEMENTER]: [{
            label: t('Easy Start'),
            note: t('We’ll do everything for you, including moving your programs. You will just need to double-check everything and click the button.'),
            inputValue: SiteTransferMethodEnum.AUTO,
        }, {
            label: t('Your Way'),
            note: t('Together, we will create a new site that will help you communicate with advertisers.'),
            inputValue: SiteTransferMethodEnum.MANUAL,
        }],
        [UserRoleEnum.PAYER]: [{
            label: t('Easy Start'),
            note: t('We’ll do everything for you, including moving your programs. You will just need to double-check everything and click the button.'),
            inputValue: SiteTransferMethodEnum.AUTO,
        }, {
            label: t('Your Way'),
            note: t('Together, we will create a new site that will help you communicate with publishers.'),
            inputValue: SiteTransferMethodEnum.MANUAL,
        }],
    };

    const welcomeText = {
        [UserRoleEnum.IMPLEMENTER]: {
            headerText: t('A unique service that helps publishers monetize traffic using non-CPA models.'),
        },
        [UserRoleEnum.PAYER]: {
            headerText: t('A unique service that helps publishers select tailored placements that can cover their marketing demands.'),
        },
    };


    const changeMethod = (value) => () => {
        dispatch(setMethod(value));
    };

    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };

    const handleSkipClick = () => {
        dispatch(setMethod(undefined));
        handleToggleStepClick(StepEnum.STEP_2)();
    };


    useEffect(() => {
        if (method) return;
        changeMethod(SiteTransferMethodEnum.AUTO)();
    }, []);


    return (
        <>
            <OnboardingHeaderComponent text={welcomeText[role || UserRoleEnum.IMPLEMENTER].headerText}/>
            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{t('Shall we get started, {{name}}?', {name: user.first_name})}</h2>
            <p className={onboardingStyle.text}>{t('We’ve prepared something and are ready to move your site parameters from your Admitad Affiliate profile')}</p>
            <p className={onboardingStyle.subtext}>{t('Select the best option')}:</p>

            <RadioGroupComponent inputData={inputData[role || UserRoleEnum.IMPLEMENTER]} name="method" onChange={changeMethod} value={method}/>

            <div className={`controls controls_align_justify ${onboardingStyle.controls}`}>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME} ${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'}`}
                        eventLabel="back button"
                        eventName={`${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - step 2`}
                        onClick={handleSkipClick}
                        textContent={t('Skip')}
                        theme={'ghost_cancel'}/>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME} ${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'}`}
                        eventLabel={method === SiteTransferMethodEnum.MANUAL ? 'create new website' : 'move website'}
                        eventName="choose way"
                        onClick={handleToggleStepClick(method === SiteTransferMethodEnum.MANUAL ? StepEnum.STEP_1_FORM : StepEnum.STEP_1)}
                        textContent={t('Next')}/>
            </div>
        </>
    );
};
