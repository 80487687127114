import React, {FC} from 'react';
import classNames from 'classnames/bind';
import avatarStyle from './Avatar.module.scss';
import {AvatarComponentInterface} from './AvatarComponent.interface';


export const AvatarComponent: FC<AvatarComponentInterface> = ({className, isImplementer, isResizable, isSystem, size = 'l', text}) => {
    const avatarClassName = classNames(
        className,
        avatarStyle.avatar,
        {[avatarStyle.avatar_implementer]: isImplementer},
        {[avatarStyle.avatar_payer]: isImplementer === false},
        {[avatarStyle.avatar_system]: isSystem},
        {[avatarStyle.avatar_resizable]: isResizable},
        [avatarStyle[`avatar_size_${size}`]],
    );


    return (
        <span className={avatarClassName}>
        {text}
    </span>
    );
};
