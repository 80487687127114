import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {PlacementTabComponent} from 'components';
import {CatalogRoutesPathEnum} from 'core/Routes/typings/enums/CatalogRoutesPath.enum';
import {PlacementType} from 'typings';
import {WebsiteForPlacementCardComponent} from '../WebsiteForPlacementCard/WebsiteForPlacementCard.component';
import {AsideShortInfoComponent} from '../asideShortInfo/AsideShortInfo.component';


export const PlacementRouter: FC<{ attachmentsLink?: string; isRequest?: boolean; placement: PlacementType; }> = (props) => {
    const RoutesConfig = [
        {
            path: `${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`,
            index: true,
            element: <PlacementTabComponent {...props}><AsideShortInfoComponent {...props}/></PlacementTabComponent>,
        },
        {
            path: `${CatalogRoutesPathEnum.CATALOG_USER_INFO}`,
            index: true,
            element: <WebsiteForPlacementCardComponent/>,
        },
        {
            path: '/*',
            element: <PlacementTabComponent {...props}><AsideShortInfoComponent {...props}/></PlacementTabComponent>,
        },
    ];


    return useRoutes(RoutesConfig);
};
