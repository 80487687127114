import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {DealInterface, DealStatusEnum} from 'typings';
import lastOfferStyle from './DealLastOffer.module.scss';


export const DealLastOfferComponent: FC<{ deal: DealInterface; }> = ({deal}) => {
    const {t} = useTranslation();


    return (
        <div className={`textBody_02 ${lastOfferStyle.lastOffer}`}>
            {deal.status === DealStatusEnum.CLOSED ? <span className={lastOfferStyle.lastOffer__completed}>{t('Closed')}</span> : <>
                {(deal.is_approved_by_implementer !== deal.is_approved_by_payer)
                    ? <>
                        {t('The last offer')}:&ensp;
                        <span className={deal.proposed_conditions.is_created_by_implementer ? lastOfferStyle.lastOffer__implementer : lastOfferStyle.lastOffer__payer}>
                            {deal.proposed_conditions.is_created_by_implementer ? t('Publisher') : t('Advertiser')}
                        </span>
                    </>
                    : t('No proposals')
                }
            </>}
        </div>
    );
};
