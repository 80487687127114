import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, ETextAreaThemes, IDropdownOption, Input, Modal, TDropdownOptionValue, TextArea, ValidationTooltipWrapper} from '@design-system/ui-kit';
import {Button, DatePickerInputComponent, FileDropComponent, LoaderWithOverlay, TotalBlockComponent} from 'components/index';
import {ModalContentIdConst, nonFieldErrorsKey, WrapperPageContainerIdConst} from 'consts';
import {clearAttachments, clearDealRepeatedFormError, deleteAttachment, repeatDeal, RootState, uploadFile} from 'core';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, PromotionalDateInterface, RepeatDealControlsEnum, RepeatDealFormStateInterface, UploadedAttachmentInterface} from 'typings';
import repeatDealModalStyle from './RepeatDealModal.module.scss';
import {RepeatDealModalComponentInterface} from './RepeatDealModalComponent.interface';


export const RepeatDealModalComponent: FC<RepeatDealModalComponentInterface> = ({dealId, isImplementer, partnerName, setShowModal, showModal}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [priceControlName, setPriceControlName] = useState<RepeatDealControlsEnum>(RepeatDealControlsEnum.PRICE);


    const nullDate: PromotionalDateInterface = {
        [RepeatDealControlsEnum.PLACEMENT_START_DATE]: null,
        [RepeatDealControlsEnum.PLACEMENT_END_DATE]: null,
    };

    const initFormState: RepeatDealFormStateInterface = {
        [RepeatDealControlsEnum.ATTACHMENTS]: [],
        [RepeatDealControlsEnum.CURRENCY]: '',
        [RepeatDealControlsEnum.DESCRIPTION]: '',
        [RepeatDealControlsEnum.NAME]: '',
        [RepeatDealControlsEnum.PLACEMENT_END_DATE]: null,
        [RepeatDealControlsEnum.PLACEMENT_START_DATE]: null,
        [RepeatDealControlsEnum.PRICE]: '',
        [RepeatDealControlsEnum.PRICE_WITH_COMMISSION]: '',
    };
    const {repeatedFormErrors, files, isLoading} = useSelector((state: RootState) => state.commonReducer);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const currencies: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('currencies');
    const [currencyForDropdown, setCurrencyForDropdown] = useState<IDropdownOption[]>([]);
    const [date, setDate] = useState<PromotionalDateInterface>(nullDate);
    const [formState, setFormState] = useState<RepeatDealFormStateInterface>(initFormState);


    useEffect(() => () => {
        dispatch(clearAttachments());
    }, [dispatch]);

    useEffect(() => {
        handleInputChange(RepeatDealControlsEnum.ATTACHMENTS);
    }, [files]);

    useEffect(() => {
        if (showModal) return;
        dispatch(clearDealRepeatedFormError());
    }, [showModal]);

    useEffect(() => {
        setPriceControlName(isImplementer ? RepeatDealControlsEnum.PRICE_WITH_COMMISSION : RepeatDealControlsEnum.PRICE);
    }, [isImplementer]);

    useEffect(() => {
        setCurrencyForDropdown(currencies);
    }, [currencies]);

    useEffect(() => {
        setFormState({
            ...formState,
            [RepeatDealControlsEnum.CURRENCY]: `${currencyForDropdown?.[0]?.value}`,
        });
    }, [currencyForDropdown]);


    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleFormSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        const formData = formState;
        formData[RepeatDealControlsEnum.PLACEMENT_START_DATE] = date[RepeatDealControlsEnum.PLACEMENT_START_DATE]?.toLocaleDateString('en-CA') || null;
        formData[RepeatDealControlsEnum.PLACEMENT_END_DATE] = date[RepeatDealControlsEnum.PLACEMENT_END_DATE]?.toLocaleDateString('en-CA') || null;
        formData[RepeatDealControlsEnum.ATTACHMENTS] = Object.values(files).filter(file => file.meta.success).map(fileData => (fileData.uploadedAttachments as UploadedAttachmentInterface).file_id);
        return dispatch(repeatDeal({dealId, data: formData}));
    };

    const handleInputChange = (controlName: RepeatDealControlsEnum) => {
        dispatch(clearDealRepeatedFormError({inputName: controlName}));
        dispatch(clearDealRepeatedFormError({inputName: nonFieldErrorsKey}));
    };

    const onInputChange = (controlName: RepeatDealControlsEnum) => (newValue: string | TDropdownOptionValue | null | TDropdownOptionValue[]) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const onDateChange = (value: any, controlName: RepeatDealControlsEnum) => {
        setDate({
            ...date,
            [controlName]: value,
        });
        handleInputChange(controlName);
    };

    const handleDelete = (fileId) => dispatch(deleteAttachment(fileId));
    const handleDrop = (file) => dispatch(uploadFile({file: file}));


    return (
        <Modal active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <p className={`textBody_02 ${repeatDealModalStyle.repeatDealModal__header}`}>
                {t('You’re creating a new deal with')} <span className={repeatDealModalStyle.repeatDealModal__name}>{partnerName}</span>
            </p>
            <form className="form"
                  onSubmit={handleFormSubmit}
                  noValidate>
                <fieldset className="form__blank form__fieldset" id="placement-form-general">
                    <p className="textHeader_06 form__legend">{t('General information')}</p>
                    <Input name={RepeatDealControlsEnum.NAME}
                           placeholder={t('Name')}
                           required
                           value={formState[RepeatDealControlsEnum.NAME]}
                           onChange={onInputChange(RepeatDealControlsEnum.NAME)}
                           errorMessage={repeatedFormErrors?.[RepeatDealControlsEnum.NAME]?.toString()}
                           onBlur={onInputBlur}/>
                    <ValidationTooltipWrapper errorMessage={repeatedFormErrors?.[RepeatDealControlsEnum.DESCRIPTION]?.toString()}>
                        <TextArea placeholder={t('Describe the placement offer')}
                                  value={formState[RepeatDealControlsEnum.DESCRIPTION]}
                                  theme={repeatedFormErrors?.[RepeatDealControlsEnum.DESCRIPTION] ? ETextAreaThemes.error : ETextAreaThemes.base}
                                  className="textarea"
                                  onChange={onInputChange(RepeatDealControlsEnum.DESCRIPTION)}/>
                    </ValidationTooltipWrapper>
                    <div>
                        <p className="form__text">{t('Example of placement')}:</p>
                        <FileDropComponent files={files}
                                           handleDelete={handleDelete}
                                           handleDrop={handleDrop}
                                           hint={t('Maximum upload file size: 50 MB. You can upload no more than 10 files at a time')}
                                           maxFiles={10}/>
                        {repeatedFormErrors?.[RepeatDealControlsEnum.ATTACHMENTS] && (<p className="form__error">{repeatedFormErrors?.[RepeatDealControlsEnum.ATTACHMENTS]}</p>)}
                    </div>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-form-period">
                    <p className="textHeader_06 form__legend">{t('Placement time')}</p>
                    <p className="form__text">{t('If you need your ad to be placed on exact dates, please specify them. If no dates are selected, placement will be on an ongoing basis.')}</p>
                    <div className="form__lineRange">
                        <DatePickerInputComponent inputName={RepeatDealControlsEnum.PLACEMENT_START_DATE}
                                                  onInputChange={onInputChange(RepeatDealControlsEnum.PLACEMENT_START_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, RepeatDealControlsEnum.PLACEMENT_START_DATE)}
                                                  inputValue={date[RepeatDealControlsEnum.PLACEMENT_START_DATE]}
                                                  inputPlaceholder={t('Start date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={repeatedFormErrors?.[RepeatDealControlsEnum.PLACEMENT_START_DATE]?.toString()}/>
                        —
                        <DatePickerInputComponent inputName={RepeatDealControlsEnum.PLACEMENT_END_DATE}
                                                  onInputChange={onInputChange(RepeatDealControlsEnum.PLACEMENT_END_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, RepeatDealControlsEnum.PLACEMENT_END_DATE)}
                                                  inputValue={date[RepeatDealControlsEnum.PLACEMENT_END_DATE]}
                                                  inputPlaceholder={t('End date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={repeatedFormErrors?.[RepeatDealControlsEnum.PLACEMENT_END_DATE]?.toString()}/>
                    </div>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-form-payment">
                    <p className="textHeader_06 form__legend">{t('Financial terms')}</p>
                    <div className="form__lineWithEndContent">
                        <Input type="number"
                               name={priceControlName}
                               placeholder={isImplementer ? t('Offer price (incl. fee)') : t('Offer price (excl. fee)')}
                               required
                               value={formState[priceControlName] as string}
                               onChange={onInputChange(priceControlName)}
                               errorMessage={repeatedFormErrors?.[priceControlName]?.toString()}
                               onBlur={onInputBlur}/>
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[RepeatDealControlsEnum.CURRENCY]}
                                      placeholder={t('Currency')}
                                      required
                                      uncheckOptionByClick
                                      optionsListMaxHeight={currencyForDropdown.length ? 300 : undefined}
                                      onChange={onInputChange(RepeatDealControlsEnum.CURRENCY)}
                                      options={currencyForDropdown}/>
                            {repeatedFormErrors?.[RepeatDealControlsEnum.CURRENCY] && (<p className="form__error">{repeatedFormErrors?.[RepeatDealControlsEnum.CURRENCY]}</p>)}
                        </div>
                    </div>
                    {!!formState[priceControlName] && <TotalBlockComponent
                        amount={isImplementer ? (Math.round(+(formState[priceControlName] as string) / (1 + appConfig.commission_percent) * 100) / 100) : (Math.round(+(formState[priceControlName] as string) * (1 + appConfig.commission_percent) * 100) / 100)}
                        currency={formState[RepeatDealControlsEnum.CURRENCY]}
                        text={isImplementer ? t('Total, excl. {{val}}% fee', {val: appConfig.commission_percent * 100}) : t('Total, incl. {{val}}% fee', {val: appConfig.commission_percent * 100})}/>}

                </fieldset>
                {repeatedFormErrors?.[nonFieldErrorsKey] && (<p className="form__error">{repeatedFormErrors?.[nonFieldErrorsKey]}</p>)}
                <div className="controls controls_align_justify">
                    <Button onClick={handleCancelClick}
                            type="button"
                            theme={'stroke'}
                            textContent={t('Cancel')}/>
                    <Button disabled={!!Object.keys(repeatedFormErrors || {}).length}
                            textContent={t('Add deal')}/>
                </div>
            </form>
            {isLoading && <LoaderWithOverlay/>}
        </Modal>
    );
};
