import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {nonFieldErrorsKey} from 'consts';
import {FileInterface, OfferInterface, RequestInterface, WebsiteInterface} from 'typings';
import {OfferFormControlsEnum, PlacementStoreInterface, RequestFormControlsEnum} from './typings';


const defaultPlacementState: PlacementStoreInterface = {
    currentOffer: {} as OfferInterface,
    currentRequest: {} as RequestInterface,
    formErrors: {},
    isError: false,
    isLoading: false,
    offerList: [],
    requestList: [],
    websiteList: [],
};


export const placementSlice = createSlice({
    name: 'placement',
    initialState: defaultPlacementState,
    reducers: {
        resetPlacementToInitialState: () => defaultPlacementState,
        showPlacementError: state => ({...state, isError: true}),
        hidePlacementError: state => ({...state, isError: false}),
        showPlacementLoader: state => ({...state, isLoading: true}),
        hidePlacementLoader: state => ({...state, isLoading: false}),
        setOfferList: (state, action: PayloadAction<OfferInterface[]>) => ({...state, offerList: action.payload}),
        setRequestList: (state, action: PayloadAction<RequestInterface[]>) => ({...state, requestList: action.payload}),
        setOfferListCount: (state, action: PayloadAction<number>) => ({...state, offersCount: action.payload}),
        setRequestListCount: (state, action: PayloadAction<number>) => ({...state, requestsCount: action.payload}),
        setWebsiteList: (state, action: PayloadAction<WebsiteInterface[]>) => ({...state, websiteList: action.payload}),
        setOffer: (state, action: PayloadAction<OfferInterface>) => {
            return {...state, currentOffer: action.payload};
        },
        setRequest: (state, action: PayloadAction<RequestInterface>) => {
            return {...state, currentRequest: action.payload};
        },
        setPlacementFormErrors: (state, action: PayloadAction<{ [key: string]: FileInterface; }>) => ({...state, formErrors: action.payload}),
        clearPlacementFormError: (state, action: PayloadAction<OfferFormControlsEnum | RequestFormControlsEnum | typeof nonFieldErrorsKey>) => {
            const controlName: OfferFormControlsEnum | RequestFormControlsEnum | typeof nonFieldErrorsKey = action.payload;
            const newFormError = controlName ? {...state.formErrors} : {};
            delete newFormError?.[controlName];
            return {...state, formErrors: newFormError};
        },

        getOfferList(state, action: PayloadAction<{ queryParams: URLSearchParams, controller: AbortController }>) {},
        getRequestList(state, action: PayloadAction<{ queryParams: URLSearchParams, controller: AbortController }>) {},
        getOfferData(state, action: PayloadAction<{ placementId: string | undefined }>) {},
        getRequestData(state, action: PayloadAction<{ placementId: string | undefined }>) {},
        patchOffer(state, action: PayloadAction<{ data: any; placementId: string; }>) {},
        patchRequest(state, action: PayloadAction<{ data: any; placementId: string; }>) {},
        postOffer(state, action: PayloadAction<{ data: any; }>) {},
        postRequest(state, action: PayloadAction<{ data: any; }>) {},
        deleteOffer(state, action: PayloadAction<{ placementId: string; queryParams: URLSearchParams }>) {},
        deleteRequest(state, action: PayloadAction<{ placementId: string; queryParams: URLSearchParams }>) {},
    },
});

export const {
    clearPlacementFormError,
    deleteOffer,
    deleteRequest,
    getOfferData,
    getOfferList,
    getRequestData,
    getRequestList,
    hidePlacementError,
    hidePlacementLoader,
    patchOffer,
    patchRequest,
    postOffer,
    postRequest,
    resetPlacementToInitialState,
    setOffer,
    setOfferList,
    setOfferListCount,
    setPlacementFormErrors,
    setRequest,
    setRequestList,
    setRequestListCount,
    setWebsiteList,
    showPlacementError,
    showPlacementLoader,
} = placementSlice.actions;

export const placementReducer = placementSlice.reducer;
