import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import confirmModalStyle from './ConfirmOfferModal.module.scss';
import {ConfirmOfferModalComponentInterface} from './ConfirmOfferModalComponent.interface';


export const ConfirmOfferModalComponent: FC<ConfirmOfferModalComponentInterface> = ({currency, endDate, handleCancelClick, handleConfirmClick, price, setShowModal, showModal}) => {
    const {t} = useTranslation();


    return (
        <Modal title={t('Propose terms')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <dl className={confirmModalStyle.confirmModal__dl}>
                <dt className={`textBody_04 ${confirmModalStyle.confirmModal__dt}`}>{t('Amount to be held')}</dt>
                <dd className={`textBody_02 ${confirmModalStyle.confirmModal__dd}`}>
                    <span className={confirmModalStyle.confirmModal__currency}>{currency}</span>
                    &nbsp;
                    <span className={confirmModalStyle.confirmModal__price}>{price}</span>
                </dd>
                {!!endDate && <>
                    <dt className={`textBody_04 ${confirmModalStyle.confirmModal__dt}`}>{t('Execution dates')}</dt>
                    <dd className={`textBody_02 ${confirmModalStyle.confirmModal__dd}`}>{endDate?.toLocaleDateString()}</dd>
                </>}
            </dl>
            <p>{t('Please note that once you make a deal, the deal amount on your balance will be put on hold.')}</p>
            <div className="controls controls_align_justify">
                <Button theme={'stroke'} onClick={handleCancelClick} textContent={t('Back')}/>
                <Button theme={'confirm'} onClick={handleConfirmClick} textContent={t('Confirm')}/>
            </div>
        </Modal>
    );
};
