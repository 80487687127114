import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IDropdownOption} from '@design-system/ui-kit';
import {LinkComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {WebsiteInterface} from 'typings';
import {getStringArrByMatch} from 'utils';
import placementSelectedWebsiteStyle from './PlacementSelectedWebsite.module.scss';


export const PlacementSelectedWebsite: FC<{ selectedWebsite: WebsiteInterface; }> = ({selectedWebsite}) => {
    const {t} = useTranslation();
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');
    const [geos, setGeos] = useState<string>('');
    const [category, setCategory] = useState<string>('');


    useEffect(() => {
        setGeos(getStringArrByMatch(geo, selectedWebsite.geos).join(', '));
        setCategory(getStringArrByMatch(categories, selectedWebsite.categories).join(', '));
    }, [categories, geo, selectedWebsite]);


    return (
        <dl className={placementSelectedWebsiteStyle.placementWebsite}>
            <dt className={placementSelectedWebsiteStyle.placementWebsite__dt}>{t('Countries')}</dt>
            <dd className={placementSelectedWebsiteStyle.placementWebsite__dd}>{geos}</dd>
            {!!selectedWebsite.coverage && <>
                <dt className={placementSelectedWebsiteStyle.placementWebsite__dt}>{t('Coverage, visitors/mo.')}</dt>
                <dd className={placementSelectedWebsiteStyle.placementWebsite__dd}>{selectedWebsite.coverage.toLocaleString()}</dd>
            </>}
            <dt className={placementSelectedWebsiteStyle.placementWebsite__dt}>{t('Type')}</dt>
            <dd className={placementSelectedWebsiteStyle.placementWebsite__dd}>{websiteTypesMap[selectedWebsite.type]?.text}{selectedWebsite.subtype && <>, {websiteSubTypesMap[selectedWebsite.subtype]?.text}</>}</dd>
            <dt className={placementSelectedWebsiteStyle.placementWebsite__dt}>{t('Categories')}</dt>
            <dd className={placementSelectedWebsiteStyle.placementWebsite__dd}>{category}</dd>
            <dt className={placementSelectedWebsiteStyle.placementWebsite__dt}>{t('Site URL')}</dt>
            <dd className={placementSelectedWebsiteStyle.placementWebsite__dd}><LinkComponent href={selectedWebsite.url} target="_blank" textContent={selectedWebsite.url}/></dd>
        </dl>
    );
};
