export enum DealFilterEnum {
    // CREATED_AT__GT = 'created_at__gt',
    // CREATED_AT__LT = 'created_at__lt',
    ORDERING = 'ordering',
    MY_ROLE = 'my_role',
    PLACEMENT_TYPE = 'placement_type',
    PRICE__GT = 'price__gt',
    PRICE__LT = 'price__lt',
    SEARCH = 'search',
    STATE = 'state',
}
