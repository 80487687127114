export enum WebsiteFormControlsEnum {
    CATEGORIES = 'categories',
    COVERAGE = 'coverage',
    DESCRIPTION = 'description',
    GEOS = 'geos',
    IS_ACTIVE = 'is_active',
    LOGO = 'logo',
    NAME = 'name',
    TYPE = 'type',
    SUBTYPE = 'subtype',
    URL = 'url',
}
