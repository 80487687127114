import React, {FC, useEffect, useState} from 'react';
// import {i18n} from 'i18n';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {ETooltipThemes, Tooltip, TooltipPlacements, TooltipShowEvents} from '@design-system/ui-kit';
// import {ReactComponent as Arrow} from 'assets/images/arrow.svg';
import {ReactComponent as AddIcon} from 'assets/images/icons/add.svg';
import {ReactComponent as NotificationIcon} from 'assets/images/notification.svg';
import marketplaceLogo from 'assets/images/marketplace/logo.svg';
import iNeedCollabLogo from 'assets/images/ineedcollab/logo.svg';
import {AvatarComponent, Button} from 'components';
import {addEvent, clearEvents, deleteEvent, MainRoutesEnum, markAllEventsAsRead, markDealAsRead, PlacementRoutesPathEnum, setLastMsg} from 'core';
import {useCommonSelector, useMedia} from 'hooks';
import {AppConfigInterface, BalanceInterface, DealEventsInterface, GtagCategoryEnum, ProjectNameEnum, UserInterface, WebSocketEventsEnum} from 'typings';
import {gtagSendEvent} from 'utils';
import headerStyle from './Header.module.scss';
import {TooltipEventsContentComponent, TooltipUserContentComponent} from './components';


export const HeaderComponent: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const balances: BalanceInterface[] = useCommonSelector<BalanceInterface[]>('balances');
    const socket: WebSocket = useCommonSelector<WebSocket>('socket');
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const dealEventsList: DealEventsInterface[] = useCommonSelector<DealEventsInterface[]>('dealEventsList');
    // const [language, setLanguage] = useState(i18n.language);
    const [createPlacementUrl, setCreatePlacementUrl] = useState<string>();
    const [isCanCreatePlacement, setIsCanCreatePlacement] = useState<boolean>(false);
    const isNarrowScreen = useMedia();

    // const handleOnclick = (e: any) => {
    //     e.preventDefault();
    //     setLanguage(e.target.value);
    //     i18n.changeLanguage(e.target.value);
    //     window.location.reload();
    // };

    const handleMarkAllAsReadOnclick = () => {
        dispatch(markAllEventsAsRead());
        dispatch(clearEvents());
    };

    const handleMsgOnclick = (event: DealEventsInterface) => () => {
        dispatch(markDealAsRead({dealId: event.id}));
        dispatch(deleteEvent(event));
    };


    useEffect(() => {
        if (!socket) return;
        socket.onmessage = (e) => {
            const lastMsg = JSON.parse(e.data).data;
            dispatch(setLastMsg(lastMsg));
            if (lastMsg?.event === WebSocketEventsEnum.NEW_USER_EVENT) {
                dispatch(addEvent(lastMsg.event_payload));
            }
        };
    }, [socket]);

    useEffect(() => {
        if (!user.id) return;
        setIsCanCreatePlacement(user.is_allowed_to_be_implementer || user.is_allowed_to_be_payer);
        setCreatePlacementUrl(`${MainRoutesEnum.PLACEMENTS}${user.is_allowed_to_be_implementer ? PlacementRoutesPathEnum.OFFER_CREATE : PlacementRoutesPathEnum.REQUEST_CREATE}`);
    }, [user]);


    const logoMap = {
        [ProjectNameEnum.I_NEED_COLLAB]: <img src={iNeedCollabLogo} alt="I Need Collab" width="148" height="38"/>,
        [ProjectNameEnum.MARKETPLACE]: <img src={marketplaceLogo} alt="MarketPlace X" width="124" height="38"/>,
    }


    return (
        <header className={headerStyle.header}>
            <div className={headerStyle.header__content}>
                {/*TODO: to*/}
                <Link to={'/'} className={headerStyle.header__logo} onClick={gtagSendEvent('logo button', GtagCategoryEnum.HEADER)}>
                    {logoMap[appConfig.project_name || ProjectNameEnum.MARKETPLACE]}
                </Link>

                {(!isNarrowScreen && isCanCreatePlacement) && <Button to={createPlacementUrl}
                                                                      eventCategory={GtagCategoryEnum.HEADER}
                                                                      eventName="add offer button"
                                                                      Icon={AddIcon}
                                                                      textContent={t('Add offer')}
                                                                      size={'l'}/>}

                <Tooltip content={TooltipEventsContentComponent({handleMarkAllAsReadOnclick, handleMsgOnclick})}
                         placement={TooltipPlacements.bottomEnd}
                         showOn={TooltipShowEvents.click}
                         theme={ETooltipThemes.interfaceOnSurface}
                         noContentPadding
                         shadow>
                    <button className={`${headerStyle.header__toggleTooltipBtn} ${dealEventsList.length ? headerStyle.header__toggleTooltipBtn_withAlert : ''}`}>
                        <NotificationIcon className={headerStyle.header__langIcon}/>
                    </button>
                </Tooltip>

                {/*TODO: lang dropdown*/}
                {/*<Tooltip content={TooltipLangContentComponent({handleOnclick})}*/}
                {/*         placement={TooltipPlacements.bottomEnd}*/}
                {/*         showOn={TooltipShowEvents.click}*/}
                {/*         theme={ETooltipThemes.interfaceOnSurface}*/}
                {/*         shadow>*/}
                {/*    <button className={headerStyle.header__toggleTooltipBtn}>*/}
                {/*        {language}*/}
                {/*        <Arrow className={headerStyle.header__langIcon}/>*/}
                {/*    </button>*/}
                {/*</Tooltip>*/}

                <Tooltip content={TooltipUserContentComponent({balances, user})}
                         placement={TooltipPlacements.bottomEnd}
                         showOn={TooltipShowEvents.click}
                         theme={ETooltipThemes.interfaceOnSurface}
                         noContentPadding
                         shadow>
                    <button className={headerStyle.header__user}>
                        <AvatarComponent text={`${user.first_name?.[0]}${user.last_name?.[0]}`} isSystem size={'m'}/>
                    </button>
                </Tooltip>
            </div>
        </header>
    );
};
