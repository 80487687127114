import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {Catalog, Deals, Finance, Placements, Websites} from 'modules';
import {MainRoutesEnum} from './typings';
import {PageNotFound} from '../PageNotFound';


export const AppRouter: FC = () => {
    const RoutesConfig = [
        {
            path: `${MainRoutesEnum.CATALOG}/*`,
            index: true,
            element: <Catalog/>,
        },
        {
            path: `${MainRoutesEnum.DEALS}/*`,
            index: true,
            element: <Deals/>,
        },
        {
            path: `${MainRoutesEnum.WEBSITES}/*`,
            index: true,
            element: <Websites/>,
        },
        {
            path: `${MainRoutesEnum.PLACEMENTS}/*`,
            index: true,
            element: <Placements/>,
        },
        {
            path: `${MainRoutesEnum.FINANCE}/*`,
            index: true,
            element: <Finance/>,
        },
        {
            path: '/*',
            element: <PageNotFound/>,
        },
    ];

    return useRoutes(RoutesConfig);
};
