import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, FilterChoicesComponent} from 'components';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum, UserInterface} from 'typings';
import {getEventDimension} from 'utils';
import {FinanceFilterEnum, PaymentOperationTypeEnum, PaymentStatusEnum} from '../../typings';
import {FinanceOperationTypeTextConst, FinanceStatusTextConst} from '../../constants';


export const PaymentFilterComponent: FC<{ user: UserInterface; }> = ({user}) => {
    const {t} = useTranslation();
    const statusChoices = [
        {value: PaymentStatusEnum.APPROVED, text: FinanceStatusTextConst[PaymentStatusEnum.APPROVED]},
        {value: PaymentStatusEnum.NEW, text: FinanceStatusTextConst[PaymentStatusEnum.NEW]},
        {value: PaymentStatusEnum.REJECTED, text: FinanceStatusTextConst[PaymentStatusEnum.REJECTED]},
    ];
    const operationTypeChoices = [
        {value: PaymentOperationTypeEnum.TOP_UP, text: FinanceOperationTypeTextConst[PaymentOperationTypeEnum.TOP_UP]},
        {value: PaymentOperationTypeEnum.WITHDRAWAL, text: FinanceOperationTypeTextConst[PaymentOperationTypeEnum.WITHDRAWAL]},
    ];
    const initFilterValue = {
        [FinanceFilterEnum.OPERATION_TYPE]: [],
        [FinanceFilterEnum.PLACEMENT_TYPE]: [],
        [FinanceFilterEnum.STATUS]: [],
    };
    const [filterValue, setFilterValue] = useState<any>(initFilterValue);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setFilterValue({
            ...filterValue,
            [FinanceFilterEnum.OPERATION_TYPE]: searchParams.getAll(FinanceFilterEnum.OPERATION_TYPE),
            [FinanceFilterEnum.PLACEMENT_TYPE]: searchParams.getAll(FinanceFilterEnum.PLACEMENT_TYPE),
            [FinanceFilterEnum.STATUS]: searchParams.getAll(FinanceFilterEnum.STATUS),
        });
    }, [searchParams]);


    const onCheckboxChange = (controlName: string, value: TDropdownOptionValue) => (checked: boolean) => {
        let newValue = filterValue[controlName];
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (checked) {
            newValue.push(`${value}`);
        } else {
            newValue = newValue.filter(v => v !== `${value}`);
        }
        newValue.forEach(item => {
            newSearchParams.append(controlName, `${item}`);
        });
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        const optionsMap = {
            [FinanceFilterEnum.OPERATION_TYPE]: operationTypeChoices,
            [FinanceFilterEnum.STATUS]: statusChoices,
        };
        gtag('event', `${GtagNameEnum.FILTER}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.FINANCE, `${controlName} - ${optionsMap[controlName].find(option => option.value === value).text} - ${checked ? 'active' : 'disable'}`));
    };

    const handleResetFilterClick = (controlName?: string) => () => {
        const newSearchParams = new URLSearchParams(controlName ? searchParams : undefined);
        if (controlName) {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue(controlName
            ? {
                ...filterValue,
                [controlName]: initFilterValue[controlName],
            }
            : initFilterValue);
        setSearchParams(newSearchParams);
    };


    return (
        <>
            {(user.is_allowed_to_be_implementer && user.is_allowed_to_be_payer) && <FilterChoicesComponent choicesList={operationTypeChoices}
                                                                                                           controlName={FinanceFilterEnum.OPERATION_TYPE}
                                                                                                           counter={filterValue[FinanceFilterEnum.OPERATION_TYPE]?.length}
                                                                                                           handleResetFilterClick={handleResetFilterClick(FinanceFilterEnum.OPERATION_TYPE)}
                                                                                                           isFirst
                                                                                                           onCheckboxChange={onCheckboxChange}
                                                                                                           filterLabel={t('Operation')}
                                                                                                           filterValue={filterValue}/>}
            <FilterChoicesComponent choicesList={statusChoices}
                                    controlName={FinanceFilterEnum.STATUS}
                                    counter={filterValue[FinanceFilterEnum.STATUS]?.length}
                                    handleResetFilterClick={handleResetFilterClick(FinanceFilterEnum.STATUS)}
                                    isFirst={!(user.is_allowed_to_be_implementer && user.is_allowed_to_be_payer)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Status')}
                                    filterValue={filterValue}/>
            <Button className="filterControls"
                    eventCategory={GtagCategoryEnum.FINANCE}
                    eventLabel="reset all filters button"
                    eventName={`${GtagNameEnum.FILTER}${GtagNameEnum.FILTER}`}
                    onClick={handleResetFilterClick()}
                    textContent={t('Reset all filters')}
                    theme={'stroke'}/>
        </>
    );
};
