import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import {DealsRoutesPathEnum, FinanceRoutesPathEnum, MainRoutesEnum, PlacementRoutesPathEnum} from 'core';
import {ReactComponent as CatalogMenuIcon} from 'assets/images/menu/catalog.svg';
import {ReactComponent as DealMenuIcon} from 'assets/images/menu/deal.svg';
import {ReactComponent as FinanceMenuIcon} from 'assets/images/menu/finance.svg';
import {ReactComponent as OffersMenuIcon} from 'assets/images/menu/offers.svg';
import {ReactComponent as SitesMenuIcon} from 'assets/images/menu/sites.svg';
import {useCommonSelector} from 'hooks';
import {UserInterface, UserRoleEnum} from 'typings';
import menuStyle from './Menu.module.scss';


export const MenuComponent: FC = () => {
    const {t} = useTranslation();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {pathname} = useLocation();
    const [dealsSectionUrl, setDealsSectionUrl] = useState<string>();
    const [isCanShowSections, setIsCanShowSections] = useState<boolean>(false);
    const [placementSectionUrl, setPlacementSectionUrl] = useState<string>();

    const isCurrentSection = (path): boolean => {
        return pathname.startsWith(path);
    };


    useEffect(() => {
        if (!user.id) return;
        setIsCanShowSections(user.is_allowed_to_be_implementer || user.is_allowed_to_be_payer);
        setPlacementSectionUrl(`${MainRoutesEnum.PLACEMENTS}${user.is_allowed_to_be_implementer ? PlacementRoutesPathEnum.OFFER_LIST : PlacementRoutesPathEnum.REQUEST_LIST}?status=active`);
        setDealsSectionUrl(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL_LIST}?my_role=${user.is_allowed_to_be_payer ? UserRoleEnum.PAYER : UserRoleEnum.IMPLEMENTER}`);
    }, [user]);


    return (
        <nav className={menuStyle.menu}>
            <div className={menuStyle.menuContent}>
                <NavLink to={`${MainRoutesEnum.CATALOG}`}
                         className={({isActive}) => isActive || isCurrentSection('/catalog') ? `${menuStyle.menuLink} ${menuStyle.menuLink_active}` : menuStyle.menuLink}>
                    <CatalogMenuIcon className={menuStyle.menuIcon}/>
                    {t('Store')}
                </NavLink>
                {(isCanShowSections && placementSectionUrl) && <NavLink to={placementSectionUrl} className={`${menuStyle.menuLink} ${isCurrentSection(MainRoutesEnum.PLACEMENTS) && menuStyle.menuLink_active}`}>
                    <OffersMenuIcon className={menuStyle.menuIcon}/>
                    {t('My offers')}
                </NavLink>}
                <NavLink to={`${MainRoutesEnum.WEBSITES}`}
                         className={`${menuStyle.menuLink} ${isCurrentSection(MainRoutesEnum.WEBSITES) && menuStyle.menuLink_active}`}>
                    <SitesMenuIcon className={menuStyle.menuIcon}/>
                    {t('My sites')}
                </NavLink>
                {isCanShowSections && <>
                    <NavLink to={`${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.BALANCE_HISTORY}`}
                             className={`${menuStyle.menuLink} ${isCurrentSection(MainRoutesEnum.FINANCE) && menuStyle.menuLink_active}`}>
                        <FinanceMenuIcon className={menuStyle.menuIcon}/>
                        {t('Finance')}
                    </NavLink>
                    {!!dealsSectionUrl && <NavLink to={dealsSectionUrl}
                                                   className={`${menuStyle.menuLink} ${isCurrentSection(MainRoutesEnum.DEALS) && menuStyle.menuLink_active}`}>
                        <DealMenuIcon className={menuStyle.menuIcon}/>
                        {t('My deals')}
                    </NavLink>}
                </>}
                {user.is_allowed_to_be_implementer && !user.is_allowed_to_be_payer && <a className={`${menuStyle.menuLink} ${menuStyle.menuLink_help}`} href="https://promo.admitad.com/marketplacex-manual-pub">{t('Help')}</a>}
                {!user.is_allowed_to_be_implementer && user.is_allowed_to_be_payer && <a className={`${menuStyle.menuLink} ${menuStyle.menuLink_help}`} href="https://promo.admitad.com/marketplacex-manual">{t('Help')}</a>}
                {user.is_allowed_to_be_implementer && user.is_allowed_to_be_payer && <>
                    <a className={`${menuStyle.menuLink} ${menuStyle.menuLink_help}`} href="https://promo.admitad.com/marketplacex-manual-pub">{t('Help for publishers')}</a>
                    <a className={`${menuStyle.menuLink}`} href="https://promo.admitad.com/marketplacex-manual">{t('Help for advertisers')}</a>
                </>}
            </div>
        </nav>
    );
};
