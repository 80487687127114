import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import './index.scss';
import {store} from './core';
import App from './core/App/App';


['prod', 'stage'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`) && Sentry.init({
    dsn: 'https://dd3f806ad6bc424d87b126d54495765d@sentry.admit.ad/76',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

export const history = createBrowserHistory({window});


declare global {
    const gtag: any;
    const gtagIdMain: string;
    const gtagIdAdd: string;


    interface Window {
        gtag: any,
        dataLayer: any,
    }
}


ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <HistoryRouter history={history}>
                <App/>
            </HistoryRouter>
        </Provider>
    </StrictMode>,
    document.getElementById('marketplacex-app'),
);
