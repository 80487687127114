import React, {FC, FormEvent, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, Dropdown, ETextAreaThemes, IDropdownOption, Input, TDropdownOptionValue, TextArea, ValidationTooltipWrapper} from '@design-system/ui-kit';
import {ReactComponent as AddIcon} from 'assets/images/icons/add.svg';
import 'assets/styles/_datepicker.scss';
import {Button, DatePickerInputComponent, FileDropComponent, TotalBlockComponent} from 'components';
import {nonFieldErrorsKey} from 'consts';
import {clearAttachments, deleteAttachment, MainRoutesEnum, PlacementRoutesPathEnum, RootState, uploadFile, WebsitesRoutesPathsEnum} from 'core';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, GtagCategoryEnum, GtagNameEnum, UploadedAttachmentInterface, WebsiteInterface} from 'typings';
import {OfferFormControlsEnum, OfferFormStateType} from '../../typings';
import {clearPlacementFormError, getOfferData, patchOffer, postOffer, resetPlacementToInitialState} from '../../placements.reducer';
import {AttentionModal} from '../attentionModal/AttentionModal.component';
import {PlacementSelectedWebsite} from '../selectedWebsite/PlacementSelectedWebsite.component';
import {PromotionalDateInterface} from './PromotionalDate.interface';


export const OfferFormComponent: FC<{ isEditMode: boolean; placementId?: string; }> = ({isEditMode, placementId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const nullDate: PromotionalDateInterface = {
        [OfferFormControlsEnum.PLACEMENT_START_DATE]: null,
        [OfferFormControlsEnum.PLACEMENT_END_DATE]: null,
    };

    const initFormState: OfferFormStateType = {
        [OfferFormControlsEnum.ATTACHMENTS]: [],
        [OfferFormControlsEnum.COVERAGE]: null as unknown as number,
        [OfferFormControlsEnum.CURRENCY]: '',
        // [OfferFormControlsEnum.DAYS_TO_IMPLEMENT]: null as unknown as number,
        [OfferFormControlsEnum.DESCRIPTION]: '',
        [OfferFormControlsEnum.GEOS]: [],
        [OfferFormControlsEnum.NAME]: '',
        [OfferFormControlsEnum.IS_PROMO_FILES_REQUIRED]: false,
        [OfferFormControlsEnum.IS_PUBLISHED]: false,
        [OfferFormControlsEnum.PLACEMENT_END_DATE]: null,
        [OfferFormControlsEnum.PLACEMENT_START_DATE]: null,
        [OfferFormControlsEnum.PLACEMENT_TYPE]: null as unknown as number,
        [OfferFormControlsEnum.PRICE]: '',
        [OfferFormControlsEnum.PROMO_FILES_REQUIREMENTS]: '',
        [OfferFormControlsEnum.WEBSITE]: null as unknown as number,
    };

    const {files} = useSelector((state: RootState) => state.commonReducer);
    const {websiteList, currentOffer, formErrors} = useSelector((state: RootState) => state.placementReducer);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const currencies: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('currencies');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const placementTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('placementTypes');

    const [currencyForDropdown, setCurrencyForDropdown] = useState<IDropdownOption[]>([]);
    const [placementTypesForDropdown, setPlacementTypesForDropdown] = useState<IDropdownOption[]>([]);
    const [websiteListForDropdown, setWebsiteListForDropdown] = useState<IDropdownOption[]>([]);
    const [supportedGeosList, setSupportedGeosList] = useState<IDropdownOption[]>([]);
    const [selectedWebsite, setSelectedWebsite] = useState<WebsiteInterface | null>(null);
    const [date, setDate] = useState<PromotionalDateInterface>(nullDate);
    const [showModal, setShowModal] = useState(false);
    const [formState, setFormState] = useState<OfferFormStateType>(initFormState);


    useEffect(() => {
        handleInputChange(OfferFormControlsEnum.ATTACHMENTS);
    }, [files]);

    useEffect(() => {
        setSupportedGeosList(geo);
    }, [geo]);

    useEffect(() => {
        setPlacementTypesForDropdown(placementTypes);
    }, [placementTypes]);

    useEffect(() => {
        setCurrencyForDropdown(currencies);
    }, [currencies]);

    useEffect(() => {
        setFormState({
            ...formState,
            [OfferFormControlsEnum.CURRENCY]: `${currencyForDropdown?.[0]?.value}`,
        });
    }, [currencyForDropdown]);

    useEffect(() => {
        dispatch(getOfferData({placementId: placementId}));
        if (placementId) {
            setFormState({...formState, ...currentOffer});
        } else {
            setFormState(initFormState);
        }
        return () => {
            dispatch(clearAttachments());
            dispatch(resetPlacementToInitialState());
            setSelectedWebsite(null);
            setDate(nullDate);
            setFormState(initFormState);
        };
    }, [dispatch, placementId]);

    useEffect(() => {
        if (!currentOffer || !websiteList) return;

        const activeWebsiteList = websiteList.filter(website => website.is_active || website.id === currentOffer[OfferFormControlsEnum.WEBSITE]);
        setWebsiteListForDropdown(activeWebsiteList.map((website: WebsiteInterface) => ({value: Number(website.id), text: website.name})));

        if (Object.keys(currentOffer).length) {
            setFormState({...formState, ...currentOffer});
            setDate({
                [OfferFormControlsEnum.PLACEMENT_START_DATE]: currentOffer[OfferFormControlsEnum.PLACEMENT_START_DATE] ? new Date(currentOffer[OfferFormControlsEnum.PLACEMENT_START_DATE] as string) : null,
                [OfferFormControlsEnum.PLACEMENT_END_DATE]: currentOffer[OfferFormControlsEnum.PLACEMENT_END_DATE] ? new Date(currentOffer[OfferFormControlsEnum.PLACEMENT_END_DATE] as string) : null,
            });
            const newSelectedWebsite = websiteList.find(website => website.id === currentOffer.website);
            setSelectedWebsite(newSelectedWebsite ?? null);
        }
    }, [currentOffer, websiteList]);


    const handleSaveClick = () => {
        setShowModal(false);
        handleFormSubmit(undefined, true);
    };

    const handleCancelClick = () => {
        setShowModal(true);
    };

    const handleFormSubmit = (event?: FormEvent, draft?: boolean) => {
        event?.preventDefault();
        const formData = formState;
        formData[OfferFormControlsEnum.PLACEMENT_START_DATE] = date[OfferFormControlsEnum.PLACEMENT_START_DATE]?.toLocaleDateString('en-CA') || null;
        formData[OfferFormControlsEnum.PLACEMENT_END_DATE] = date[OfferFormControlsEnum.PLACEMENT_END_DATE]?.toLocaleDateString('en-CA') || null;
        formData[OfferFormControlsEnum.IS_PUBLISHED] = !draft;
        formData[OfferFormControlsEnum.ATTACHMENTS] = Object.values(files).filter(file => file.meta.success).map(fileData => (fileData.uploadedAttachments as UploadedAttachmentInterface).file_id);
        return dispatch(isEditMode && placementId ? patchOffer({data: formData, placementId}) : postOffer({data: formData}));
    };

    const handleInputChange = (controlName: OfferFormControlsEnum) => {
        dispatch(clearPlacementFormError(controlName));
    };

    const onInputChange = (controlName: OfferFormControlsEnum) => (newValue: string | TDropdownOptionValue | null | TDropdownOptionValue[]) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputWebsiteChange = (newValue: any) => {
        const newSelectedWebsite = websiteList.find(website => website.id === newValue);
        setFormState({
            ...formState,
            [OfferFormControlsEnum.WEBSITE]: newValue,
            [OfferFormControlsEnum.GEOS]: newSelectedWebsite?.geos || [],
            [OfferFormControlsEnum.COVERAGE]: Number(newSelectedWebsite?.coverage) || null as unknown as number,
        });
        setSelectedWebsite(newSelectedWebsite ?? null);

        handleInputChange(OfferFormControlsEnum.WEBSITE);
        dispatch(clearPlacementFormError(nonFieldErrorsKey));
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const onCheckboxChange = (controlName: OfferFormControlsEnum) => (checked: boolean) => {
        setFormState({
            ...formState,
            [controlName]: checked,
        });

        handleInputChange(controlName);
    };

    const onDateChange = (value: any, controlName: OfferFormControlsEnum) => {
        setDate({
            ...date,
            [controlName]: value,
        });
        handleInputChange(controlName);
    };

    const handleDelete = (fileId) => dispatch(deleteAttachment(fileId));
    const handleDrop = (file) => dispatch(uploadFile({file: file}));


    return (
        <div className="blank">
            <form className="form"
                  onSubmit={handleFormSubmit}
                  noValidate>
                <fieldset className="form__blank form__fieldset" id="placement-form-site">
                    <p className="textHeader_06 form__legend">{t('Site')}</p>
                    <div className="form__lineWithEndContent">
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[OfferFormControlsEnum.WEBSITE]}
                                      placeholder={t('Choose your site')}
                                      uncheckOptionByClick
                                      required
                                      optionsListMaxHeight={websiteListForDropdown.length ? 300 : undefined}
                                      onChange={onInputWebsiteChange}
                                      options={websiteListForDropdown}/>
                            {formErrors?.[OfferFormControlsEnum.WEBSITE] && (<p className="form__error">{formErrors?.[OfferFormControlsEnum.WEBSITE]}</p>)}
                            {formErrors?.[nonFieldErrorsKey] && (<p className="form__error">{formErrors?.[nonFieldErrorsKey]}</p>)}
                        </div>
                        <Button eventCategory={GtagCategoryEnum.OFFERS}
                                {...isEditMode && {eventLabel: `${currentOffer.name} - ${currentOffer.id}`}}
                                eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.WEBMASTER_OFFERS} - add site button`}
                                Icon={AddIcon}
                                textContent={t('Add site')}
                                to={`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.CREATE}`}/>
                    </div>
                    {selectedWebsite && <PlacementSelectedWebsite selectedWebsite={selectedWebsite}/>}
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-form-general">
                    <p className="textHeader_06 form__legend">{t('General information')}</p>
                    <Input name={OfferFormControlsEnum.NAME}
                           placeholder={t('Offer title')}
                           required
                           value={formState[OfferFormControlsEnum.NAME]}
                           onChange={onInputChange(OfferFormControlsEnum.NAME)}
                           errorMessage={formErrors?.[OfferFormControlsEnum.NAME]?.toString()}
                           onBlur={onInputBlur}/>
                    <div className="form__dropdownWrap">
                        <Dropdown value={formState[OfferFormControlsEnum.PLACEMENT_TYPE]}
                                  placeholder={t('Placement type')}
                                  uncheckOptionByClick
                                  required
                                  optionsListMaxHeight={placementTypesForDropdown.length ? 300 : undefined}
                                  onChange={onInputChange(OfferFormControlsEnum.PLACEMENT_TYPE)}
                                  options={placementTypesForDropdown}/>
                        {formErrors?.[OfferFormControlsEnum.PLACEMENT_TYPE] && (
                            <p className="form__error">{formErrors?.[OfferFormControlsEnum.PLACEMENT_TYPE]}</p>)}
                    </div>
                    <ValidationTooltipWrapper errorMessage={formErrors?.[OfferFormControlsEnum.DESCRIPTION]?.toString()}>
                        <TextArea placeholder={t('Describe the placement offer')}
                                  value={formState[OfferFormControlsEnum.DESCRIPTION]}
                                  theme={formErrors?.[OfferFormControlsEnum.DESCRIPTION] ? ETextAreaThemes.error : ETextAreaThemes.base}
                                  className="textarea"
                                  onChange={onInputChange(OfferFormControlsEnum.DESCRIPTION)}/>
                    </ValidationTooltipWrapper>
                    <div className="form__line">
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[OfferFormControlsEnum.GEOS]}
                                      placeholder={t('Countries')}
                                      uncheckOptionByClick
                                      multiple
                                      optionsListMaxHeight={supportedGeosList.length ? 300 : undefined}
                                      onMultipleChange={onInputChange(OfferFormControlsEnum.GEOS)}
                                      onChange={onInputChange(OfferFormControlsEnum.GEOS)}
                                      options={supportedGeosList}/>
                            {formErrors?.[OfferFormControlsEnum.GEOS] && (<p className="form__error">{formErrors?.[OfferFormControlsEnum.GEOS]}</p>)}
                        </div>
                        <Input name={OfferFormControlsEnum.COVERAGE}
                               placeholder={t('Coverage, visitors/mo.')}
                               required
                               value={formState[OfferFormControlsEnum.COVERAGE]?.toString()}
                               onChange={onInputChange(OfferFormControlsEnum.COVERAGE)}
                               errorMessage={formErrors?.[OfferFormControlsEnum.COVERAGE]?.toString()}
                               onBlur={onInputBlur}/>
                    </div>
                    {/*<Input type="number"*/}
                    {/*       name={OfferFormControlsEnum.DAYS_TO_IMPLEMENT}*/}
                    {/*       placeholder={t('Placement approval period, days')}*/}
                    {/*       value={formState[OfferFormControlsEnum.DAYS_TO_IMPLEMENT]?.toString()}*/}
                    {/*       onChange={onInputChange(OfferFormControlsEnum.DAYS_TO_IMPLEMENT)}*/}
                    {/*       errorMessage={formErrors?.[OfferFormControlsEnum.DAYS_TO_IMPLEMENT]?.toString()}*/}
                    {/*       onBlur={onInputBlur}/>*/}
                    <ValidationTooltipWrapper errorMessage={formErrors?.[OfferFormControlsEnum.IS_PROMO_FILES_REQUIRED]?.toString()}>
                        <Checkbox name={OfferFormControlsEnum.IS_PROMO_FILES_REQUIRED}
                                  checked={formState[OfferFormControlsEnum.IS_PROMO_FILES_REQUIRED]}
                                  onChange={onCheckboxChange(OfferFormControlsEnum.IS_PROMO_FILES_REQUIRED)}>
                            {t('Ad creatives required')}
                        </Checkbox>
                    </ValidationTooltipWrapper>
                    <ValidationTooltipWrapper errorMessage={formErrors?.[OfferFormControlsEnum.PROMO_FILES_REQUIREMENTS]?.toString()}>
                        <TextArea placeholder={t('Description of promotional materials requirements')}
                                  value={formState[OfferFormControlsEnum.PROMO_FILES_REQUIREMENTS]}
                                  theme={formErrors?.[OfferFormControlsEnum.PROMO_FILES_REQUIREMENTS] ? ETextAreaThemes.error : ETextAreaThemes.base}
                                  className="textarea"
                                  onChange={onInputChange(OfferFormControlsEnum.PROMO_FILES_REQUIREMENTS)}/>
                    </ValidationTooltipWrapper>
                    <div>
                        <p className="form__text">{t('Example of placement')}:</p>
                        <FileDropComponent files={files}
                                           handleDelete={handleDelete}
                                           handleDrop={handleDrop}
                                           hint={t('Maximum upload file size: 50 MB. You can upload no more than 10 files at a time')}
                                           maxFiles={10}/>
                        {formErrors?.[OfferFormControlsEnum.ATTACHMENTS] && (<p className="form__error">{formErrors?.[OfferFormControlsEnum.ATTACHMENTS]}</p>)}
                    </div>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-form-period">
                    <p className="textHeader_06 form__legend">{t('Placement time')}</p>
                    <p className="form__text">{t('If you need your ad to be placed on exact dates, please specify them. If no dates are selected, placement will be on an ongoing basis.')}</p>
                    <div className="form__lineRange">
                        <DatePickerInputComponent inputName={OfferFormControlsEnum.PLACEMENT_START_DATE}
                                                  onInputChange={onInputChange(OfferFormControlsEnum.PLACEMENT_START_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, OfferFormControlsEnum.PLACEMENT_START_DATE)}
                                                  inputValue={date[OfferFormControlsEnum.PLACEMENT_START_DATE]}
                                                  inputPlaceholder={t('Start date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[OfferFormControlsEnum.PLACEMENT_START_DATE]?.toString()}/>
                        —
                        <DatePickerInputComponent inputName={OfferFormControlsEnum.PLACEMENT_END_DATE}
                                                  onInputChange={onInputChange(OfferFormControlsEnum.PLACEMENT_END_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, OfferFormControlsEnum.PLACEMENT_END_DATE)}
                                                  inputValue={date[OfferFormControlsEnum.PLACEMENT_END_DATE]}
                                                  inputPlaceholder={t('End date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[OfferFormControlsEnum.PLACEMENT_END_DATE]?.toString()}/>
                    </div>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-form-payment">
                    <p className="textHeader_06 form__legend">{t('Financial terms')}</p>
                    <div className="form__lineWithEndContent">
                        <Input type="number"
                               name={OfferFormControlsEnum.PRICE}
                               placeholder={t('Offer price (incl. fee)')}
                               required
                               value={formState[OfferFormControlsEnum.PRICE]}
                               onChange={onInputChange(OfferFormControlsEnum.PRICE)}
                               errorMessage={formErrors?.[OfferFormControlsEnum.PRICE]?.toString()}
                               onBlur={onInputBlur}/>
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[OfferFormControlsEnum.CURRENCY]}
                                      placeholder={t('Currency')}
                                      required
                                      uncheckOptionByClick
                                      optionsListMaxHeight={currencyForDropdown.length ? 300 : undefined}
                                      onChange={onInputChange(OfferFormControlsEnum.CURRENCY)}
                                      options={currencyForDropdown}/>
                            {formErrors?.[OfferFormControlsEnum.CURRENCY] && (<p className="form__error">{formErrors?.[OfferFormControlsEnum.CURRENCY]}</p>)}
                        </div>
                    </div>
                    {!!formState[OfferFormControlsEnum.PRICE] && <TotalBlockComponent amount={Math.round(+formState[OfferFormControlsEnum.PRICE] / (1 + appConfig.commission_percent) * 100) / 100}
                                                                                      currency={formState[OfferFormControlsEnum.CURRENCY]}
                                                                                      text={t('Total, excl. {{val}}% fee', {val: appConfig.commission_percent * 100})}/>}
                </fieldset>
                <div className="controls controls_align_justify">
                    <Button eventCategory={GtagCategoryEnum.OFFERS}
                            {...isEditMode && {eventLabel: `${currentOffer.name} - ${currentOffer.id}`}}
                            eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.WEBMASTER_OFFERS} - cancel ${isEditMode ? 'edit' : 'add'} offer button`}
                            onClick={handleCancelClick}
                            textContent={t('Cancel')}
                            theme={'stroke'}
                            type="button"/>
                    <Button disabled={!!Object.keys(formErrors).length}
                            eventCategory={GtagCategoryEnum.OFFERS}
                            {...isEditMode && {eventLabel: `${currentOffer.name} - ${currentOffer.id}`}}
                            eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.WEBMASTER_OFFERS} - ${isEditMode ? 'edit' : 'add'} offer button`}
                            textContent={isEditMode ? t('Save changes') : t('Add offer')}/>
                </div>
            </form>
            <AttentionModal handleSaveClick={handleSaveClick}
                            setShowModal={setShowModal}
                            showModal={showModal}
                            to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_LIST}?status=active`}/>
        </div>
    );
};
