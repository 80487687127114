export enum CatalogFilterEnum {
    CATEGORY = 'category',
    // COVERAGE__GT = 'coverage__gt',
    // COVERAGE__LT = 'coverage__lt',
    GEO = 'geo',
    ORDERING = 'ordering',
    PLACEMENT_TYPE = 'placement_type',
    SEARCH = 'search',
    STATUS = 'status',
    // WEBSITE_TYPE = 'website_type',
}
