import React, {Dispatch, FC, FormEvent, SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {matchPath, useLocation, useSearchParams} from 'react-router-dom';
import {Dropdown, IDropdownOption, Input, Modal, TDropdownOptionValue} from '@design-system/ui-kit';
import {FinanceRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {Button, LoaderWithOverlay} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, GtagCategoryEnum, GtagNameEnum} from 'typings';
import {TransactionFormStateType, TransactionFromControlsEnum} from '../../typings';
import {clearFinanceFormErrors, getBalanceTransactionRequestsList, postBalanceTransactionRequests, setFinanceShowForm} from '../../finance.reducer';


export const RefillModal: FC<{ setShowModal: Dispatch<SetStateAction<boolean>>; showModal: boolean; }> = ({setShowModal, showModal}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const currencies: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('currencies');
    const {formErrors, isShowForm, isFormLoading} = useSelector((state: RootState) => state.financeReducer);
    const [searchParams] = useSearchParams();
    const {pathname} = useLocation();

    const initFormState: TransactionFormStateType = {
        [TransactionFromControlsEnum.AMOUNT]: '',
        [TransactionFromControlsEnum.CURRENCY]: '',
    };
    const [formState, setFormState] = useState<TransactionFormStateType>(initFormState);
    const [currencyForDropdown, setCurrencyForDropdown] = useState<IDropdownOption[]>([]);


    const resetForm = () => {
        setFormState({
            ...initFormState,
            [TransactionFromControlsEnum.CURRENCY]: `${currencyForDropdown?.[0]?.value}`,
        });
    };

    const handleFormSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        dispatch(postBalanceTransactionRequests({
            data: formState,
            ...matchPath(`${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.PAYMENT_REQUESTS}/*`, pathname) && {
                updateDataMethod: getBalanceTransactionRequestsList({
                    queryParams: searchParams,
                    controller: new AbortController(),
                }),
            },
        }));
    };

    const handleInputChange = (controlName: string) => {
        dispatch(clearFinanceFormErrors(controlName));
    };

    const onInputChange = (controlName: string) => (newValue: string | TDropdownOptionValue | null) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };


    useEffect(() => {
        if (showModal) return;
        resetForm();
    }, [showModal]);

    useEffect(() => {
        if (!currencies.length) return;
        setCurrencyForDropdown(currencies);
    }, [currencies]);

    useEffect(() => {
        if (!currencyForDropdown.length) return;
        setFormState({
            ...formState,
            [TransactionFromControlsEnum.CURRENCY]: `${currencyForDropdown[0]?.value}`,
        });
    }, [currencyForDropdown]);

    useEffect(() => {
        if (!isShowForm) return;
        setShowModal(false);
        dispatch(setFinanceShowForm(false));
    }, [dispatch, isShowForm]);


    return (
        <Modal title={t('Deposit')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <p>{t('You add funds from your Partner Network account.')}</p>
            <p>{t('The amount added will be converted to {{defaultCurrency}} from your account’s currency.', {defaultCurrency: appConfig.default_currency})}</p>
            <p>{t('The final amount in your account’s currency will be added as a bonus to the report and next invoice.')}</p>
            <form onSubmit={handleFormSubmit} noValidate className="form">
                <div className="form__lineWithEndContent">
                    <Input type="number"
                           name={TransactionFromControlsEnum.AMOUNT}
                           placeholder={t('Amount')}
                           value={formState[TransactionFromControlsEnum.AMOUNT]}
                           onChange={onInputChange(TransactionFromControlsEnum.AMOUNT)}
                           errorMessage={formErrors?.[TransactionFromControlsEnum.AMOUNT]?.toString()}
                           onBlur={onInputBlur}/>
                    <div className="form__dropdownWrap">
                        <Dropdown value={appConfig.default_currency}
                                  placeholder={t('Currency')}
                                  uncheckOptionByClick
                                  optionsListMaxHeight={currencyForDropdown.length ? 300 : undefined}
                                  disabled
                                  onChange={onInputChange(TransactionFromControlsEnum.CURRENCY)}
                                  options={currencyForDropdown}/>
                        {formErrors?.[TransactionFromControlsEnum.CURRENCY] && (<p className="form__error">{formErrors?.[TransactionFromControlsEnum.CURRENCY]}</p>)}
                    </div>
                </div>
                <div className="controls controls_align_justify">
                    <Button eventCategory={GtagCategoryEnum.FINANCE}
                            eventLabel={`${formState[TransactionFromControlsEnum.CURRENCY]} - ${formState[TransactionFromControlsEnum.AMOUNT]}`}
                            eventName={`${GtagNameEnum.FINANCE} - refill cancel button`}
                            onClick={handleCancelClick}
                            textContent={t('Cancel')}
                            theme={'stroke'}
                            type={'button'}/>
                    <Button disabled={isFormLoading || !formState[TransactionFromControlsEnum.AMOUNT]}
                            eventCategory={GtagCategoryEnum.FINANCE}
                            eventLabel={`${formState[TransactionFromControlsEnum.CURRENCY]} - ${formState[TransactionFromControlsEnum.AMOUNT]}`}
                            eventName={`${GtagNameEnum.FINANCE} - refill confirm button`}
                            textContent={t('Confirm')}
                            theme={'confirm'}/>
                </div>
            </form>
            {isFormLoading && <LoaderWithOverlay/>}
        </Modal>
    );
};
