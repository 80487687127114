import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'components';
import {RootState} from 'core';
import {GtagCategoryEnum, UserRoleEnum} from 'typings';
import {setCurrentStep, setRole} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {StepEnum} from '../../typings';
import {OnboardingHeaderComponent} from '../header/OnboardingHeader.component';
import {RadioGroupComponent} from '../radioGroup/RadioGroup.component';


export const WelcomeComponent: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {role} = useSelector((state: RootState) => state.onboardingReducer);

    const inputData = [{
        label: t('I\'m an advertiser'),
        note: t('I want to advertise my product on the web and boost sales.'),
        inputValue: UserRoleEnum.PAYER,
    }, {
        label: t('I\'m a publisher'),
        note: t('I can recommend advertiser products to my audience and drive customers to them.'),
        inputValue: UserRoleEnum.IMPLEMENTER,
    }];


    const changeRole = (value) => () => {
        dispatch(setRole(value));
    };

    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };


    return (
        <>
            <OnboardingHeaderComponent text={t('A one-of-a-kind service that helps select a placement tailored to the advertiser’s needs and assists publishers with monetizing traffic using non-CPA models.')}/>

            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{t('Let\'s get to know each other!')}</h2>
            <p className={onboardingStyle.text}>{t('Tell us a bit about yourself so we can guide you through the service.')}</p>
            <p className={onboardingStyle.subtext}>{t('Select the best option')}:</p>

            <RadioGroupComponent inputData={inputData} name="role" onChange={changeRole} value={role}/>

            <div className={`controls controls_align_right ${onboardingStyle.controls}`}>
                <Button disabled={!role}
                        eventCategory={GtagCategoryEnum.WELCOME}
                        eventLabel={role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'}
                        eventName="choose advertiser or webmaster"
                        onClick={handleToggleStepClick(StepEnum.STEP_1_FORM)}
                        textContent={t('Next')}/>
            </div>
        </>
    );
};
