import {i18n} from 'i18n';
import {BalanceOperationTypeEnum, PaymentOperationTypeEnum} from '../typings';


export const FinanceOperationTypeTextConst = {
    [BalanceOperationTypeEnum.ADD]: i18n.t('Deposit'),
    [BalanceOperationTypeEnum.WRITE_OFF]: i18n.t('Payment'),
    [PaymentOperationTypeEnum.TOP_UP]: i18n.t('Replenishment'),
    [PaymentOperationTypeEnum.WITHDRAWAL]: i18n.t('Withdrawal'),
};
