import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, LoaderWithOverlay, PageTitleComponent} from 'components';
import {MainRoutesEnum, PlacementRoutesPathEnum, RootState} from 'core';
import {useCommonSelector, useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, UserInterface} from 'typings';
import {PlacementNavComponent, RequestFormComponent} from '../../../components';


const RequestCreate: FC = () => {
    const {t} = useTranslation();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {placementId} = useParams();
    const isNarrowScreen = useMedia();
    const isEditMode: boolean = !!placementId;
    const {isLoading} = useSelector((state: RootState) => state.placementReducer);

    const offerLegend = [
        {href: '#placement-request-form-site', text: t('Site')},
        {href: '#placement-request-form-general', text: t('General information')},
        {href: '#placement-request-form-materials', text: t('Placement creatives')},
        {href: '#placement-request-form-period', text: t('Placement time')},
        {href: '#placement-request-form-payment', text: t('Financial terms')},
    ];


    return (
        <>
            <main className="page page_aside-left">
                <PageTitleComponent text={isEditMode ? t('Edit my offer') : t('Add my offer')}/>
                {!isNarrowScreen && <PlacementNavComponent className="page__aside" anchorList={offerLegend}/>}
                <section className="page__content page__blank">
                    {(user.is_allowed_to_be_implementer && !isEditMode) && <div className="blank topBlock">
                        <div className="btnTabs">
                            <Button eventCategory={GtagCategoryEnum.OFFERS}
                                    eventLabel="publisher offers"
                                    eventName={`${GtagNameEnum.CREATE_OFFER}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Publisher offers')}
                                    to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_CREATE}`}/>
                            <Button eventCategory={GtagCategoryEnum.OFFERS}
                                    eventLabel="advertiser offers"
                                    eventName={`${GtagNameEnum.CREATE_OFFER}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Advertiser offers')}
                                    to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_CREATE}`}/>
                        </div>
                        {/*TODO: href*/}
                        {/*<span className={topBlockStyle.topBlockTotal}>{t('Go to the help section')}</span>*/}
                    </div>}
                    <RequestFormComponent isEditMode={isEditMode} placementId={placementId}/>
                </section>
                {isLoading && <LoaderWithOverlay/>}
            </main>
        </>
    );
};

export {RequestCreate};
export default RequestCreate;
