import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {IDropdownOption, Input, TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, DatePickerInputComponent, FilterChoicesComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, GtagCategoryEnum, GtagNameEnum, PaginationEnum} from 'typings';
import {getEventDimension} from 'utils';
import {DealFilterEnum} from '../../typings';


export const DealFilterComponent: FC<{ priceInclFee: boolean; }> = ({priceInclFee}) => {
    const {t} = useTranslation();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const placementTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('placementTypes');
    const [filterValue, setFilterValue] = useState<any>({});
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setFilterValue({
            ...filterValue,
            // [DealFilterEnum.CREATED_AT__GT]: searchParams.get(DealFilterEnum.CREATED_AT__GT) ? new Date(`${searchParams.get(DealFilterEnum.CREATED_AT__GT)}`) : '',
            // [DealFilterEnum.CREATED_AT__LT]: searchParams.get(DealFilterEnum.CREATED_AT__LT) ? new Date(`${searchParams.get(DealFilterEnum.CREATED_AT__LT)}`) : '',
            [DealFilterEnum.PRICE__GT]: searchParams.get(DealFilterEnum.PRICE__GT),
            [DealFilterEnum.PRICE__LT]: searchParams.get(DealFilterEnum.PRICE__LT),
            [DealFilterEnum.PLACEMENT_TYPE]: searchParams.getAll(DealFilterEnum.PLACEMENT_TYPE),
        });
    }, [searchParams]);


    const onInputChange = (controlName: string) => (value: TDropdownOptionValue[] | string) => {
        let newValue;
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (typeof value == 'string') {
            newValue = value;
            if (value) {
                newSearchParams.append(controlName, value);
            }
        } else if (Array.isArray(value)) {
            newValue = value || [];
            value.forEach(item => {
                newSearchParams.append(controlName, `${item}`);
            });
        } else {
            newValue = value;
            if (value) {
                const dateValue = (value as Date).toLocaleDateString('en-CA');
                newSearchParams.append(controlName, dateValue);
            }
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        gtag('event', `${GtagNameEnum.MY_DEALS}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.DEALS, `${controlName} - ${value.toString()}`));
    };

    const onCheckboxChange = (controlName: string, value: TDropdownOptionValue) => (checked: boolean) => {
        let newValue = filterValue[controlName];
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (checked) {
            newValue.push(`${value}`);
        } else {
            newValue = newValue.filter(v => v !== `${value}`);
        }
        newValue.forEach(item => {
            newSearchParams.append(controlName, `${item}`);
        });
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        const optionsMap = {
            [DealFilterEnum.PLACEMENT_TYPE]: placementTypes,
        };
        gtag('event', `${GtagNameEnum.MY_DEALS}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.DEALS, `${controlName} - ${optionsMap[controlName].find(option => option.value === value).text} - ${checked ? 'active' : 'disable'}`));
    };

    const handleResetFilterClick = (controlName?: string) => () => {
        const newSearchParams = new URLSearchParams(controlName ? searchParams : undefined);
        if (controlName) {
            newSearchParams.delete(controlName);
        }
        if (searchParams.get(DealFilterEnum.MY_ROLE)) {
            newSearchParams.set(DealFilterEnum.MY_ROLE, searchParams.get(DealFilterEnum.MY_ROLE) || '');
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue(controlName
            ? {
                ...filterValue,
                [controlName]: [],
            }
            : {});
        setSearchParams(newSearchParams);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        //    TODO
    };


    return (
        <>
            {/*<p className="textBodyMedium_03 filterTitle filterTitle_first">{t('Date')}</p>*/}
            {/*<DatePickerInputComponent inputName={DealFilterEnum.CREATED_AT__GT}*/}
            {/*                          className="filterItem"*/}
            {/*                          onInputChange={onInputChange(DealFilterEnum.CREATED_AT__GT)}*/}
            {/*                          onDateChange={onInputChange(DealFilterEnum.CREATED_AT__GT)}*/}
            {/*                          inputValue={filterValue[DealFilterEnum.CREATED_AT__GT] || null}*/}
            {/*                          inputPlaceholder={t('Start date')}*/}
            {/*                          onInputBlur={onInputBlur}*/}
            {/*                          errorMessage={undefined}/>*/}
            {/*<DatePickerInputComponent inputName={DealFilterEnum.CREATED_AT__LT}*/}
            {/*                          className="filterItem"*/}
            {/*                          onInputChange={onInputChange(DealFilterEnum.CREATED_AT__LT)}*/}
            {/*                          onDateChange={onInputChange(DealFilterEnum.CREATED_AT__LT)}*/}
            {/*                          inputValue={filterValue[DealFilterEnum.CREATED_AT__LT] || null}*/}
            {/*                          inputPlaceholder={t('End date')}*/}
            {/*                          onInputBlur={onInputBlur}*/}
            {/*                          errorMessage={undefined}/>*/}
            <p className="textBodyMedium_03 filterTitle filterTitle_first">{priceInclFee ? t('Offer price (incl. fee)') : t('Offer price (ex fee)')}, {appConfig.default_currency}</p>
            <Input value={filterValue[DealFilterEnum.PRICE__GT]}
                   placeholder={t('From')}
                   className="filterItem"
                   type="number"
                   onChange={onInputChange(DealFilterEnum.PRICE__GT)}/>
            <Input value={filterValue[DealFilterEnum.PRICE__LT]}
                   placeholder={t('To')}
                   type="number"
                   className="filterItem"
                   onChange={onInputChange(DealFilterEnum.PRICE__LT)}/>
            <FilterChoicesComponent choicesList={placementTypes}
                                    controlName={DealFilterEnum.PLACEMENT_TYPE}
                                    counter={filterValue[DealFilterEnum.PLACEMENT_TYPE]?.length}
                                    handleResetFilterClick={handleResetFilterClick(DealFilterEnum.PLACEMENT_TYPE)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Placement types')}
                                    filterValue={filterValue}/>
            <Button className="filterControls"
                    eventCategory={GtagCategoryEnum.DEALS}
                    eventLabel="reset all filters button"
                    eventName={`${GtagNameEnum.MY_DEALS}${GtagNameEnum.FILTER}`}
                    onClick={handleResetFilterClick()}
                    textContent={t('Reset all filters')}
                    theme={'stroke'}/>
        </>
    );
};
