import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import sadDog from 'assets/images/404.svg';
import {Button} from 'components';
import pageNotFoundStyle from './PageNotFound.module.scss';


const PageNotFound: FC = () => {
    const {t} = useTranslation();


    return (
        <div className={pageNotFoundStyle.notFoundPage}>
            <div className={pageNotFoundStyle.notFoundPage__content}>
                <h1 className={`textHeaderMedium_01 ${pageNotFoundStyle.notFoundPage__title}`}>{t('Page not found')}</h1>
                <p className={`textBody_01 ${pageNotFoundStyle.notFoundPage__text}`}>{t('The requested URL was not found. You may have made a typo, or maybe that page never existed.')}</p>
                {/*TODO: to*/}
                <Button to={'/'} textContent={t('Go to main page')}/>
            </div>
            <img src={sadDog} alt="Page not found" width="294" height="195"/>
        </div>
    );
};

export {PageNotFound};
export default PageNotFound;
