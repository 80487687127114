import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {EmptyComponent, PartnerNameComponent, WebsiteCardComponent} from 'components';
import {RootState} from 'core';
import {getDealPartner} from '../../deals.reducer';
import partnerStyle from './Partner.module.scss';


export const PartnerComponent: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {deal, partner} = useSelector((state: RootState) => state.dealsReducer);


    useEffect(() => {
        if (!deal) return;
        dispatch(getDealPartner({partnerId: deal.companion_info.id}));
    }, [deal, dispatch]);


    return (
        <>
            {deal && <>
                <PartnerNameComponent className={partnerStyle.partnerPartnerInfo}
                                      avatarText={`${deal.companion_info.first_name[0]}${deal.companion_info.last_name[0]}`}
                                      name={`${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`}
                                      isImplementer={deal.companion_info.id === deal.implementer.id}/>
                {partner?.websites.length === 0
                    ? <EmptyComponent title={t('No sites added yet')} text={t('Unfortunately, the user has not yet added information about their sites')}/>
                    : <div>
                        <div className={partnerStyle.partnerTitleWrap}>
                            <h2 className={`textHeaderMedium_06 ${partnerStyle.partnerTitle}`}>{t('User placement sites')}</h2>
                            <span className={partnerStyle.partnerSites}>{t('Sites: {{val}}', {val: partner?.websites.length})}</span>
                        </div>
                        {partner && partner.websites.map(website => <WebsiteCardComponent key={website.id} website={website}/>)}
                    </div>
                }
            </>}
        </>
    );
};
