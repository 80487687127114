import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import footerStyle from './Footer.module.scss';


export const FooterComponent: FC = () => {
    const {t} = useTranslation();


    return (
        <footer className={footerStyle.footer}>{t('© {{year}} Admitad GmbH. All rights reserved', {year: new Date().getFullYear()})}</footer>
    );
};
