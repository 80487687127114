import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useMatch, useParams} from 'react-router-dom';
import {Button, LinkComponent, LinkTabsComponent, LoaderWithOverlay, PageTitleComponent, PlacementInfoBoxComponent, RepeatDealModalComponent} from 'components';
import {CatalogRoutesPathEnum, DealsRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {DealStatusEnum, GtagCategoryEnum, GtagNameEnum, TabInterface, UserRoleEnum} from 'typings';
import {ChatRouter, HistoryItemComponent, JobIsDoneBtnComponent, MakeDealBtnComponent, RefuseBtnComponent, RevokeApprovalBtnComponent} from '../../componets';
import {getDeal, resetDealToInitialState, setShowSuggestionForm} from '../../deals.reducer';
import dealStyle from './Deal.module.scss';


const Deal: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {dealId} = useParams();
    const isChatCurrentPage = useMatch(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`);
    const {isLoading, deal} = useSelector((state: RootState) => state.dealsReducer);
    const [placementDealListUrl, setPlacementDealListUrl] = useState<string>('');
    const [myRole, setMyRole] = useState<UserRoleEnum | null>(null);
    const [attachmentsLink, setAttachmentsLink] = useState<string>('');
    const [tabs, setTabs] = useState<Omit<TabInterface, 'paramValue'>[]>();
    const [proposerRole, setProposerRole] = useState<UserRoleEnum>();
    const [showRepeatDealModal, setRepeatDealShowModal] = useState<boolean>(false);


    useEffect(() => {
        if (!dealId) return;
        dispatch(getDeal({dealId: dealId}));
    }, [dealId, dispatch]);

    useEffect(() => () => {
        dispatch(resetDealToInitialState());
    }, [dispatch]);

    useEffect(() => {
        if (!deal) return;
        const placementPartUrl = deal.placement ? CatalogRoutesPathEnum.CATALOG_OFFER_DEAL_LIST : CatalogRoutesPathEnum.CATALOG_REQUEST_DEAL_LIST;
        setPlacementDealListUrl(generatePath(`${MainRoutesEnum.CATALOG}${placementPartUrl}`, {placementId: `${(deal.placement || deal.placement_request).id}`}));
        setMyRole(deal.companion_info.id === deal.payer.id ? UserRoleEnum.IMPLEMENTER : UserRoleEnum.PAYER);
        if (deal.placement_request?.uploaded_attachments.length) {
            setAttachmentsLink(generatePath(DealsRoutesPathEnum.DEAL_DOWNLOAD_ALL, {dealId: `${deal.id}`}));
        }
        setProposerRole(deal.is_approved_by_implementer !== deal.is_approved_by_payer ? deal.proposed_conditions.is_created_by_implementer ? UserRoleEnum.IMPLEMENTER : UserRoleEnum.PAYER : undefined);
    }, [deal]);

    useEffect(() => {
        setTabs([{
            eventCategory: GtagCategoryEnum.DEALS,
            eventLabel: `${deal?.name} - ${dealId}`,
            eventName: `${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} comments`,
            linkText: t('Comments'),
            linkValue: generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId}),
        }, {
            eventCategory: GtagCategoryEnum.DEALS,
            eventLabel: `${deal?.name} - ${dealId}`,
            eventName: `${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} offer description`,
            linkText: t('Offer Description'),
            linkValue: generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_PLACEMENT_INFO}`, {dealId}),
        }, {
            eventCategory: GtagCategoryEnum.DEALS,
            eventLabel: `${deal?.name} - ${dealId}`,
            eventName: `${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} ${myRole === UserRoleEnum.IMPLEMENTER ? 'about advertiser' : 'about publisher'}`,
            linkText: myRole === UserRoleEnum.IMPLEMENTER ? t('About Advertiser') : t('About Publisher'),
            linkValue: generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_USER}`, {dealId}),
        }]);
    }, [deal, dealId, myRole, t]);


    const handleAddOfferClick = () => {
        dispatch(setShowSuggestionForm(true));
        setTimeout(() => {
            window.scroll(0, window.outerHeight);
        }, 50);
    };

    const handleRepeatDealClick = () => {
        setRepeatDealShowModal(true);
    };


    return (
        <>
            {dealId && <main className="page page_aside-permanent">
                <PageTitleComponent>
                    {t('Deal')}{deal && <span className={`${dealStyle.dealPlacementName} word-break`}> / {(deal.placement || deal.placement_request).name}</span>}
                </PageTitleComponent>
                <div className="page__aside page__aside_sticky page__blank">
                    {!!(deal?.placement || deal?.placement_request) && <>
                        <div className="blank">
                            {deal && <PlacementInfoBoxComponent startDate={deal.proposed_conditions.placement_start_date}
                                                                endDate={deal.proposed_conditions.placement_end_date}
                                                                price={deal.companion_info.id === deal.payer.id ? deal.proposed_conditions.price : deal.proposed_conditions.price_with_commission}
                                                                priceInclFee={deal.companion_info.id !== deal.payer.id}
                                                                proposerRole={proposerRole}
                                                                name={(deal.placement || deal.placement_request).placement_type_name}
                                                                logo={(deal.placement || deal.placement_request).placement_type_logo}
                                                                currency={deal.currency}
                                                                daysToImplement={(deal.placement || deal.placement_request).days_to_implement}
                                                                approvedAt={deal.approved_at || ''}
                                                                closedAt={deal.closed_at || ''}
                                                                isUpdatedPrice={deal.proposed_conditions.price !== (deal.placement || deal.placement_request).price}
                                                                isUpdatedDate={deal.proposed_conditions.placement_start_date !== (deal.placement || deal.placement_request).placement_start_date || deal.proposed_conditions.placement_end_date !== (deal.placement || deal.placement_request).placement_end_date}/>}

                            <div className="controls controls_align_vertical">
                                {!deal.is_approved_by_me && (deal.is_approved_by_implementer || deal.is_approved_by_payer) &&
                                    <MakeDealBtnComponent deal={deal} showConfirmDialog={deal.companion_info.id === deal.implementer.id} updateDataMethod={getDeal({dealId: dealId})}/>
                                }
                                {deal.is_possible_to_create_change_request && (isChatCurrentPage
                                        ? <Button theme={'stroke'}
                                                  eventCategory={GtagCategoryEnum.DEALS}
                                                  eventLabel={`${deal.name} - ${deal.id}`}
                                                  eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.LEFT_MENU}${GtagNameEnum.CLICK} add own offer`}
                                                  onClick={handleAddOfferClick}
                                                  textContent={t('+ Propose my terms')}/>
                                        : <Button theme={'stroke'}
                                                  eventCategory={GtagCategoryEnum.DEALS}
                                                  eventLabel={`${deal.name} - ${deal.id}`}
                                                  eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.LEFT_MENU}${GtagNameEnum.CLICK} add own offer`}
                                                  to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId})}
                                                  state={{isShowSuggestionForm: true}}
                                                  textContent={t('+ Propose my terms')}/>
                                )}
                                {(deal.is_approved_by_me && (deal.is_approved_by_payer !== deal.is_approved_by_implementer)) && <RevokeApprovalBtnComponent deal={deal}
                                                                                                                                                            eventCategory={GtagCategoryEnum.DEALS}
                                                                                                                                                            eventLabel={`${deal.name} - ${deal.id}`}
                                                                                                                                                            eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.LEFT_MENU}${GtagNameEnum.CLICK} revoke my last offer`}
                                                                                                                                                            updateDataMethod={getDeal({dealId: dealId})}/>}
                                {deal.is_possible_to_mark_as_ready && <JobIsDoneBtnComponent deal={deal}
                                                                                             eventCategory={GtagCategoryEnum.DEALS}
                                                                                             eventLabel={`${deal.name} - ${deal.id}`}
                                                                                             eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.LEFT_MENU}${GtagNameEnum.CLICK} ${deal?.status === DealStatusEnum.DONE ? 'accept work' : 'job is done'}`}
                                                                                             showConfirmDialog={deal.companion_info.id === deal.implementer.id}
                                                                                             textContent={deal?.status === DealStatusEnum.DONE ? t('Accept work') : t('Work complete')}
                                                                                             updateDataMethod={getDeal({dealId: dealId})}/>}
                                {deal.is_possible_to_decline && <RefuseBtnComponent deal={deal}
                                                                                    eventCategory={GtagCategoryEnum.DEALS}
                                                                                    eventLabel={`${deal.name} - ${deal.id}`}
                                                                                    eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.LEFT_MENU}${GtagNameEnum.CLICK} refuse`}
                                                                                    updateDataMethod={getDeal({dealId: dealId})}/>}
                            </div>
                        </div>
                        {!!deal.placement_request?.uploaded_attachments.length && <div className="blank">
                            <p className={dealStyle.dealBlockText}>{t('Download ad creatives')}</p>
                            <LinkComponent className="word-break" download href={attachmentsLink} textContent={`${deal.placement_request.name}_attachments.zip`}/>
                        </div>}
                        {(deal.placement || deal.placement_request).is_mine && <Button to={placementDealListUrl} textContent={t('Other responses')}/>}
                        {deal.is_possible_to_repeat_deal && <div className="blank">
                            <p className={`textBodyMedium_03 ${dealStyle.dealRepeatDealText}`}>
                                {t('Did you like the work of the {{name}}?', {name: `${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`})}
                            </p>
                            <div className="controls controls_align_vertical">
                                <Button onClick={handleRepeatDealClick} textContent={t('New deal')}/>
                            </div>
                            <RepeatDealModalComponent dealId={dealId} isImplementer={deal.companion_info.id !== deal.implementer.id}
                                                      partnerName={`${deal.companion_info.first_name} ${deal.companion_info.last_name?.[0]}.`} setShowModal={setRepeatDealShowModal}
                                                      showModal={showRepeatDealModal}/>
                        </div>}
                        {!!deal.history?.length && <>
                            <p className={`textBodyMedium_01 ${dealStyle.dealAsideTitle}`}>{t('Offers history')}</p>
                            <div className={`blank ${dealStyle.dealHistoryWrap}`}>
                                {deal.history.map(dealHistoryItem => <HistoryItemComponent key={dealHistoryItem.id} dealHistoryItem={dealHistoryItem}/>)}
                            </div>
                        </>}
                    </>}
                </div>

                <div className="page__content page__blank blank">
                    {!!tabs && <LinkTabsComponent tabs={tabs}/>}
                    <ChatRouter updateDataMethod={getDeal({dealId: dealId})} handleRepeatDealMethod={handleRepeatDealClick}/>
                </div>
                {isLoading && <LoaderWithOverlay/>}
            </main>}
        </>
    );
};

export {Deal};
export default Deal;
