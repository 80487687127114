import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames/bind';
import {getEventDimension} from 'utils';
import linkStyle from './Link.module.scss';
import {LinkComponentInterface} from './LinkComponent.interface';


export const LinkComponent: FC<LinkComponentInterface> = (props) => {
    const {className, eventCategory, eventLabel, eventName, href, textContent, to, ...linkProps} = props;
    let template;
    const linkClassName = classNames(
        className,
        linkStyle.link,
    );

    const gtagSendEvent = (e) => {
        gtag('event', eventName, getEventDimension(eventCategory, eventLabel));
        linkProps.onClick?.(e);
    };


    if (to) {
        template = <Link to={to} className={linkClassName} {...linkProps} {...eventName && {onClick: gtagSendEvent}}>{textContent}</Link>;
    } else if (href) {
        template = <a href={href} className={linkClassName} {...linkProps} {...eventName && {onClick: gtagSendEvent}}>{textContent}</a>;
    } else {
        template = <button className={linkClassName} {...linkProps} {...eventName && {onClick: gtagSendEvent}}>{textContent}</button>;
    }


    return template;
};
