import React, {FC} from 'react';
import {ELoaderSizes, Loader} from '@design-system/ui-kit';
import loaderStyle from './LoaderWithOverlay.module.scss';

export const LoaderWithOverlay: FC<{ size?: ELoaderSizes; }> = ({size = ELoaderSizes.l}) => {
    return (
        <>
            <div className={loaderStyle.loaderOverlay}/>
            <Loader className={loaderStyle.loader} inline={false} noMargin={false} size={size}/>
        </>
    );
};
