export enum RequestFormControlsEnum {
    ATTACHMENTS = 'attachments',
    CATEGORIES = 'categories',
    COVERAGE = 'coverage',
    CURRENCY = 'currency',
    // DAYS_TO_IMPLEMENT = 'days_to_implement',
    DESCRIPTION = 'description',
    GEOS = 'geos',
    NAME = 'name',
    IS_PUBLISHED = 'is_published',
    PLACEMENT_END_DATE = 'placement_end_date',
    PLACEMENT_START_DATE = 'placement_start_date',
    PLACEMENT_TYPE = 'placement_type',
    PLACEMENT_URL = 'placement_url',
    PRICE = 'price',
    PROMO_FILES_REQUIREMENTS = 'promo_files_requirements',
    WEBSITE = 'website',
}
