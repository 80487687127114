import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Dropdown, IDropdownOption, TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, FilterChoicesComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum} from 'typings';
import {getEventDimension} from 'utils';
import {CatalogFilterEnum} from '../../typings';
import {CatalogFilterInterface} from './CatalogFilter.interface';


const CatalogFilterComponent: FC = () => {
    const {t} = useTranslation();
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const placementTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('placementTypes');
    // const websiteTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('websiteTypes');
    const initFilterValue: CatalogFilterInterface = {
        [CatalogFilterEnum.CATEGORY]: [],
        [CatalogFilterEnum.GEO]: [],
        [CatalogFilterEnum.PLACEMENT_TYPE]: [],
        // [CatalogFilterEnum.WEBSITE_TYPE]: [],
    };
    const [filterValue, setFilterValue] = useState<CatalogFilterInterface>(initFilterValue);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setFilterValue({
            ...filterValue,
            [CatalogFilterEnum.CATEGORY]: searchParams.getAll(CatalogFilterEnum.CATEGORY),
            [CatalogFilterEnum.GEO]: searchParams.getAll(CatalogFilterEnum.GEO),
            [CatalogFilterEnum.PLACEMENT_TYPE]: searchParams.getAll(CatalogFilterEnum.PLACEMENT_TYPE),
            // [CatalogFilterEnum.WEBSITE_TYPE]: searchParams.getAll(CatalogFilterEnum.WEBSITE_TYPE),
        });
    }, [searchParams]);


    const onInputChange = (controlName: string) => (value: TDropdownOptionValue[] | string) => {
        let newValue;
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (typeof value == 'string') {
            newValue = value;
            if (value) {
                newSearchParams.append(controlName, value);
            }
        } else if (Array.isArray(value)) {
            newValue = value || [];
            value.forEach(item => {
                newSearchParams.append(controlName, `${item}`);
            });
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);
        //TODO: for geo label: val - active/disable
        gtag('event', `${GtagNameEnum.CATALOG}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.CATALOG, `${controlName} - ${value.toString()}`));
    };

    const onCheckboxChange = (controlName: string, value: TDropdownOptionValue) => (checked: boolean) => {
        let newValue = filterValue[controlName];
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (checked) {
            newValue.push(`${value}`);
        } else {
            newValue = newValue.filter(v => v !== `${value}`);
        }
        newValue.forEach(item => {
            newSearchParams.append(controlName, `${item}`);
        });
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        const optionsMap = {
            [CatalogFilterEnum.CATEGORY]: categories,
            [CatalogFilterEnum.PLACEMENT_TYPE]: placementTypes,
        };
        gtag('event', `${GtagNameEnum.CATALOG}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.CATALOG, `${controlName} - ${optionsMap[controlName].find(option => option.value === value).text} - ${checked ? 'active' : 'disable'}`));
    };

    const handleResetFilterClick = (controlName?: string) => () => {
        const newSearchParams = new URLSearchParams(controlName ? searchParams : undefined);
        if (controlName) {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue(controlName
            ? {
                ...filterValue,
                [controlName]: initFilterValue[controlName],
            }
            : initFilterValue);
        setSearchParams(newSearchParams);
    };


    return (
        <>
            <Dropdown value={filterValue[CatalogFilterEnum.GEO]}
                      placeholder={t('Countries')}
                      uncheckOptionByClick
                      optionsListMaxHeight={geo?.length ? 300 : undefined}
                      onMultipleChange={onInputChange(CatalogFilterEnum.GEO)}
                      multiple
                      options={geo}/>
            <FilterChoicesComponent choicesList={categories}
                                    controlName={CatalogFilterEnum.CATEGORY}
                                    counter={filterValue[CatalogFilterEnum.CATEGORY]?.length}
                                    handleResetFilterClick={handleResetFilterClick(CatalogFilterEnum.CATEGORY)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Categories')}
                                    filterValue={filterValue}/>
            <FilterChoicesComponent choicesList={placementTypes}
                                    controlName={CatalogFilterEnum.PLACEMENT_TYPE}
                                    counter={filterValue[CatalogFilterEnum.PLACEMENT_TYPE]?.length}
                                    handleResetFilterClick={handleResetFilterClick(CatalogFilterEnum.PLACEMENT_TYPE)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Placement types')}
                                    filterValue={filterValue}/>
            {/*<FilterChoicesComponent choicesList={websiteTypes}*/}
            {/*                        controlName={CatalogFilterEnum.WEBSITE_TYPE}*/}
            {/*                        counter={filterValue[CatalogFilterEnum.WEBSITE_TYPE]?.length}*/}
            {/*                        handleResetFilterClick={handleResetFilterClick(CatalogFilterEnum.WEBSITE_TYPE)}*/}
            {/*                        onCheckboxChange={onCheckboxChange}*/}
            {/*                        filterLabel={t('Site types')}*/}
            {/*                        filterValue={filterValue}/>*/}
            {/*<p className="textBodyMedium_03 filterTitle">{t('Coverage, visitors/mo.')}</p>*/}
            {/*<div className="filterLine">*/}
            {/*    <Input value={filterValue[CatalogFilterEnum.COVERAGE__GT]}*/}
            {/*           type="number"*/}
            {/*           onChange={onInputChange(CatalogFilterEnum.COVERAGE__GT)}/>*/}
            {/*    —*/}
            {/*    <Input value={filterValue[CatalogFilterEnum.COVERAGE__LT]}*/}
            {/*           type="number"*/}
            {/*           onChange={onInputChange(CatalogFilterEnum.COVERAGE__LT)}/>*/}
            {/*</div>*/}
            <Button className="filterControls"
                    eventCategory={GtagCategoryEnum.CATALOG}
                    eventName={`${GtagNameEnum.CATALOG}${GtagNameEnum.FILTER}`}
                    eventLabel="reset all filters button"
                    onClick={handleResetFilterClick()}
                    textContent={t('Reset all filters')}
                    theme={'stroke'}/>
        </>
    );
};

export {CatalogFilterComponent};
export default CatalogFilterComponent;
