import {RequestPayloadType, WebsiteFormErrorsType, WebsiteInterface, WebsitesResponseInterface} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';


export const getWebsiteListRequest = async (payload: RequestPayloadType<any>): Promise<WebsitesResponseInterface | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('websites/'), payload);
    return await sendRequest<WebsitesResponseInterface>(sendRequestParams);
};

export const getWebsiteRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<WebsiteInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`websites/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const putWebsiteRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null | WebsiteFormErrorsType> => {
    payload.method = 'PUT';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`websites/${payload.routeParams.id}/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const deleteWebsiteRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null> => {
    payload.method = 'DELETE';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`websites/${payload.routeParams.id}/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};
