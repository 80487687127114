import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {DealBtnConfirmModalComponentInterface} from '../../typings';


export const ConfirmDealModalComponent: FC<DealBtnConfirmModalComponentInterface> = ({handleCancelClick, handleConfirmClick, setShowModal, showModal}) => {
    const {t} = useTranslation();


    return (
        <Modal title={t('Make the deal')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <p>{t('By making the deal, you accept the publisher\'s terms. Once you accept, we will put the deal amount in your account on hold')}</p>
            <div className="controls controls_align_justify">
                <Button theme={'stroke'} onClick={handleCancelClick} textContent={t('Back')}/>
                <Button theme={'confirm'} onClick={handleConfirmClick} textContent={t('Make a deal')}/>
            </div>
        </Modal>
    );
};
