import React, {FC} from 'react';
import {useCommonSelector} from 'hooks';
import {WebsiteInterface} from 'typings';
import websiteNameForPlacementCardStyle from './WebsiteNameType.module.scss';


export const WebsiteNameTypeComponent: FC<{ website: WebsiteInterface; }> = ({website}) => {
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');


    return (
        <div className={websiteNameForPlacementCardStyle.websiteShortInfo}>
            {!!website.uploaded_logo?.file_url && <img src={website.uploaded_logo.file_url} alt={website.name} width={30} height={30} className={websiteNameForPlacementCardStyle.websiteLogo}/>}
            <span>{website.name}</span>
            <span className={websiteNameForPlacementCardStyle.websiteShortInfoDelimiter}>|</span>
            <a href={website.url} className={websiteNameForPlacementCardStyle.websiteType} target="_blank">
                {!!website.website_type_logo && <img className={websiteNameForPlacementCardStyle.websiteTypeLogo} src={website.website_type_logo} alt=""/>}
                {website.subtype ? websiteSubTypesMap[website.subtype]?.text : websiteTypesMap[website.type]?.text}
            </a>
        </div>
    );
};
