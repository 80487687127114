import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {nonFieldErrorsKey} from 'consts';
import {getBalances, notificationListPushItem} from 'core';
import {i18n} from 'i18n';
import {RequestPayloadType} from 'typings';
import {getBalanceTransactionRequestsRequest, getBalanceTransactionsRequest, postBalanceTransactionRequestsRequest, postRegisterWithdrawalRequest} from './finance.api';
import {
    getBalanceTransactionList,
    getBalanceTransactionRequestsList,
    hideFinanceBtnLoader,
    hideFinanceError,
    hideFinanceFormLoader,
    hideFinanceLoader,
    postBalanceTransactionRequests,
    postRegisterWithdrawal,
    setBalanceTransactionList,
    setBalanceTransactionListCount,
    setBalanceTransactionRequestsList,
    setBalanceTransactionRequestsListCount,
    setFinanceFormErrors,
    setFinanceShowForm,
    showFinanceBtnLoader,
    showFinanceError,
    showFinanceFormLoader,
    showFinanceLoader,
} from './finance.reducer';


function* getBalanceTransactionListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showFinanceLoader());
        yield put(hideFinanceError());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data = yield call(getBalanceTransactionsRequest, requestParams);
        if (data) {
            yield put(setBalanceTransactionList(data.results));
            yield put(setBalanceTransactionListCount(data.count));
            yield put(hideFinanceLoader());
        }
    } catch (e) {
        yield put(showFinanceError());
        yield put(hideFinanceLoader());
    }
}

function* getBalanceTransactionRequestsListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showFinanceLoader());
        yield put(hideFinanceError());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data = yield call(getBalanceTransactionRequestsRequest, requestParams);
        if (data) {
            yield put(setBalanceTransactionRequestsList(data.results));
            yield put(setBalanceTransactionRequestsListCount(data.count));
            yield put(hideFinanceLoader());
        }
    } catch (e) {
        yield put(showFinanceError());
        yield put(hideFinanceLoader());
    }
}

function* createBalanceTransactionRequest(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showFinanceFormLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        yield call(postBalanceTransactionRequestsRequest, requestParams);
        yield put(setFinanceShowForm(true));
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Balance transaction request has been created successfully'),
            theme: ENotificationsThemes.success,
        }));
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
    } catch (error) {
        yield put(setFinanceFormErrors(error));
    } finally {
        yield put(hideFinanceFormLoader());
    }
}

function* createRegisterWithdrawal(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showFinanceBtnLoader());
        yield call(postRegisterWithdrawalRequest, {});
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Funds has been successfully withdrawn'),
            theme: ENotificationsThemes.success,
        }));
        yield put(getBalances());
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error[nonFieldErrorsKey] || i18n.t('Something went wrong with the withdrawal')}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideFinanceBtnLoader());
    }

}


export function* financeWatcher() {
    yield takeEvery(getBalanceTransactionList.type, getBalanceTransactionListWorker);
    yield takeEvery(getBalanceTransactionRequestsList.type, getBalanceTransactionRequestsListWorker);
    yield takeEvery(postBalanceTransactionRequests.type, createBalanceTransactionRequest);
    yield takeEvery(postRegisterWithdrawal.type, createRegisterWithdrawal);
}

