import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {matchPath, useLocation, useSearchParams} from 'react-router-dom';
import {Button} from 'components';
import {FinanceRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {BalanceInterface, UserInterface} from 'typings';
import {getBalanceTransactionRequestsList, postRegisterWithdrawal} from '../../finance.reducer';
import {RefillModal} from '../refillModal/RefillModal.component';
import {WithdrawModalComponent} from '../withdrawModal/WithdrawModal.component';
import {UserBalanceItemComponent} from './UserBalanceItem.component';
import userBalanceStyle from './UserBalance.module.scss';


export const UserBalanceComponent: FC<{ className: string; user: UserInterface; }> = ({className, user}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const balances: BalanceInterface[] = useCommonSelector<BalanceInterface[]>('balances');
    const {isBtnLoading} = useSelector((state: RootState) => state.financeReducer);
    const [showRefillModal, setShowRefillModal] = useState<boolean>(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const {pathname} = useLocation();


    const handleRefillClick = () => {
        setShowRefillModal(true);
    };

    const handleWithdrawClick = () => {
        setShowWithdrawModal(true);
    };

    const handleWithdrawFundsClick = () => {
        dispatch(postRegisterWithdrawal({
            ...matchPath(`${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.PAYMENT_REQUESTS}/*`, pathname) && {
                updateDataMethod: getBalanceTransactionRequestsList({
                    queryParams: searchParams,
                    controller: new AbortController(),
                }),
            },
        }));
        setShowWithdrawModal(false);
    };


    return (
        <div className={`${className} ${userBalanceStyle.userBalance}`}>

            <UserBalanceItemComponent amount={Number(balances?.[0]?.balance || 0).toLocaleString()}
                                      className={userBalanceStyle.userBalance__item}
                                      currency={balances?.[0]?.currency_id}
                                      isMain
                                      title={t('Balance')}/>
            <hr className={userBalanceStyle.userBalance__hr}/>

            <UserBalanceItemComponent amount={Number(balances?.[0]?.frozen_balance || 0).toLocaleString()}
                                      className={userBalanceStyle.userBalance__item}
                                      currency={balances?.[0]?.currency_id}
                                      title={t('On hold')}/>
            <hr className={userBalanceStyle.userBalance__hr}/>

            <div className={userBalanceStyle.userBalance__actions}>
                {user.is_allowed_to_be_implementer && <Button onClick={handleWithdrawClick} theme={'ghost'} textContent={t('Withdraw funds')} isBtnLoading={isBtnLoading}/>}
                {user.is_allowed_to_be_payer && <Button onClick={handleRefillClick} textContent={t('Deposit')}/>}
            </div>
            {user.is_allowed_to_be_payer && <RefillModal setShowModal={setShowRefillModal} showModal={showRefillModal}/>}
            {user.is_allowed_to_be_implementer && <WithdrawModalComponent amountToWithdraw={balances?.[0]?.amount_to_withdraw}
                                                                          currencyId={balances?.[0]?.currency_id}
                                                                          handleConfirmClick={handleWithdrawFundsClick}
                                                                          setShowModal={setShowWithdrawModal}
                                                                          showModal={showWithdrawModal}/>}
        </div>
    );
};
