import React, {FC} from 'react';
import {AvatarComponent} from '../Avatar/Avatar.component';
import partnerNameStyle from './PartnerName.module.scss';


export const PartnerNameComponent: FC<{ isImplementer: boolean; avatarText: string; name: string; className?: string; }> = ({isImplementer, avatarText, name, className}) => {
    return (
        <div className={`${partnerNameStyle.partnerNameWrap} ${className ? className : ''}`}>
            <AvatarComponent text={avatarText} isImplementer={isImplementer}/>
            <span className="textBodyMedium_02">{name}</span>
        </div>
    );
};
