import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {getFullSiteListRequest, postWebsiteRequest} from 'core';
import {history} from 'index';
import {RequestPayloadType} from 'typings';
import {
    finishOnboarding,
    getSiteList,
    hideOnboardingLoader,
    postSite,
    setCloseOnboarding,
    setCreatedWebsiteId,
    setCurrentStep,
    setOnboardingFormError,
    setSiteList,
    showOnboardingLoader,
} from './onboarding.reducer';
import {finishOnboardingRequest} from './onboarding.api';


function* finishOnboardingWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showOnboardingLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: {
                is_delete_all_websites: payload.payload.isDeleteAllWebsites,
                onboarding_created_website_id: payload.payload.createdWebsiteId,
                website_ids_to_delete: payload.payload.siteIdsToDelete,
            },
        };
        yield call(finishOnboardingRequest, requestParams);
        history.push(payload.payload.nextPage);
        yield put(setCloseOnboarding());
    } finally {
        yield put(hideOnboardingLoader());
    }
}

function* getSiteListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showOnboardingLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            signal: payload.payload.controller?.signal,
        };

        const sites = yield call(getFullSiteListRequest, requestParams);
        yield put(setSiteList(sites.results));
    } catch (error) {

    } finally {
        yield put(hideOnboardingLoader());
    }
}

function* createSiteWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showOnboardingLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        const site = yield call(postWebsiteRequest, requestParams);
        yield put(setCreatedWebsiteId(site.id));
        yield put(setCurrentStep(payload.payload.nextStep));
    } catch (error) {
        yield put(setOnboardingFormError(error));
    } finally {
        yield put(hideOnboardingLoader());
    }
}

export function* onboardingWatcher() {
    yield takeEvery(finishOnboarding.type, finishOnboardingWorker);
    yield takeEvery(getSiteList.type, getSiteListWorker);
    yield takeEvery(postSite.type, createSiteWorker);
}
