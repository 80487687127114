import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Dropdown, IDropdownOption, Input, TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, FilterChoicesComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum} from 'typings';
import {getEventDimension} from 'utils';
import {PlacementFilterEnum} from '../../typings';
import {PlacementFilterInterface} from './PlacementFilter.interface';


export const PlacementFilterComponent: FC = () => {
    const {t} = useTranslation();
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const placementTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('placementTypes');
    const websiteTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('websiteTypes');
    const initFilterValue: PlacementFilterInterface = {
        [PlacementFilterEnum.CATEGORY]: [],
        [PlacementFilterEnum.COVERAGE__GT]: '',
        [PlacementFilterEnum.COVERAGE__LT]: '',
        [PlacementFilterEnum.GEO]: [],
        [PlacementFilterEnum.PLACEMENT_TYPE]: [],
        [PlacementFilterEnum.WEBSITE_TYPE]: [],
    };
    const [filterValue, setFilterValue] = useState<PlacementFilterInterface>(initFilterValue);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setFilterValue({
            ...filterValue,
            [PlacementFilterEnum.CATEGORY]: searchParams.getAll(PlacementFilterEnum.CATEGORY),
            [PlacementFilterEnum.COVERAGE__GT]: searchParams.get(PlacementFilterEnum.COVERAGE__GT) || '',
            [PlacementFilterEnum.COVERAGE__LT]: searchParams.get(PlacementFilterEnum.COVERAGE__LT) || '',
            [PlacementFilterEnum.GEO]: searchParams.getAll(PlacementFilterEnum.GEO),
            [PlacementFilterEnum.PLACEMENT_TYPE]: searchParams.getAll(PlacementFilterEnum.PLACEMENT_TYPE),
            [PlacementFilterEnum.WEBSITE_TYPE]: searchParams.getAll(PlacementFilterEnum.WEBSITE_TYPE),
        });
    }, [searchParams]);


    const onInputChange = (controlName: string) => (value: TDropdownOptionValue[] | string) => {
        let newValue;
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (typeof value == 'string') {
            newValue = value;
            if (value) {
                newSearchParams.append(controlName, value);
            }
        } else if (Array.isArray(value)) {
            newValue = value || [];
            value.forEach(item => {
                newSearchParams.append(controlName, `${item}`);
            });
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);
        //TODO: for geo label: val - active/disable
        gtag('event', `${GtagNameEnum.PLACEMENT}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.OFFERS, `${controlName} - ${value.toString()}`));
    };

    const onCheckboxChange = (controlName: string, value: TDropdownOptionValue) => (checked: boolean) => {
        let newValue = filterValue[controlName];
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (checked) {
            newValue.push(`${value}`);
        } else {
            newValue = newValue.filter(v => v !== `${value}`);
        }
        newValue.forEach(item => {
            newSearchParams.append(controlName, `${item}`);
        });
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        const optionsMap = {
            [PlacementFilterEnum.CATEGORY]: categories,
            [PlacementFilterEnum.PLACEMENT_TYPE]: placementTypes,
            [PlacementFilterEnum.WEBSITE_TYPE]: websiteTypes,
        };
        gtag('event', `${GtagNameEnum.PLACEMENT}${GtagNameEnum.FILTER}`, getEventDimension(GtagCategoryEnum.OFFERS, `${controlName} - ${optionsMap[controlName].find(option => option.value === value).text} - ${checked ? 'active' : 'disable'}`));
    };

    const handleResetFilterClick = (controlName?: string) => () => {
        const newSearchParams = new URLSearchParams(controlName ? searchParams : undefined);
        if (controlName) {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue(controlName
            ? {
                ...filterValue,
                [controlName]: initFilterValue[controlName],
            }
            : initFilterValue);
        setSearchParams(newSearchParams);
    };


    return (
        <>
            <Dropdown value={filterValue[PlacementFilterEnum.GEO]}
                      placeholder={t('Countries')}
                      uncheckOptionByClick
                      optionsListMaxHeight={geo?.length ? 300 : undefined}
                      onMultipleChange={onInputChange(PlacementFilterEnum.GEO)}
                      multiple
                      options={geo}/>
            <FilterChoicesComponent choicesList={categories}
                                    controlName={PlacementFilterEnum.CATEGORY}
                                    counter={filterValue[PlacementFilterEnum.CATEGORY]?.length}
                                    handleResetFilterClick={handleResetFilterClick(PlacementFilterEnum.CATEGORY)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Categories')}
                                    filterValue={filterValue}/>
            <FilterChoicesComponent choicesList={placementTypes}
                                    controlName={PlacementFilterEnum.PLACEMENT_TYPE}
                                    counter={filterValue[PlacementFilterEnum.PLACEMENT_TYPE]?.length}
                                    handleResetFilterClick={handleResetFilterClick(PlacementFilterEnum.PLACEMENT_TYPE)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Placement types')}
                                    filterValue={filterValue}/>
            <FilterChoicesComponent choicesList={websiteTypes}
                                    controlName={PlacementFilterEnum.WEBSITE_TYPE}
                                    counter={filterValue[PlacementFilterEnum.WEBSITE_TYPE]?.length}
                                    handleResetFilterClick={handleResetFilterClick(PlacementFilterEnum.WEBSITE_TYPE)}
                                    onCheckboxChange={onCheckboxChange}
                                    filterLabel={t('Site types')}
                                    filterValue={filterValue}/>
            <p className="textBodyMedium_03 filterTitle">{t('Coverage, visitors/mo.')}</p>
            <div className="filterLine">
                <Input value={filterValue[PlacementFilterEnum.COVERAGE__GT]}
                       type="number"
                       onChange={onInputChange(PlacementFilterEnum.COVERAGE__GT)}
                />
                —
                <Input value={filterValue[PlacementFilterEnum.COVERAGE__LT]}
                       type="number"
                       onChange={onInputChange(PlacementFilterEnum.COVERAGE__LT)}
                />
            </div>
            <Button className="filterControls"
                    eventCategory={GtagCategoryEnum.OFFERS}
                    eventLabel="reset all filters button"
                    eventName={`${GtagNameEnum.PLACEMENT}${GtagNameEnum.FILTER}`}
                    onClick={handleResetFilterClick()}
                    textContent={t('Reset all filters')}
                    theme={'stroke'}/>
        </>
    );
};
