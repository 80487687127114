import React, {FC} from 'react';
import totalBlockStyle from './TotalBlock.module.scss';


export const TotalBlockComponent: FC<{ amount: number; currency: string; text: string; }> = ({amount, currency, text}) => {
    return (
        <div className={`textBodyMedium_03 ${totalBlockStyle.totalBlock}`}>
            <span>{text}</span>
            <span>{currency}&nbsp;{amount}</span>
        </div>
    );
};
