import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {WithdrawModalComponentInterface} from './WithdrawModalComponent.interface';


export const WithdrawModalComponent: FC<WithdrawModalComponentInterface> = ({amountToWithdraw, currencyId, handleConfirmClick, setShowModal, showModal}) => {
    const {t} = useTranslation();

    const handleCancelClick = () => {
        setShowModal(false);
    };


    return (
        <Modal title={t('Withdraw funds')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            {!!+amountToWithdraw
                ? <>
                    <p>{t('{{amountToWithdraw}} {{currencyId}} will be credited to your Admitad Earnings account', {amountToWithdraw: amountToWithdraw, currencyId: currencyId})}</p>
                    <div className="controls controls_align_justify">
                        <Button theme={'stroke'} textContent={t('Cancel')} onClick={handleCancelClick}/>
                        <Button theme={'confirm'} textContent={t('Confirm')} onClick={handleConfirmClick}/>
                    </div>
                </>
                : <>
                    <p>{t('There are no deals to be withdrawn')}</p>
                </>}
        </Modal>
    );
};
