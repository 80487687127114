import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import progressStyle from './Progress.module.scss';


export const ProgressComponent: FC<{ step: number; }> = ({step}) => {
    const {t} = useTranslation();
    const totalStep = 3;


    return (
        <>
            <div className={progressStyle.progress}>
                {[...Array(totalStep)].map((item, i) => (
                    <span className={`${progressStyle.progressBtn} ${i < step ? progressStyle.progressBtn_passed : ''}`} key={i}/>
                ))}
            </div>
            <div className={progressStyle.progressNote}>{t('{{step}} of {{totalStep}}', {step: step, totalStep})}</div>
        </>
    );
};
