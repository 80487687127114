import React, {FC, ForwardedRef, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import {Input} from '@design-system/ui-kit';
import datepickerIcon from 'assets/images/icons/datepicker.svg';
import {ResetInputBtnComponent} from '../ResetInputBtn/ResetInputBtn.component';
import {DatePickerInputComponentInterface} from './DatePickerInputComponent.interface';
import {PropsType} from './Props.type';
import {RefType} from './Ref.type';


export const DatePickerInputComponent: FC<DatePickerInputComponentInterface> = ({className, errorMessage, inputName, inputPlaceholder, inputValue, onDateChange, onInputChange, onInputBlur}) => {
    const DateInput = forwardRef<RefType, PropsType>(({onClick, value}: PropsType, ref: ForwardedRef<number>) => (
        <Input name={inputName}
               placeholder={inputPlaceholder}
               onClick={onClick}
               endContent={inputValue ? <ResetInputBtnComponent handleClick={() => onDateChange(null)}/> : <img src={datepickerIcon} alt=""/>}
               ref={ref}
               value={inputValue?.toLocaleDateString()}
               errorMessage={errorMessage}
               onChange={onInputChange}
               onBlur={onInputBlur}/>
    ));


    return (
        <div className={className}>
            <DatePicker selected={inputValue}
                        onChange={onDateChange}
                        customInput={<DateInput/>}
                        showPopperArrow={false}
                        calendarClassName="marketplacex-datepicker"/>
        </div>
    );
};
