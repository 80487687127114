import React, {ChangeEvent, FC, FormEvent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {Dropdown, ELoaderSizes, IDropdownOption, Input, Loader, TDropdownOptionValue} from '@design-system/ui-kit';
import {ReactComponent as ClipIcon} from 'assets/images/clip.svg';
import {ReactComponent as ErrorIcon} from 'assets/images/error.svg';
import {ReactComponent as SendIcon} from 'assets/images/send.svg';
import {Button, DatePickerInputComponent, LinkComponent} from 'components';
import {nonFieldErrorsKey} from 'consts';
import {clearAttachments, deleteAttachment, uploadFile} from 'core';
import {DealStatusEnum, GtagCategoryEnum, GtagNameEnum, UploadedAttachmentInterface} from 'typings';
import {getEventDimension, gtagSendEvent} from 'utils';
import {DealFormControlsEnum, DealFormStateType} from '../../typings';
import {clearDealFormError, getDeal, postDeal, setDealFormErrors, setResetDealsForm, setShowSuggestionForm} from '../../deals.reducer';
import {JobIsDoneBtnComponent} from '../btnJobIsDone/JobIsDoneBtn.component';
import {RefuseBtnComponent} from '../btnRefuse/RefuseBtn.component';
import {ConfirmOfferModalComponent} from '../confirmOfferModal/ConfirmOfferModal.component';
import {DealFileComponent} from '../file/DealFile.component';
import {DealFormComponentInterface} from './DealFormComponent.interface';
import {PromotionalDateInterface} from './PromotionalDate.interface';
import dealFormStyle from './DealForm.module.scss';


export const DealFormComponent: FC<DealFormComponentInterface> = ({appConfig, balances, currencies, deal, files, formErrors, isResetForm, isChatLoading, isShowForm}) => {
    const location: any = useLocation();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {dealId} = useParams();
    const ref = useRef(null);

    const nullDate: PromotionalDateInterface = {
        [DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]: null,
        [DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]: null,
    };

    const initFormState: DealFormStateType = {
        [DealFormControlsEnum.ATTACHMENTS]: [],
        [DealFormControlsEnum.TEXT]: '',
        [DealFormControlsEnum.SUGGESTED_PRICE]: '',
        [DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]: '',
        [DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]: '',
    };
    const [isShowSuggestionForm, setIsShowSuggestionForm] = useState<boolean>(location.state?.isShowSuggestionForm);
    const [currentDealTerms, setCurrentDealTerms] = useState<DealFormStateType | null>(null);
    const [currencyForDropdown, setCurrencyForDropdown] = useState<IDropdownOption[]>([]);
    const [date, setDate] = useState<PromotionalDateInterface>(nullDate);
    const [formState, setFormState] = useState<DealFormStateType>(initFormState);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [isAttachmentsLengthError, setIsAttachmentsLengthError] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);


    const handleFormSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        if (isShowSuggestionForm && deal?.is_possible_to_create_change_request && deal?.companion_info.id === deal?.implementer.id) {
            setShowModal(true);
        } else {
            makeOffer();
        }
    };

    const makeOffer = () => {
        if (!dealId) return;
        const formData = {
            [DealFormControlsEnum.IS_CREATE_CHANGE_REQUEST]: (isShowSuggestionForm && deal?.is_possible_to_create_change_request),
            [DealFormControlsEnum.TEXT]: formState[DealFormControlsEnum.TEXT],
            [DealFormControlsEnum.ATTACHMENTS]: Object.values(files).filter(file => file.meta.success).map(fileData => (fileData.uploadedAttachments as UploadedAttachmentInterface).file_id),
            [DealFormControlsEnum.CHANGE_REQUEST]: {
                [DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]: date[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]?.toLocaleDateString('en-CA') || null,
                [DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]: date[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]?.toLocaleDateString('en-CA') || null,
                [DealFormControlsEnum.SUGGESTED_PRICE]: formState[DealFormControlsEnum.SUGGESTED_PRICE],
            },
        };
        return dispatch(postDeal({dealId, data: formData}));
    };

    const handleInputChange = (controlName: string) => {
        dispatch(clearDealFormError({inputName: controlName}));
        dispatch(clearDealFormError({inputName: nonFieldErrorsKey}));
    };

    const onInputTextChange = (newValue: ChangeEvent<HTMLTextAreaElement>) => {
        setFormState({
            ...formState,
            [DealFormControlsEnum.TEXT]: newValue.target.value,
        });

        handleInputChange(DealFormControlsEnum.TEXT);
    };

    const onInputChange = (controlName: string) => (newValue: TDropdownOptionValue | null) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputPriceChange = (controlName: string) => (newValue: string) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);

        if (deal?.companion_info.id === deal?.implementer.id && +newValue > +balances?.[0]?.balance) {
            dispatch(setDealFormErrors({[DealFormControlsEnum.SUGGESTED_PRICE]: [t('Insufficient balance')]}));
        }
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const onDateChange = (value: any, controlName: DealFormControlsEnum) => {
        setDate({
            ...date,
            [controlName]: value,
        });
        handleInputChange(controlName);
    };

    const prefillFormWithDealTerms = (resetText = false) => {
        setDate({
            [DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]: (currentDealTerms || initFormState)[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE],
            [DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]: (currentDealTerms || initFormState)[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE],
        });
        setFormState({
            ...formState,
            [DealFormControlsEnum.SUGGESTED_PRICE]: (currentDealTerms || initFormState)[DealFormControlsEnum.SUGGESTED_PRICE],
            ...resetText && {[DealFormControlsEnum.TEXT]: ''},
        });
    };

    const toggleForm = () => {
        setIsShowSuggestionForm(!isShowSuggestionForm);
        prefillFormWithDealTerms();
    };

    const handleChange = (e) => {
        const maxFilesCount = 5;
        const restFilesCount = Math.abs(maxFilesCount - Object.entries(files).length);
        setIsAttachmentsLengthError(false);
        if (Object.entries(files).length >= maxFilesCount) {
            setIsAttachmentsLengthError(true);
            return;
        }
        const attachments: FileList = e.target.files;
        if (attachments.length > restFilesCount) {
            setIsAttachmentsLengthError(true);
        }
        for (let i = 0; i < attachments.length && i < restFilesCount; i++) {
            dispatch(uploadFile({file: attachments.item(i) as File}));
        }
        gtag('event', `${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} add file`, getEventDimension(GtagCategoryEnum.DEALS, `${deal.name} - ${deal.id}`));
    };

    const handleDeleteFile = (fileId: string) => {
        dispatch(deleteAttachment(fileId));
    };

    const handleShowMsgClick = (elId: number) => {
        const targetEl = document.getElementById(`${elId}`);
        if (targetEl) targetEl.scrollIntoView({behavior: 'smooth'});
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleConfirmClick = () => {
        makeOffer();
        setShowModal(false);
    };

    const onKeyDownTextArea = (e) => {
        if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
            handleFormSubmit();
        }
    };


    useEffect(() => {
        if (!isShowForm) return;
        setIsShowSuggestionForm(!!isShowForm);
        dispatch(setShowSuggestionForm(false));
    }, [dispatch, isShowForm]);

    useEffect(() => {
        if (!isResetForm) return;
        setIsShowSuggestionForm(false);
        prefillFormWithDealTerms(true);
        ref?.current && ((ref?.current as unknown as HTMLInputElement).value = '');
        dispatch(clearAttachments());
        dispatch(setResetDealsForm(false));
    }, [dispatch, isResetForm]);

    useEffect(() => {
        const valid = formState[DealFormControlsEnum.TEXT] !== ''
            || !!formState[DealFormControlsEnum.ATTACHMENTS]?.length
            || !!Object.keys(files).length
            || formState[DealFormControlsEnum.SUGGESTED_PRICE] !== currentDealTerms?.[DealFormControlsEnum.SUGGESTED_PRICE]
            || date[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE] !== currentDealTerms?.[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]
            || date[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE] !== currentDealTerms?.[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE];
        setFormValid(valid);
    }, [date, files, formState]);

    useEffect(() => {
        if (!currencies.length) return;
        setCurrencyForDropdown(currencies);
    }, [currencies]);

    useEffect(() => {
        if (!currencyForDropdown.length) return;
        setFormState({
            ...formState,
            [DealFormControlsEnum.CURRENCY]: `${currencyForDropdown[0]?.value}`,
        });
    }, [currencyForDropdown]);

    useEffect(() => {
        if (!deal) return;
        setCurrentDealTerms({
            [DealFormControlsEnum.SUGGESTED_PRICE]: deal.companion_info.id === deal.implementer.id ? deal.price : deal.price_with_commission,
            [DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]: deal.placement_end_date ? new Date(deal.placement_end_date as string) : null,
            [DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]: deal.placement_start_date ? new Date(deal.placement_start_date as string) : null,
        });
    }, [deal]);

    useEffect(() => {
        prefillFormWithDealTerms();
    }, [currentDealTerms]);


    return (
        <form onSubmit={handleFormSubmit} noValidate>
            <div className={dealFormStyle.dealFormHeader}>
                {deal?.is_possible_to_create_change_request && <>
                    {isShowSuggestionForm ? <>
                        <p className={`textBodyMedium_02 ${dealFormStyle.dealFormHeaderText}`}>{t('Propose my terms')}</p>
                        <Button className={dealFormStyle.dealFormHeaderBtn} theme={'ghost'} type="button" onClick={toggleForm} textContent={t('Hide')}/>
                    </> : <>
                        <p className={dealFormStyle.dealFormSupText}>{t('Ask a question in the comment section or propose your terms using the button on the right')}</p>
                        <Button className={dealFormStyle.dealFormHeaderBtn} theme={'ghost'} type="button" onClick={toggleForm} textContent={t('+ Propose my terms')}/>
                    </>}
                </>}
                {deal?.status === DealStatusEnum.IN_PROGRESS && <>
                    <p className={dealFormStyle.dealFormSupText}>
                        {t('Deal in progress (made {{date}})', {date: new Date(deal.approved_at || '').toLocaleDateString()})}.
                        &ensp;
                        <LinkComponent type="button" onClick={() => handleShowMsgClick(deal.approve_message_id as number)} textContent={t('Show message')}/>
                    </p>
                    {deal.is_possible_to_decline && <RefuseBtnComponent deal={deal}
                                                                        eventCategory={GtagCategoryEnum.DEALS}
                                                                        eventLabel={`${deal.name} - ${deal.id}`}
                                                                        eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.CHAT}${GtagNameEnum.CLICK} refuse`}
                                                                        updateDataMethod={getDeal({dealId: `${deal.id}`})}/>}
                    <JobIsDoneBtnComponent deal={deal}
                                           eventCategory={GtagCategoryEnum.DEALS}
                                           eventLabel={`${deal.name} - ${deal.id}`}
                                           eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.CHAT}${GtagNameEnum.CLICK} job is done button`}
                                           showConfirmDialog={deal.companion_info.id === deal.implementer.id}
                                           textContent={t('Work complete')}
                                           updateDataMethod={getDeal({dealId: `${deal.id}`})}/>
                </>}
                {deal?.status === DealStatusEnum.DONE && !deal.is_possible_to_mark_as_ready &&
                <p className={`${dealFormStyle.dealFormSupText} ${dealFormStyle.dealFormSupText_center}`}>{t('Waiting for the advertiser\'s response')}</p>}
                {deal?.status === DealStatusEnum.DONE && deal.is_possible_to_mark_as_ready && <>
                    <p className={dealFormStyle.dealFormSupText}>{t('{{name}} has fulfilled all terms of the deal', {name: `${deal.implementer.first_name} ${deal.implementer.last_name[0]}.`})}</p>
                    {deal.is_possible_to_decline && <RefuseBtnComponent deal={deal}
                                                                        eventCategory={GtagCategoryEnum.DEALS}
                                                                        eventLabel={`${deal.name} - ${deal.id}`}
                                                                        eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.CHAT}${GtagNameEnum.CLICK} refuse`}
                                                                        updateDataMethod={getDeal({dealId: `${deal.id}`})}/>}
                    <JobIsDoneBtnComponent deal={deal}
                                           eventCategory={GtagCategoryEnum.DEALS}
                                           eventLabel={`${deal.name} - ${deal.id}`}
                                           eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.CHAT}${GtagNameEnum.CLICK} accept work`}
                                           showConfirmDialog={deal.companion_info.id === deal.implementer.id}
                                           textContent={t('Accept work')}
                                           updateDataMethod={getDeal({dealId: `${deal.id}`})}/>
                </>}
                {deal?.status === DealStatusEnum.CLOSED && <p className={`${dealFormStyle.dealFormSupText} ${dealFormStyle.dealFormSupText_center}`}>
                    {t('{{name}} fulfilled all the terms of the deal and completed the deal on {{date}}', {
                        name: `${deal.payer.first_name} ${deal.payer.last_name[0]}.`,
                        date: new Date(deal.closed_at || '').toLocaleDateString(),
                    })}
                </p>}
            </div>
            {(deal?.is_possible_to_create_change_request && isShowSuggestionForm) && <>
                <div className="form__line">
                    <div className="form__lineRange">
                        <DatePickerInputComponent inputName={DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE}
                                                  onInputChange={onInputChange(DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE)}
                                                  inputValue={date[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE] || null}
                                                  inputPlaceholder={t('Start date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[DealFormControlsEnum.SUGGESTED_PLACEMENT_START_DATE]?.toString()}/>
                        —
                        <DatePickerInputComponent inputName={DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE}
                                                  onInputChange={onInputChange(DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE)}
                                                  inputValue={date[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE] || null}
                                                  inputPlaceholder={t('End date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]?.toString()}/>
                    </div>
                    <div className="form__lineWithCurrencyStart">
                        <div className="form__dropdownWrap">
                            <Dropdown value={appConfig.default_currency}
                                      placeholder={t('Currency')}
                                      uncheckOptionByClick
                                      optionsListMaxHeight={currencyForDropdown.length ? 300 : undefined}
                                      disabled
                                      onChange={onInputChange('currency')}
                                      options={currencyForDropdown}/>
                        </div>
                        <Input type="number"
                               name={DealFormControlsEnum.SUGGESTED_PRICE}
                               placeholder={deal?.companion_info.id === deal?.implementer.id ? t('Publisher income') : t('Advertiser payment')}
                               value={formState[DealFormControlsEnum.SUGGESTED_PRICE]}
                               onChange={onInputPriceChange(DealFormControlsEnum.SUGGESTED_PRICE)}
                            // errorMessage={formErrors?.[DealFormControlsEnum.SUGGESTED_PRICE]?.toString()}
                               onBlur={onInputBlur}/>
                    </div>
                </div>
                <div className={dealFormStyle.dealFormTotalBlock}>
                    {deal?.companion_info.id === deal?.payer.id
                        ? <>
                            <span>
                                <span className="textBodyMedium_03">{t('Publisher income')}</span>
                                &ensp;
                                {t('(excl. Admitad fee)')}
                            </span>
                            <span className="textBodyMedium_03">
                                {formState[DealFormControlsEnum.CURRENCY] || currencyForDropdown?.[0]?.value}
                                &nbsp;
                                {(Math.round(+formState[DealFormControlsEnum.SUGGESTED_PRICE] / (1 + appConfig.commission_percent) * 100) / 100).toLocaleString()}
                            </span>
                        </>
                        : <>
                            <span>
                                <span className="textBodyMedium_03">{t('Advertiser payment')}</span>
                                &ensp;
                                {t('(incl. Admitad fees)')}
                            </span>
                            <span className="textBodyMedium_02">
                                {formState[DealFormControlsEnum.CURRENCY] || currencyForDropdown?.[0]?.value}
                                &nbsp;
                                {(Math.round(+formState[DealFormControlsEnum.SUGGESTED_PRICE] * (1 + appConfig.commission_percent) * 100) / 100).toLocaleString()}
                            </span>
                        </>}
                </div>
            </>}
            <div className={dealFormStyle.dealFormBlock}>
                {formErrors && !!Object.keys(formErrors).length && <div className={dealFormStyle.dealFormError}>
                    <ErrorIcon className={dealFormStyle.dealErrorIcon}/>
                    {Object.values(formErrors || {}).map((error, index) => <span key={index}>{(error as string[]).join(' ')}</span>)}
                </div>}
                {!!Object.keys(files).length && (
                    <div className={dealFormStyle.dealFormFiles}>
                        <div className={dealFormStyle.dealFormFilesWrap}>
                            {Object.entries(files).map(([fileKey, file]) => <DealFileComponent key={fileKey} file={file} handleDeleteClick={handleDeleteFile}/>)}
                        </div>
                        {isAttachmentsLengthError && <p className={`textBody_04 ${dealFormStyle.dealFormFilesError}`}>
                            <ErrorIcon className={dealFormStyle.dealErrorIcon}/>
                            {t('You cannot upload more than 5 files at a time')}
                        </p>}
                    </div>
                )}
                <div className={dealFormStyle.dealFormMsgBlock}>
                    <label className={dealFormStyle.btnIcon}>
                        <ClipIcon/>
                        <input ref={ref} className={dealFormStyle.dealHiddenInput} type="file" onChange={handleChange} multiple/>
                    </label>
                    <textarea className={dealFormStyle.dealFormTextarea}
                              placeholder={t('Write your message here...')}
                              value={formState[DealFormControlsEnum.TEXT]}
                              onChange={onInputTextChange}
                              onKeyDown={onKeyDownTextArea}/>
                    {isChatLoading
                        ? <Loader inline noMargin={false} size={ELoaderSizes.s}/>
                        : <>
                            {(deal?.is_possible_to_create_change_request && isShowSuggestionForm)
                                ? <Button eventCategory={GtagCategoryEnum.DEALS}
                                          eventLabel={`${deal.name} - ${deal.id}`}
                                          eventName={`${GtagNameEnum.DEAL} - make an offer button`}
                                          theme={'confirm'}
                                          textContent={t('Propose terms')}/>
                                : <button disabled={!formValid || !!Object.keys(formErrors || {}).length}
                                          onClick={gtagSendEvent(`${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} send message`, GtagCategoryEnum.DEALS, `${deal.name} - ${deal.id}`)}
                                          className={dealFormStyle.btnIcon}><SendIcon/></button>
                            }
                        </>}
                </div>
            </div>
            <ConfirmOfferModalComponent price={(Math.round(+formState[DealFormControlsEnum.SUGGESTED_PRICE] * (1 + appConfig.commission_percent) * 100) / 100).toLocaleString()}
                                        currency={currencyForDropdown?.[0]?.value}
                                        endDate={date[DealFormControlsEnum.SUGGESTED_PLACEMENT_END_DATE]}
                                        handleCancelClick={handleCancelClick}
                                        handleConfirmClick={handleConfirmClick}
                                        setShowModal={setShowModal}
                                        showModal={showModal}/>
        </form>
    );
};
