export enum DealFormControlsEnum {
    ATTACHMENTS = 'attachments',
    CHANGE_REQUEST = 'change_request',
    CURRENCY = 'currency',
    IS_CREATE_CHANGE_REQUEST = 'is_create_change_request',
    SUGGESTED_PRICE = 'suggested_price',
    SUGGESTED_PLACEMENT_START_DATE = 'suggested_placement_start_date',
    SUGGESTED_PLACEMENT_END_DATE = 'suggested_placement_end_date',
    TEXT = 'text',
}
