import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {ConfirmDeleteModalComponentInterface} from './ConfirmDeleteModalComponent.interface';


export const ConfirmDeleteModal: FC<ConfirmDeleteModalComponentInterface> = ({gtagCancel, gtagConfirm, handleCancelClick, handleConfirmClick, setShowModal, showModal}) => {
    const {t} = useTranslation();


    return (
        <Modal title={t('Are you sure you want to delete the offer?')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <div className="controls">
                <Button eventCategory={gtagCancel.eventCategory}
                        eventLabel={gtagCancel.eventLabel}
                        eventName={gtagCancel.eventName}
                        onClick={handleCancelClick}
                        theme={'stroke'}
                        textContent={t('No, cancel')}/>
                <Button eventCategory={gtagConfirm.eventCategory}
                        eventLabel={gtagConfirm.eventLabel}
                        eventName={gtagConfirm.eventName}
                        onClick={handleConfirmClick}
                        theme={'stroke'}
                        textContent={t('Yes, delete')}/>
            </div>
        </Modal>
    );
};
