import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router-dom';
import {LinkComponent} from 'components';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {DealHistoryInterface} from 'typings';
import historyItemStyle from './HistoryItem.module.scss';


export const HistoryItemComponent: FC<{ dealHistoryItem: DealHistoryInterface; }> = ({dealHistoryItem}) => {
    const {t} = useTranslation();


    return (
        <div key={dealHistoryItem.id}>
            <time className={historyItemStyle.historyItem__time}>{new Date(dealHistoryItem.created_at).toLocaleDateString()}</time>
            <p className={`textBodyMedium_03 word-break ${historyItemStyle.historyItem__name}`}>{dealHistoryItem.name}</p>
            <LinkComponent textContent={t('Open chat')}
                           to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${dealHistoryItem.id}`})}/>
        </div>
    );
};
