export enum CatalogRoutesPathEnum {
    CATALOG_OFFER_LIST = '/',
    CATALOG_OFFER = '/catalog/offer/:placementId',
    CATALOG_REQUEST_LIST = '/catalog/requests',
    CATALOG_REQUEST = '/catalog/requests/:placementId',
    CATALOG_REQUEST_DOWNLOAD_ALL = '/api/catalog/placement_requests/:placementId/download_all/',
    CATALOG_OFFER_DEAL_LIST = '/catalog/offer/:placementId/deals',
    CATALOG_REQUEST_DEAL_LIST = '/catalog/requests/:placementId/deals',

    CATALOG_USER_INFO = '/user',
    CATALOG_PLACEMENT_INFO = '/placement',
}
