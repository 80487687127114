import React, {FC} from 'react';
import {PlacementInfoBoxComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {PlacementType, UserInterface} from 'typings';
import {PlacementActionsComponent} from '../actions/PlacementActions.component';


export const AsideShortInfoComponent: FC<{ isRequest?: boolean; placement: PlacementType; }> = ({placement, isRequest}) => {
    const user: UserInterface = useCommonSelector<UserInterface>('user');


    return (
        <>
            <PlacementInfoBoxComponent name={placement.placement_type_name}
                                       logo={placement.placement_type_logo}
                                       price={isRequest ? placement.price : placement.price_with_commission}
                                       priceInclFee={!isRequest}
                                       currency={placement.currency}
                                       daysToImplement={placement.days_to_implement}
                                       startDate={placement.placement_start_date}
                                       endDate={placement.placement_end_date}/>
            <PlacementActionsComponent isAllowedToRespond={isRequest ? user.is_allowed_to_be_implementer : user.is_allowed_to_be_payer} isRequest={isRequest} placement={placement}/>
        </>
    );
};
