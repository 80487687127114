import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {DatePickerInputComponent} from 'components';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum} from 'typings';
import {getEventDimension} from 'utils';
import {FinanceFilterEnum} from '../../typings';
import dateFilterStyle from './FinanceDateFilter.module.scss';


export const FinanceDateFilterComponent: FC<{ className: string; }> = ({className}) => {
    const {t} = useTranslation();

    let [searchParams, setSearchParams] = useSearchParams();

    const [filterValue, setFilterValue] = useState<any>({
        [FinanceFilterEnum.CREATED_AT__GT]: '',
        [FinanceFilterEnum.CREATED_AT__LT]: '',
    });


    useEffect(() => {
        setFilterValue({
            ...filterValue,
            [FinanceFilterEnum.CREATED_AT__GT]: searchParams.get(FinanceFilterEnum.CREATED_AT__GT) ? new Date(`${searchParams.get(FinanceFilterEnum.CREATED_AT__GT)}`) : '',
            [FinanceFilterEnum.CREATED_AT__LT]: searchParams.get(FinanceFilterEnum.CREATED_AT__LT) ? new Date(`${searchParams.get(FinanceFilterEnum.CREATED_AT__LT)}`) : '',
        });
    }, [searchParams]);


    const onInputChange = (controlName: string) => (value: Date) => {
        let newValue = value;
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(controlName);
        if (value) {
            const dateValue = value.toLocaleDateString('en-CA');
            newSearchParams.append(controlName, dateValue);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setFilterValue({
            ...filterValue,
            [controlName]: newValue,
        });
        setSearchParams(newSearchParams);

        gtag('event', `${GtagNameEnum.FINANCE}${GtagNameEnum.BALANCE_HISTORY} - ${controlName}`, getEventDimension(GtagCategoryEnum.FINANCE, value));
    };

    const onInputBlur = () => {
        //    TODO
    };


    return (
        <div className={`${className} ${dateFilterStyle.dateFilter}`}>
            <DatePickerInputComponent inputName={FinanceFilterEnum.CREATED_AT__GT}
                                      onInputChange={onInputChange(FinanceFilterEnum.CREATED_AT__GT)}
                                      onDateChange={onInputChange(FinanceFilterEnum.CREATED_AT__GT)}
                                      inputValue={filterValue[FinanceFilterEnum.CREATED_AT__GT] || null}
                                      inputPlaceholder={t('From')}
                                      onInputBlur={onInputBlur}
                                      errorMessage={undefined}/>
            —
            <DatePickerInputComponent inputName={FinanceFilterEnum.CREATED_AT__LT}
                                      onInputChange={onInputChange(FinanceFilterEnum.CREATED_AT__LT)}
                                      onDateChange={onInputChange(FinanceFilterEnum.CREATED_AT__LT)}
                                      inputValue={filterValue[FinanceFilterEnum.CREATED_AT__LT] || null}
                                      inputPlaceholder={t('Until')}
                                      onInputBlur={onInputBlur}
                                      errorMessage={undefined}/>
        </div>
    );
};
