import {RouteObject} from 'react-router-dom';
import {PageNotFound} from 'core/PageNotFound/PageNotFound';
import {WebsitesRoutesPathsEnum} from 'core/Routes/typings/enums/WebsitesRoutesPaths.enum';
import {WebsiteCreate, WebsiteList} from '../pages';


export const websitesRoutes: RouteObject[] = [{
    element: <WebsiteList/>,
    index: true,
}, {
    element: <WebsiteCreate/>,
    path: `${WebsitesRoutesPathsEnum.CREATE}`,
}, {
    element: <WebsiteCreate/>,
    path: `${WebsitesRoutesPathsEnum.EDIT}`,
},
    {element: <PageNotFound/>, path: '/*'},
];
