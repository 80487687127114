import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {AttentionModalComponentInterface} from './AttentionModalComponent.interface';


export const AttentionModal: FC<AttentionModalComponentInterface> = ({handleSaveClick, setShowModal, showModal, to}) => {
    const {t} = useTranslation();


    return (
        <Modal title={t('Attention! Unsaved data will be lost')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <p>{t('You can save the current progress and complete the form later. Draft offers are stored in the \'Draft\' section in the \'My offers\' tab.')}</p>
            <div className="controls controls_align_right">
                <Button to={to}
                        theme={'stroke'}
                        textContent={t('Don\'t save')}/>
                <Button onClick={handleSaveClick}
                        textContent={t('Save and leave')}/>
            </div>
        </Modal>
    );
};
