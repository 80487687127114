import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Step2Ill from 'assets/images/onboarding/step2.png';
import Step2x2Ill from 'assets/images/onboarding/step2@2x.png';
import {Button} from 'components';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, UserInterface, UserRoleEnum} from 'typings';
import {setCurrentStep} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {SiteTransferMethodEnum, StepEnum} from '../../typings';
import {ProgressComponent} from '../progress/Progress.component';


export const Step2Component: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {method, role} = useSelector((state: RootState) => state.onboardingReducer);

    const step2Text = {
        [UserRoleEnum.IMPLEMENTER]: {
            text: t('Site info will attract advertisers’ attention. Moreover, it will be clear to your potential partners who they are talking to.'),
        },
        [UserRoleEnum.PAYER]: {
            text: t('Site info will attract publishers’ attention. Moreover, it will be clear to your potential partners who they are talking to.'),
        },
    };


    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };


    return (
        <>
            <h1 className={`textHeaderMedium_05 ${onboardingStyle.title}`}>{t('Nice to meet you')}</h1>
            <ProgressComponent step={2}/>
            {/*TODO: width height*/}
            <img className={onboardingStyle.ill} srcSet={`${Step2Ill}, ${Step2x2Ill} 2x`} src={Step2Ill} alt=""/>

            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{t('Why do you need it?')}</h2>
            <p className={onboardingStyle.text}>{step2Text[role || UserRoleEnum.IMPLEMENTER].text}</p>

            <div className={`controls controls_align_justify ${onboardingStyle.controls}`}>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="back button"
                        eventName={`${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - step 2`}
                        onClick={handleToggleStepClick(method === SiteTransferMethodEnum.AUTO ? StepEnum.STEP_1 : StepEnum.STEP_1_FORM)}
                        textContent={t('Back')}
                        theme={'stroke'}/>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="next button"
                        eventName={`${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - step 2`}
                        onClick={handleToggleStepClick(StepEnum.STEP_3)}
                        textContent={t('Next')}/>
            </div>

        </>
    );
};
