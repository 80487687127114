import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {IDropdownOption, Pagination, TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, EmptyComponent, FilterSearchSortComponent, LoaderWithOverlay, PageTitleComponent, TabsComponent} from 'components';
import {TableTexts} from 'consts';
import {DealsRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useCommonSelector, useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum, TabInterface, UserInterface} from 'typings';
import {getEventDimension} from 'utils';
import {DealCardComponent, DealFilterComponent} from '../../componets';
import {DealFilterEnum, DealRoleEnum, DealStateEnum} from '../../typings';
import {getDealList, resetDealToInitialState} from '../../deals.reducer';


const DealList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isLoading, isError, dealList, dealCount} = useSelector((state: RootState) => state.dealsReducer);
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const isNarrowScreen = useMedia();
    const [searchParams, setSearchParams] = useSearchParams();
    const [firstInit, setFirstInit] = useState<boolean>(true);


    const tabs: Omit<TabInterface, 'linkValue'>[] = [{
        eventCategory: GtagCategoryEnum.DEALS,
        eventLabel: 'all deals',
        eventName: `${GtagNameEnum.MY_DEALS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('All deals'),
        paramValue: DealStateEnum.ALL,
    }, {
        eventCategory: GtagCategoryEnum.DEALS,
        eventLabel: 'deals in discussion',
        eventName: `${GtagNameEnum.MY_DEALS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Deals in discussion'),
        paramValue: DealStateEnum.ACTIVE,
    }, {
        eventCategory: GtagCategoryEnum.DEALS,
        eventLabel: 'deals in progress',
        eventName: `${GtagNameEnum.MY_DEALS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Deals in progress'),
        paramValue: DealStateEnum.IN_PROGRESS,
    }, {
        eventCategory: GtagCategoryEnum.DEALS,
        eventLabel: 'closed deals',
        eventName: `${GtagNameEnum.MY_DEALS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Closed deals'),
        paramValue: DealStateEnum.COMPLETED,
    }];

    const orderingOptions: IDropdownOption[] = [
        {value: 'approved_at', text: t('Deal made (oldest first)')},
        {value: '-approved_at', text: t('Deal made (newest first)')},
        {value: 'closed_at', text: t('Deal closed (oldest first)')},
        {value: '-closed_at', text: t('Deal closed (newest first)')},
        {value: 'price', text: t('Price (lowest first)')},
        {value: '-price', text: t('Price (highest first)')},
        {value: 'last_message_date', text: t('Last message (oldest first)')},
        {value: '-last_message_date', text: t('Last message (newest first)')},
    ];


    const handlePaginationChange = (params: any) => {
        const limit = params.itemsPerPage || searchParams.get(PaginationEnum.LIMIT) || 20;
        const offset = `${limit * (params.pageNumber - 1)}`;
        if (searchParams.get(PaginationEnum.OFFSET) !== offset || Number(searchParams.get(PaginationEnum.OFFSET)) !== params.itemsPerPage) {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(PaginationEnum.LIMIT, limit);
            newSearchParams.set(PaginationEnum.OFFSET, offset);
            setSearchParams(newSearchParams);
        }
    };

    const handleSearchAndOrderingChange = (controlName: string) => (value: string | TDropdownOptionValue | null) => {
        if (firstInit) {
            setFirstInit(false);
            return;
        }
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);

        const searchOrderingMap = {
            [DealFilterEnum.SEARCH]: `${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)}${GtagNameEnum.SEARCH}`,
            [DealFilterEnum.ORDERING]: `${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)}${GtagNameEnum.SORT_BY}`,
        };
        gtag('event', searchOrderingMap[controlName], getEventDimension(GtagCategoryEnum.DEALS, value));
    };


    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        window.scrollTo({top: 0});
        dispatch(getDealList({queryParams: newSearchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => () => {
        dispatch(resetDealToInitialState());
    }, []);


    return (
        <>
            <main className="page page_aside-left">
                <PageTitleComponent text={t('My deals')}/>

                {!isNarrowScreen && <aside className="page__aside page__aside_sticky blank">
                    <DealFilterComponent priceInclFee={searchParams.get(DealFilterEnum.MY_ROLE) === DealRoleEnum.PAYER}/>
                </aside>}

                <section className="page__content page__blank">
                    <div className="blank topBlock">
                        {(user.is_allowed_to_be_payer && user.is_allowed_to_be_implementer) && <div className="btnTabs">
                            <Button eventCategory={GtagCategoryEnum.DEALS}
                                    eventLabel="publisher deals"
                                    eventName={`${GtagNameEnum.MY_DEALS}${GtagNameEnum.TYPE_DEALS}`}
                                    theme={searchParams.get(DealFilterEnum.MY_ROLE) === DealRoleEnum.IMPLEMENTER ? 'fill' : 'stroke'}
                                    textContent={t('Publisher deals')}
                                    to={`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL_LIST}?${DealFilterEnum.MY_ROLE}=${DealRoleEnum.IMPLEMENTER}`}/>
                            <Button eventCategory={GtagCategoryEnum.DEALS}
                                    eventLabel="advertiser deals"
                                    eventName={`${GtagNameEnum.MY_DEALS}${GtagNameEnum.TYPE_DEALS}`}
                                    theme={searchParams.get(DealFilterEnum.MY_ROLE) === DealRoleEnum.PAYER ? 'fill' : 'stroke'}
                                    textContent={t('Advertiser deals')}
                                    to={`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL_LIST}?${DealFilterEnum.MY_ROLE}=${DealRoleEnum.PAYER}`}/>
                        </div>}
                        <span className="topBlockTotal">{t('Deals found')}: {dealCount}</span>
                    </div>
                    <div className="blank page__blank">
                        <div className="tabsBlock">
                            <TabsComponent className="tabsBlockTabs"
                                           defaultParamValue={DealStateEnum.ALL}
                                           link={`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL_LIST}?${DealFilterEnum.MY_ROLE}=${searchParams.get(DealFilterEnum.MY_ROLE)}`}
                                           paramName={DealFilterEnum.STATE}
                                           tabs={tabs}
                                           withParams/>
                        </div>
                        <FilterSearchSortComponent filter={{component: <DealFilterComponent priceInclFee={searchParams.get(DealFilterEnum.MY_ROLE) === DealRoleEnum.PAYER}/>}}
                                                   search={{
                                                       onChange: handleSearchAndOrderingChange(DealFilterEnum.SEARCH),
                                                       placeholder: t('Search'),
                                                       value: searchParams.get(DealFilterEnum.SEARCH) || '',
                                                   }}
                                                   sort={{
                                                       onChange: handleSearchAndOrderingChange(DealFilterEnum.ORDERING),
                                                       options: orderingOptions,
                                                       placeholder: t('Sort by'),
                                                       value: searchParams.get(DealFilterEnum.ORDERING) || '',
                                                   }}/>
                    </div>
                    <div className="loader__container">
                        {!isLoading && !dealList.length && !isError && <EmptyComponent title={TableTexts.noRowsPlaceholder}/>}
                        {isError && <p><b>{TableTexts.responseValidationFailedTitle}</b><br/>{TableTexts.responseValidationFailedDescription}</p>}

                        {dealList.map(deal => <DealCardComponent key={deal.id} deal={deal} searchParams={searchParams}/>)}

                        {isLoading && <LoaderWithOverlay/>}
                    </div>
                    {(Number(dealCount) > 20) && <Pagination itemsCount={dealCount || 0}
                                                             itemsPerPage={Number(searchParams.get(PaginationEnum.LIMIT)) || 20}
                                                             pageNumber={Number(searchParams.get(PaginationEnum.OFFSET) || 0) / Number(searchParams.get(PaginationEnum.LIMIT) || 20) + 1}
                                                             onChange={handlePaginationChange}
                                                             hidePerPageDropdown={isNarrowScreen}
                                                             hideElementsCounter={isNarrowScreen}
                                                             perPageDropdownOptions={[20, 50, 100]}
                                                             texts={TableTexts}/>}
                </section>

            </main>
        </>
    );
};

export {DealList};
export default DealList;
