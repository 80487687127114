import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useSearchParams} from 'react-router-dom';
import {IDropdownOption, SmartTable, TDropdownOptionValue, TTableColumns, TTableRows} from '@design-system/ui-kit';
import acceptedIcon from 'assets/images/statuses/accepted.svg';
import holdIcon from 'assets/images/statuses/hold.svg';
import {FilterSearchSortComponent, LinkComponent, LinkTabsComponent} from 'components';
import {TableTexts} from 'consts';
import {DealsRoutesPathEnum, FinanceRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum, TabInterface} from 'typings';
import {getEventDimension} from 'utils';
import {BalanceFilterComponent, FinanceDateFilterComponent} from '../../components';
import {FinanceOperationTypeTextConst, FinanceStatusTextConst} from '../../constants';
import {BalanceHistoryInterface, BalanceOperationTypeEnum, BalanceStatusEnum, FinanceFilterEnum} from '../../typings';
import {getBalanceTransactionList, resetFinanceToInitialState} from '../../finance.reducer';
import balanceHistoryStyle from './BalanceHistory.module.scss';


export const BalanceHistory: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {balanceHistoryCount, balanceHistoryList, isLoading, isError} = useSelector((state: RootState) => state.financeReducer);
    const isNarrowScreen = useMedia();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableBalanceTransaction, setBalanceTransaction] = useState<TTableRows>([]);


    const tabs: Omit<TabInterface, 'paramValue'>[] = [{
        eventCategory: GtagCategoryEnum.FINANCE,
        eventName: `${GtagNameEnum.FINANCE} - withdraw funds button`,
        linkText: t('Balance history'),
        linkValue: `${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.BALANCE_HISTORY}`,
    }, {
        eventCategory: GtagCategoryEnum.FINANCE,
        eventName: `${GtagNameEnum.FINANCE} - refill button`,
        linkText: t('Payment requests'),
        linkValue: `${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.PAYMENT_REQUESTS}`,
    }];

    const orderingOptions: IDropdownOption[] = [
        {value: 'created_at', text: t('Date (oldest first)')},
        {value: '-created_at', text: t('Date (newest first)')},
        {value: 'amount', text: t('Amount (ascending)')},
        {value: '-amount', text: t('Amount (descending)')},
    ];

    const tableColumn: TTableColumns = [
        {key: 'titleCell', value: t('Deal')},
        {key: 'dateCell', value: t('Date')},
        {key: 'statusCell', value: t('Status')},
        {key: 'operationTypeCell', value: t('Operation type')},
        {key: 'amountCell', value: t('Amount')},
    ];

    const statusItem = {
        [BalanceStatusEnum.COMPLETED]: <>
            <img src={acceptedIcon} alt=""/>
            {FinanceStatusTextConst[BalanceStatusEnum.COMPLETED]}
        </>,
        [BalanceStatusEnum.HOLD]: <>
            <img src={holdIcon} alt=""/>
            {FinanceStatusTextConst[BalanceStatusEnum.HOLD]}
        </>,
    };


    const handlePaginationChange = (params: any) => {
        if (!Object.entries(params).every(([key, value]) => Number(searchParams.get(key)) === value)) {
            const newSearchParams = new URLSearchParams(searchParams);
            Object.entries(params).forEach(([key, value]) => {
                newSearchParams.set(key, value as string);
            });
            setSearchParams(newSearchParams);
        }
    };

    const handleSearchAndOrderingChange = (controlName: string) => (value: string | TDropdownOptionValue | null) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);

        gtag('event', `${GtagNameEnum.FINANCE}${GtagNameEnum.BALANCE_HISTORY}${GtagNameEnum.SORT_BY}`, getEventDimension(GtagCategoryEnum.FINANCE, value));
    };


    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        dispatch(getBalanceTransactionList({queryParams: newSearchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => () => {
        dispatch(resetFinanceToInitialState());
    }, []);

    useEffect(() => {
        if (!balanceHistoryList) return;
        const tableData = balanceHistoryList.map((item: BalanceHistoryInterface) => ({
            ...item,
            titleCell: (<LinkComponent to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}`, {dealId: `${item.deal.id}`})} textContent={item.deal.name}/>),
            dateCell: (<span className="table__secondary">{new Date(item.created_at).toLocaleDateString()}</span>),
            amountCell: (<span className={item.status === BalanceStatusEnum.HOLD
                ? balanceHistoryStyle.balanceHistory__amountCell_frozen
                : item.operation_type === BalanceOperationTypeEnum.ADD
                    ? balanceHistoryStyle.balanceHistory__amountCell_refill
                    : balanceHistoryStyle.balanceHistory__amountCell_payment}>{Number(item.amount).toLocaleString()} {item.currency}</span>),
            statusCell: (<span className="table__secondary table__statusCell">{statusItem[item.status]}</span>),
            operationTypeCell: (<span className="table__secondary">{FinanceOperationTypeTextConst[item.operation_type]}</span>),
        })) as TTableRows;
        setBalanceTransaction(tableData);
    }, [balanceHistoryList, t]);


    return (
        <>
            {!isNarrowScreen && <aside className="page__aside page__aside_sticky blank"><BalanceFilterComponent/></aside>}

            <section className="blank page__content page__blank">
                <LinkTabsComponent tabs={tabs}/>
                <FilterSearchSortComponent AdditionalFilterComponent={FinanceDateFilterComponent}
                                           filter={{component: <BalanceFilterComponent/>}}
                                           sort={{
                                               onChange: handleSearchAndOrderingChange(FinanceFilterEnum.ORDERING),
                                               options: orderingOptions,
                                               placeholder: t('Sort by'),
                                               value: searchParams.get(FinanceFilterEnum.ORDERING) || '',
                                           }}/>
                <SmartTable columns={tableColumn}
                            texts={TableTexts}
                            rows={tableBalanceTransaction}
                            showPendingState={isLoading}
                            total={balanceHistoryCount}
                            limit={Number(searchParams.get(PaginationEnum.LIMIT) || 20)}
                            offset={Number(searchParams.get(PaginationEnum.OFFSET))}
                            onPageChange={handlePaginationChange}
                            showErrorState={isError}
                            isMobile={isNarrowScreen}/>
            </section>
        </>
    );
};
