import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DealInterface, OfferInterface, PlacementType, RequestInterface, WebsiteInterface} from 'typings';
import {CatalogStoreInterface} from './typings';


const defaultCatalogState: CatalogStoreInterface = {
    dealList: [],
    isError: false,
    isLoading: false,
    placementList: [],
    websiteList: [],
};

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: defaultCatalogState,
    reducers: {
        resetCatalogToInitialState: state => defaultCatalogState,
        hideCatalogError: state => ({...state, isError: false}),
        hideCatalogLoader: state => ({...state, isLoading: false}),
        showCatalogError: state => ({...state, isError: true}),
        showCatalogLoader: state => ({...state, isLoading: true}),
        setCatalogOfferList: (state, action: PayloadAction<OfferInterface<WebsiteInterface>[]>) => ({...state, placementList: action.payload}),
        setCatalogOfferListCount: (state, action: PayloadAction<number>) => ({...state, placementCount: action.payload}),
        setCatalogOffer: (state, action: PayloadAction<PlacementType>) => ({...state, placement: action.payload}),
        setCatalogRequestList: (state, action: PayloadAction<RequestInterface<WebsiteInterface>[]>) => ({...state, placementList: action.payload}),
        setCatalogRequestListCount: (state, action: PayloadAction<number>) => ({...state, placementCount: action.payload}),
        setCatalogRequest: (state, action: PayloadAction<PlacementType>) => ({...state, placement: action.payload}),
        setCatalogOfferDealList: (state, action: PayloadAction<DealInterface[]>) => ({...state, dealList: action.payload}),
        setCatalogRequestDealList: (state, action: PayloadAction<DealInterface[]>) => ({...state, dealList: action.payload}),
        setCatalogPlacementWebsiteList: (state, action: PayloadAction<WebsiteInterface[]>) => ({...state, websiteList: action.payload}),

        getCatalogOfferList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getCatalogOffer(state, action: PayloadAction<{ placementId: string }>) {},
        getCatalogRequestList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getCatalogRequest(state, action: PayloadAction<{ placementId: string }>) {},
        getCatalogOfferDealList(state, action: PayloadAction<{ controller: AbortController; placementId: string; queryParams: URLSearchParams; }>) {},
        getCatalogRequestDealList(state, action: PayloadAction<{ controller: AbortController; placementId: string; queryParams: URLSearchParams; }>) {},
        getOffer(state, action: PayloadAction<{ placementId: string }>) {},
        getRequest(state, action: PayloadAction<{ placementId: string }>) {},
        createCatalogOfferDeal(state, action: PayloadAction<{ placementId: number; isShowSuggestionForm?: boolean; }>) {},
        createCatalogRequestDeal(state, action: PayloadAction<{ placementId: number; isShowSuggestionForm?: boolean; }>) {},
        getCatalogPlacementWebsiteList(state, action: PayloadAction<{ partnerId: string }>) {},
        changeVisibilityOfferDeal(state, action: PayloadAction<{ controller: AbortController; dealId: number; isHidden: boolean; placementId: number; queryParams: URLSearchParams; }>) {},
        changeVisibilityRequestDeal(state, action: PayloadAction<{ controller: AbortController; dealId: number; isHidden: boolean; placementId: number; queryParams: URLSearchParams; }>) {},
    },
});

export const {
    changeVisibilityOfferDeal,
    changeVisibilityRequestDeal,
    createCatalogOfferDeal,
    createCatalogRequestDeal,
    getCatalogOffer,
    getCatalogOfferDealList,
    getCatalogOfferList,
    getCatalogPlacementWebsiteList,
    getCatalogRequest,
    getCatalogRequestDealList,
    getCatalogRequestList,
    getOffer,
    getRequest,
    hideCatalogError,
    hideCatalogLoader,
    resetCatalogToInitialState,
    setCatalogOffer,
    setCatalogOfferDealList,
    setCatalogOfferList,
    setCatalogOfferListCount,
    setCatalogPlacementWebsiteList,
    setCatalogRequest,
    setCatalogRequestDealList,
    setCatalogRequestList,
    setCatalogRequestListCount,
    showCatalogError,
    showCatalogLoader,
} = catalogSlice.actions;

export const catalogReducer = catalogSlice.reducer;

