import {store} from 'core';
import {UserInterface} from 'typings';
import {clientid} from './clientId';
import {getTimeStamp} from './getTimeStamp';
import {getSessionId} from './getSessionId';
import {uuidv4} from './uuidv4';
import {webmasterOrAdvertiser} from './webmasterOrAdvertiser';


export const getEventDimension = (event_category, event_label?) => {
    const state = store.getState();
    const user: UserInterface = state.commonReducer.user;

    return {
        event_category,
        event_label,
        dimension1: clientid(),
        dimension4: user?.id,
        dimension5: getTimeStamp(),
        dimension6: getSessionId(),
        dimension8: webmasterOrAdvertiser(user),
        dimension15: user?.id,
        dimension9: uuidv4(),
    };
};
