import React, {FC} from 'react';
import radioStyle from './RadioGroup.module.scss';
import {RadioGroupComponentInterface} from './RadioGroupComponent.interface';


export const RadioGroupComponent: FC<RadioGroupComponentInterface> = ({inputData, name, onChange, value}) => {
    return (
        <div className={radioStyle.radioGroup}>
            {inputData.map(input => <label key={input.inputValue} className={`${radioStyle.radioItem} ${value === input.inputValue ? radioStyle.radioItem_checked : radioStyle.radioItem_unchecked}`}>
                <input className={radioStyle.radioInput} type="radio" onChange={onChange(input.inputValue)} name={name} checked={value === input.inputValue}/>
                <svg className={radioStyle.radioSvg} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#b4aeb9"/>
                    <circle cx="9" cy="9" r="7" fill="#fff"/>
                    {(value === input.inputValue) && <circle cx="9" cy="9" r="5" fill="#9b8af4"/>}
                </svg>
                <span className={radioStyle.radioLabel}>{input.label}</span>
                <span className={`textBody_03 ${radioStyle.radioNote}`}>{input.note}</span>
            </label>)}
        </div>
    );
};
