import React, {FC} from 'react';
import itemStyle from './UserBalanceItem.module.scss';


export const UserBalanceItemComponent: FC<{ amount: string; className: string; currency: string; isMain?: boolean; title: string; }> = ({amount, className, currency, isMain, title}) => {
    return (
        <div className={`${className} ${itemStyle.userBalanceItem} ${isMain ? itemStyle.userBalanceItem_main : ''}`}>
            <div className={itemStyle.userBalanceItem__title}>{title}</div>
            <div>
                <span className={`textHeader_06 ${itemStyle.userBalanceItem__amount}`}>{amount}</span>
                <span className={`textHeaderMedium_06 ${itemStyle.userBalanceItem__currency}`}>{currency}</span>
            </div>
        </div>
    );
};
