import axios from 'axios';
import {END, eventChannel} from 'redux-saga';
import {getApiRoute} from 'utils';


export function postFileRequest(payload) {
    return eventChannel(emitter => {
        const config = {
            onUploadProgress: progressEvent => emitter(Math.floor(progressEvent.loaded * 100 / progressEvent.total)),
        };
        axios
            .post(
                getApiRoute('upload_file/'),
                payload,
                config,
            )
            .then(({data}) => {
                emitter(data);
                emitter(END);
            })
            .catch(error => {
                emitter(error);
            });
        return () => {
        };
    });
}
