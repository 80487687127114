import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserRoleEnum, WebsiteFormControlsEnum, WebsiteFormErrorsType, WebsiteInterface} from 'typings';
import {OnboardingStoreInterface, SiteTransferMethodEnum, StepEnum} from './typings';


const defaultOnboardingState: OnboardingStoreInterface = {
    formErrors: {},
    isError: false,
    isLoading: false,
    siteIdsToDelete: [],
    siteList: [],
};


export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: defaultOnboardingState,
    reducers: {
        resetOnboardingToInitialState: () => defaultOnboardingState,
        showOnboardingError: state => ({...state, isError: true}),
        hideOnboardingError: state => ({...state, isError: false}),
        showOnboardingLoader: state => ({...state, isLoading: true}),
        hideOnboardingLoader: state => ({...state, isLoading: false}),
        setCreatedWebsiteId: (state, action: PayloadAction<number>) => ({...state, createdWebsiteId: action.payload}),
        setCurrentStep: (state, action: PayloadAction<StepEnum>) => ({...state, currentStep: action.payload}),
        setMethod: (state, action: PayloadAction<SiteTransferMethodEnum | undefined>) => ({...state, method: action.payload}),
        setRole: (state, action: PayloadAction<UserRoleEnum>) => ({...state, role: action.payload}),
        setSiteIdsToDelete: (state, action: PayloadAction<number>) => ({...state, siteIdsToDelete: [...state.siteIdsToDelete, action.payload]}),
        setSiteList: (state, action: PayloadAction<WebsiteInterface[]>) => ({...state, siteList: action.payload}),
        getSiteList(state, action: PayloadAction<{ controller: AbortController }>) {},
        postSite(state, action: PayloadAction<{ data: any; nextStep: StepEnum; }>) {},
        clearOnboardingFormError: (state, action: PayloadAction<WebsiteFormControlsEnum | undefined>) => {
            const controlName: WebsiteFormControlsEnum | undefined = action.payload;
            const newFormError = controlName ? {...state.formErrors} : {};
            if (controlName) {
                delete newFormError?.[controlName];
            }
            return {...state, formErrors: newFormError};
        },
        setOnboardingFormError: (state, action: PayloadAction<WebsiteFormErrorsType>) => ({...state, formErrors: action.payload}),
        finishOnboarding(state, action: PayloadAction<{ createdWebsiteId?: number; isDeleteAllWebsites: boolean; nextPage: string; siteIdsToDelete: number[]; }>) {},
        setCloseOnboarding: state => ({...state, closeOnboarding: true}),
    },
});

export const {
    clearOnboardingFormError,
    finishOnboarding,
    getSiteList,
    hideOnboardingError,
    hideOnboardingLoader,
    postSite,
    resetOnboardingToInitialState,
    setCloseOnboarding,
    setCreatedWebsiteId,
    setCurrentStep,
    setMethod,
    setOnboardingFormError,
    setRole,
    setSiteIdsToDelete,
    setSiteList,
    showOnboardingError,
    showOnboardingLoader,
} = onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;
