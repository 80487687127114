import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Step3Ill from 'assets/images/onboarding/step3.png';
import Step3x2Ill from 'assets/images/onboarding/step3@2x.png';
import {Button, LinkComponent, LoaderWithOverlay} from 'components';
import {CatalogRoutesPathEnum, MainRoutesEnum, PlacementRoutesPathEnum, RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, UserInterface, UserRoleEnum} from 'typings';
import {finishOnboarding, setCurrentStep} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {SiteTransferMethodEnum, StepEnum} from '../../typings';
import {ProgressComponent} from '../progress/Progress.component';


export const Step3Component: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {createdWebsiteId, isLoading, method, role, siteIdsToDelete} = useSelector((state: RootState) => state.onboardingReducer);

    const step3Text = {
        [UserRoleEnum.IMPLEMENTER]: {
            title: t('Create an offer for your sites and discuss the terms'),
            text: t('After creating an offer, you will receive placement requests faster. A chat will help you hammer out the deal with the advertiser.'),
        },
        [UserRoleEnum.PAYER]: {
            title: t('You can now discuss the deal terms with publishers'),
            text: t('All discussions are done in the chat. You can request additional information, respond to an offer, or create your own.'),
        },
    };


    const finishOnboaring = (nextPage) => () => {
        dispatch(finishOnboarding({createdWebsiteId, isDeleteAllWebsites: method === SiteTransferMethodEnum.MANUAL, nextPage, siteIdsToDelete}));
    };

    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };


    return (
        <>
            <h1 className={`textHeaderMedium_05 ${onboardingStyle.title}`}>{t('Done. Here\'s a small tip for you')}</h1>
            <ProgressComponent step={3}/>

            <img className={onboardingStyle.ill} srcSet={`${Step3Ill}, ${Step3x2Ill} 2x`} src={Step3Ill} alt=""/>

            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{step3Text[role || UserRoleEnum.IMPLEMENTER].title}</h2>
            <p className={onboardingStyle.text}>{step3Text[role || UserRoleEnum.IMPLEMENTER].text}</p>
            {role === UserRoleEnum.IMPLEMENTER && <LinkComponent eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer ? '' : ' webmaster'}`}
                                                                 eventLabel="create first offer button"
                                                                 eventName="webmaster - step 3"
                                                                 onClick={finishOnboaring(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_CREATE}`)}
                                                                 textContent={t('Create your first offer')}/>}

            <div className={`controls controls_align_justify ${onboardingStyle.controls}`}>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="back button"
                        eventName={`${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - step 3`}
                        onClick={handleToggleStepClick(StepEnum.STEP_2)}
                        textContent={t('Back')}
                        theme={'stroke'}/>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME}${user.is_allowed_to_be_implementer !== user.is_allowed_to_be_payer ? role === UserRoleEnum.PAYER ? ' advertiser' : ' webmaster' : ''}`}
                        eventLabel="catalog button"
                        eventName={`${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'} - step 3`}
                        onClick={finishOnboaring(`${MainRoutesEnum.CATALOG}${role === UserRoleEnum.IMPLEMENTER ? CatalogRoutesPathEnum.CATALOG_REQUEST_LIST : CatalogRoutesPathEnum.CATALOG_OFFER_LIST}?status=active`)}
                        textContent={t('Go to Store')}/>
            </div>

            {isLoading && <LoaderWithOverlay/>}
        </>
    );
};
