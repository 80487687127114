import {RequestPayloadType} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';


export const getDealListRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getDealCommentsRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/comments/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const postDealCommentRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/comments/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const approveDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/approve/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const revokeApproveDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/revoke_approval/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const markAsReadyDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/mark_as_ready/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const declineDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/decline/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};
