import {i18n} from 'i18n';
import {BalanceStatusEnum, PaymentStatusEnum} from '../typings';


export const FinanceStatusTextConst = {
    [BalanceStatusEnum.COMPLETED]: i18n.t('Closed'),
    [BalanceStatusEnum.HOLD]: i18n.t('Hold'),
    [PaymentStatusEnum.APPROVED]: i18n.t('Approved'),
    [PaymentStatusEnum.NEW]: i18n.t('New'),
    [PaymentStatusEnum.REJECTED]: i18n.t('Rejected'),
};
