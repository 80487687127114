import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {catalogRoutes} from './constants';


const Catalog: FC = () => {
    return useRoutes(catalogRoutes);
};

export {Catalog};
export default Catalog;
