import {RequestPayloadType} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';


export const getBalanceTransactionRequestsRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('balance_transaction_requests/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const postBalanceTransactionRequestsRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('balance_transaction_requests/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const postRegisterWithdrawalRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('balance_transaction_requests/register_withdrawal/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getBalanceTransactionsRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('balance_transactions/'), payload);
    return await sendRequest<any>(sendRequestParams);
};
