import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useParams} from 'react-router-dom';
import {LinkComponent, LinkTabsComponent, LoaderWithOverlay, PageTitleComponent, PartnerNameWebsiteTypeComponent} from 'components';
import {CatalogRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, TabInterface} from 'typings';
import {AsideShortInfoComponent, PlacementRouter} from '../../componets';
import {getCatalogRequest, resetCatalogToInitialState} from '../../catalog.reducer';
import placementPageStyle from '../../styles/PlacementPage.module.scss';


const RequestPage: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {placementId} = useParams();
    const isMobile = useMedia();
    const {isLoading, placement} = useSelector((state: RootState) => state.catalogReducer);
    const [attachmentsLink, setAttachmentsLink] = useState<string>('');
    const [tabs, setTabs] = useState<Omit<TabInterface, 'paramValue'>[]>();


    useEffect(() => {
        if (!placementId) return;
        dispatch(getCatalogRequest({placementId}));
    }, [dispatch, placementId]);

    useEffect(() => () => {
        dispatch(resetCatalogToInitialState());
    }, [dispatch]);

    useEffect(() => {
        if (!placement) return;
        setTabs([
            {
                linkText: t('Description'),
                linkValue: generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId}),
            },
            {
                linkText: t('About Advertiser'),
                linkValue: generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST}${CatalogRoutesPathEnum.CATALOG_USER_INFO}`, {placementId}),
            },
        ]);
        if (placement.uploaded_attachments.length) {
            setAttachmentsLink(generatePath(CatalogRoutesPathEnum.CATALOG_REQUEST_DOWNLOAD_ALL, {placementId}));
        }
    }, [placement]);


    return (
        <main className="page page_aside-left">
            <PageTitleComponent text={t('Advertiser offer')}/>

            {!isMobile && placement && <div className="page__aside page__aside_sticky page__blank">
                <div className="blank">
                    <AsideShortInfoComponent isRequest placement={placement}/>
                </div>
                {!!placement.uploaded_attachments.length && <div className="blank">
                    <p className={placementPageStyle.placementPageAsideBottomBlockText}>{t('Download ad creatives')}</p>
                    <LinkComponent className="word-break"
                                   download
                                   eventCategory={GtagCategoryEnum.ADVERTISER_OFFER}
                                   eventLabel={`${placement.name} - ${placement.id}`}
                                   eventName={`${GtagNameEnum.ADVERTISER_OFFER}${GtagNameEnum.CLICK} download materials`}
                                   href={attachmentsLink}
                                   textContent={`${placement.name}_attachments.zip`}/>
                </div>}
            </div>}

            {placement && <>
                <div className="page__content page__blank">
                    <div className={`blank`}>
                        <h2 className={`textHeaderMedium_04 ${placementPageStyle.placementPageTitle}`}>{placement.name}</h2>
                        <PartnerNameWebsiteTypeComponent partner={placement.created_by}
                                                         to={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST}${CatalogRoutesPathEnum.CATALOG_USER_INFO}`, {placementId})}
                                                         website={placement.website}/>
                        {!!tabs && <LinkTabsComponent tabs={tabs}/>}
                        <PlacementRouter {...placement.uploaded_attachments?.length && {attachmentsLink: generatePath(CatalogRoutesPathEnum.CATALOG_REQUEST_DOWNLOAD_ALL, {placementId: `${placement.id}`})}}
                                         isRequest
                                         placement={placement}/>
                    </div>
                </div>
            </>}
            {isLoading && <LoaderWithOverlay/>}
        </main>
    );
};

export {RequestPage};
export default RequestPage;
