import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, Link, useSearchParams} from 'react-router-dom';
import {SmartTable, TTableColumns, TTableRows} from '@design-system/ui-kit';
import {ReactComponent as AddIcon} from 'assets/images/icons/add.svg';
import {ReactComponent as DeleteIcon} from 'assets/images/icons/delete.svg';
import {ReactComponent as EditIcon} from 'assets/images/icons/edit.svg';
import {Button, LinkComponent, PageTitleComponent} from 'components';
import {TableTexts} from 'consts';
import {useMedia} from 'hooks';
import {MainRoutesEnum, RootState, WebsitesRoutesPathsEnum} from 'core';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum, WebsiteInterface} from 'typings';
import {getEventDimension, gtagSendEvent} from 'utils';
import {DeleteWebsiteModal} from '../../components';
import {deleteWebsite, getWebsiteList, resetWebsitesToInitialState} from '../../websites.reducer';
import './WebsiteList.scss';


export const WebsiteList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {websites, isLoading, isError, websitesCount} = useSelector((state: RootState) => state.websitesReducer);
    const isNarrowScreen = useMedia();
    const [showModal, setShowModal] = useState(false);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState<null | number>(null);
    const [selectedWebsiteActivePlacementCount, setWebsiteActivePlacementCount] = useState<null | number>(null);
    const [selectedWebsiteActivePlacementRequestsCount, setWebsiteActivePlacementRequestsCount] = useState<null | number>(null);

    const tableColumn: TTableColumns = [
        {key: 'nameCell', value: 'Name'},
        {key: 'linkCell', value: 'Link'},
        {key: 'statusCell', value: 'Status'},
        {key: 'actionCell', value: 'Action'},
    ];


    const handleConfirmClick = () => {
        dispatch(deleteWebsite({websiteId: `${selectedWebsiteId}`, queryParams: searchParams}));
        setSelectedWebsiteId(null);
        setShowModal(false);
    };
    const handleCancelClick = () => {
        setSelectedWebsiteId(null);
        setShowModal(false);
    };

    const handleDeleteWebsiteClick = (id: number, activePlacementsCount: number, activePlacementRequestsCount: number, eventName, eventCategory, eventLabel?) => {
        setSelectedWebsiteId(id);
        setWebsiteActivePlacementCount(activePlacementsCount);
        setWebsiteActivePlacementRequestsCount(activePlacementRequestsCount);
        setShowModal(true);
        gtag('event', eventName, getEventDimension(eventCategory, eventLabel));
    };

    let [searchParams, setSearchParams] = useSearchParams();

    const handlePaginationChange = (params: any) => {
        if (!Object.entries(params).every(([key, value]) => Number(searchParams.get(key)) === value)) {
            setSearchParams(params);
        }
    };


    useEffect(() => {
        const controller = new AbortController();
        if (!searchParams.get(PaginationEnum.OFFSET)) {
            searchParams.set(PaginationEnum.LIMIT, '20');
            searchParams.set(PaginationEnum.OFFSET, '0');
        }
        dispatch(getWebsiteList({queryParams: searchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => () => {
        dispatch(resetWebsitesToInitialState());
    }, [dispatch]);


    const [tableWebsites, setTableWebsites] = useState<TTableRows>([]);

    useEffect(() => {
        const tableData = websites.map((item: WebsiteInterface) => ({
            ...item,
            nameCell: (<span className="table__main">{item.name}</span>),
            linkCell: (<LinkComponent href={item.url} title={item.url} target="_blank" rel="noreferrer" className="table__link" textContent={item.url}/>),
            statusCell: (<span className={item.is_active ? 'table__status table__status_active' : 'table__status table__status_inactive'}>{item.is_active ? 'Active' : 'Inactive'}</span>),
            actionCell: (<div className="table__actions">
                <Link className="table__action"
                      onClick={gtagSendEvent(`${GtagNameEnum.MY_SITES}${GtagNameEnum.CLICK} edit`, GtagCategoryEnum.SITES)}
                      title="Edit"
                      to={generatePath(`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.EDIT}`, {websiteId: `${item.id}`})}><EditIcon/></Link>
                {item.is_active && <button className="table__action"
                                           onClick={() => handleDeleteWebsiteClick(item.id as number, item.active_placements_count, item.active_placement_requests_count, `${GtagNameEnum.MY_SITES}${GtagNameEnum.CLICK} delete`, GtagCategoryEnum.SITES)}
                                           title="Delete">
                    <DeleteIcon/>
                </button>}
            </div>),
        })) as TTableRows;
        setTableWebsites(tableData);
    }, [websites]);


    return (
        <>
            <main className="page page_one-column">
                <PageTitleComponent text="My sites"/>
                <div className="page__content">
                    <section className="blank">
                        <div className="controls controls_align_right websites__controls">
                            <Button eventCategory={GtagCategoryEnum.SITES}
                                    eventName={`${GtagNameEnum.MY_SITES}${GtagNameEnum.WEBMASTER_OFFERS} - add site button`}
                                    Icon={AddIcon}
                                    textContent={t('Add site')}
                                    to={`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.CREATE}`}/>
                        </div>
                        <SmartTable columns={tableColumn}
                                    texts={TableTexts}
                                    rows={tableWebsites}
                                    showPendingState={isLoading}
                                    total={websitesCount}
                                    limit={Number(searchParams.get(PaginationEnum.LIMIT))}
                                    offset={Number(searchParams.get(PaginationEnum.OFFSET))}
                                    onPageChange={handlePaginationChange}
                                    showErrorState={isError}
                                    isMobile={isNarrowScreen}/>
                    </section>
                </div>
            </main>
            <DeleteWebsiteModal showModal={showModal}
                                setShowModal={setShowModal}
                                handleCancelClick={handleCancelClick}
                                handleConfirmClick={handleConfirmClick}
                                selectedWebsiteId={selectedWebsiteId}
                                selectedWebsiteActivePlacementCount={selectedWebsiteActivePlacementCount}
                                selectedWebsiteActivePlacementRequestsCount={selectedWebsiteActivePlacementRequestsCount}/>
        </>
    );
};
