import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from '@design-system/ui-kit';
import {Button, LinkComponent} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {MainRoutesEnum, PlacementRoutesPathEnum} from 'core';
import {GtagCategoryEnum, GtagNameEnum} from 'typings';
import deleteWebsiteStyle from './DeleteWebsite.module.scss';
import {DeleteWebsiteModalComponentInterface} from './DeleteWebsiteModalComponent.interface';


export const DeleteWebsiteModal: FC<DeleteWebsiteModalComponentInterface> = (props) => {
    const {showModal, setShowModal, handleCancelClick, handleConfirmClick, selectedWebsiteId, selectedWebsiteActivePlacementCount, selectedWebsiteActivePlacementRequestsCount} = props;
    const {t} = useTranslation();


    return (
        <Modal title={t('Are you sure you want to delete this site?')}
               active={showModal}
               setActive={setShowModal}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            {(!!selectedWebsiteActivePlacementCount || !!selectedWebsiteActivePlacementRequestsCount) && <>
                <p>{t('Deleting this site will also unpublish')}:</p>
                <ul className={deleteWebsiteStyle.deleteWebsiteList}>
                    {!!selectedWebsiteActivePlacementCount && <li>
                        <LinkComponent to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_LIST}?website_id=${selectedWebsiteId}`}
                                       textContent={`${t('Active publisher offers')}: ${selectedWebsiteActivePlacementCount}`}/>
                    </li>}
                    {!!selectedWebsiteActivePlacementRequestsCount && <li>
                        <LinkComponent to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}?website_id=${selectedWebsiteId}`}
                                       textContent={`${t('Active advertiser offers')}: ${selectedWebsiteActivePlacementRequestsCount}`}/>
                    </li>}
                </ul>
            </>}
            <div className="controls controls_align_justify">
                <Button eventCategory={GtagCategoryEnum.SITES}
                        eventName={`${GtagNameEnum.MY_SITES}${GtagNameEnum.CLICK} no cancel delete button`}
                        onClick={handleCancelClick}
                        textContent={t('No, cancel')}
                        theme={'stroke'}/>
                <Button eventCategory={GtagCategoryEnum.SITES}
                        eventName={`${GtagNameEnum.MY_SITES}${GtagNameEnum.CLICK} yes delete button`}
                        onClick={handleConfirmClick}
                        textContent={t('Yes, delete')}
                        theme={'stroke'}/>
            </div>
        </Modal>
    );
};
