import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IDropdownOption} from '@design-system/ui-kit';
import {WebsiteCreationFormComponent} from 'components';
import {RootState} from 'core';
import {WebsiteFormControlsEnum, WebsiteFormStateType} from 'typings';
import {clearOnboardingFormError, postSite} from '../../onboarding.reducer';
import {StepEnum} from '../../typings';


export const SiteFormComponent: FC<{ nextStep: StepEnum; }> = ({nextStep}) => {
    const dispatch = useDispatch();
    const initFormState: Omit<WebsiteFormStateType, typeof WebsiteFormControlsEnum.LOGO> = {
        [WebsiteFormControlsEnum.CATEGORIES]: [],
        [WebsiteFormControlsEnum.COVERAGE]: null as unknown as number,
        [WebsiteFormControlsEnum.DESCRIPTION]: '',
        [WebsiteFormControlsEnum.GEOS]: [],
        [WebsiteFormControlsEnum.IS_ACTIVE]: true,
        [WebsiteFormControlsEnum.NAME]: '',
        [WebsiteFormControlsEnum.TYPE]: null as unknown as number,
        [WebsiteFormControlsEnum.SUBTYPE]: null as unknown as number,
        [WebsiteFormControlsEnum.URL]: '',
    };
    const {formErrors} = useSelector((state: RootState) => state.onboardingReducer);
    const [websiteSubTypesForDropdown, setWebsiteSubTypesForDropdown] = useState<IDropdownOption[]>([]);
    const [formState, setFormState] = useState<Omit<WebsiteFormStateType, typeof WebsiteFormControlsEnum.LOGO>>(initFormState);


    const handleInputChange = (controlName: WebsiteFormControlsEnum) => {
        dispatch(clearOnboardingFormError(controlName));
    };

    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        return dispatch(postSite({data: formState, nextStep}));
    };


    useEffect(() => () => {
        dispatch(clearOnboardingFormError());
    }, []);


    return (
        <form className="form"
              id="onboardingSiteCreation"
              onSubmit={handleFormSubmit}
              noValidate>
            <WebsiteCreationFormComponent dropdownPositionTop
                                          formErrors={formErrors}
                                          formState={formState}
                                          handleInputChange={handleInputChange}
                                          setFormState={setFormState}
                                          setWebsiteSubTypesForDropdown={setWebsiteSubTypesForDropdown}
                                          websiteSubTypesForDropdown={websiteSubTypesForDropdown}
                                          withLogo={false}/>
        </form>
    );
};
