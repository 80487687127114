import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useParams} from 'react-router-dom';
import {LinkTabsComponent, LoaderWithOverlay, PageTitleComponent, PartnerNameWebsiteTypeComponent} from 'components';
import {CatalogRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, TabInterface} from 'typings';
import {AsideShortInfoComponent, PlacementRouter} from '../../componets';
import {getCatalogOffer, resetCatalogToInitialState} from '../../catalog.reducer';
import placementPageStyle from '../../styles/PlacementPage.module.scss';


const OfferPage: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {placementId} = useParams();
    const isMobile = useMedia();
    const {isLoading, placement} = useSelector((state: RootState) => state.catalogReducer);
    const [tabs, setTabs] = useState<Omit<TabInterface, 'paramValue'>[]>();


    useEffect(() => {
        if (!placementId) return;
        dispatch(getCatalogOffer({placementId}));
    }, [dispatch, placementId]);

    useEffect(() => () => {
        dispatch(resetCatalogToInitialState());
    }, [dispatch]);

    useEffect(() => {
        if (!placement) return;
        setTabs([{
            eventCategory: GtagCategoryEnum.WEBMASTER_OFFER,
            eventLabel: `${placement.name} - ${placement.id}`,
            eventName: `${GtagNameEnum.WEBMASTER_OFFER}${GtagNameEnum.CLICK} description`,
            linkText: t('Description'),
            linkValue: generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId}),
        }, {
            eventCategory: GtagCategoryEnum.WEBMASTER_OFFER,
            eventLabel: `${placement.name} - ${placement.id}`,
            eventName: `${GtagNameEnum.WEBMASTER_OFFER}${GtagNameEnum.CLICK} about publisher`,
            linkText: t('About Publisher'),
            linkValue: generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_USER_INFO}`, {placementId}),
        }]);
    }, [placement]);


    return (
        <main className="page page_aside-left">
            <PageTitleComponent text={t('Publisher offer')}/>

            {!isMobile && <div className="blank page__aside page__aside_sticky">
                {placement && <AsideShortInfoComponent placement={placement}/>}
            </div>}

            {placement && <>
                <div className="page__content page__blank">
                    <div className={`blank`}>
                        <h2 className={`textHeaderMedium_04 ${placementPageStyle.placementPageTitle}`}>{placement.name}</h2>
                        <PartnerNameWebsiteTypeComponent isImplementer
                                                         partner={placement.created_by}
                                                         to={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_USER_INFO}`, {placementId})}
                                                         website={placement.website}/>
                        {!!tabs && <LinkTabsComponent tabs={tabs}/>}
                        <PlacementRouter placement={placement}/>
                        {/*TODO*/}
                        {/*eventCategory={GtagCategoryEnum.WEBMASTER_OFFER}*/}
                        {/*eventLabel={`${placement.name} - ${placement.id}`}*/}
                        {/*eventName={`${CatalogGtagEnum.WEBMASTER_OFFERS_CLICK} website'`}*/}
                    </div>
                </div>
            </>}

            {isLoading && <LoaderWithOverlay/>}
        </main>
    );
};

export {OfferPage};
export default OfferPage;
