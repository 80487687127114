import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from 'components';
import {approveDeal} from '../../deals.reducer';
import {DealBtnComponentInterface} from '../../typings';
import {ConfirmDealModalComponent} from './ConfirmDealModal.component';


export const MakeDealBtnComponent: FC<DealBtnComponentInterface> = ({deal, eventCategory, eventLabel, eventName, showConfirmDialog, updateDataMethod}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);


    const handleMakeDealClick = () => {
        if (showConfirmDialog) {
            setShowModal(true);
        } else {
            dispatch(approveDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
        }
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleConfirmClick = () => {
        dispatch(approveDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
        setShowModal(false);
    };


    return (
        <>
            <Button eventCategory={eventCategory}
                    eventLabel={eventLabel}
                    eventName={eventName}
                    onClick={handleMakeDealClick}
                    textContent={t('Make a deal')}
                    theme={'confirm'}/>
            <ConfirmDealModalComponent handleCancelClick={handleCancelClick} handleConfirmClick={handleConfirmClick} setShowModal={setShowModal} showModal={showModal}/>
        </>
    );
};
