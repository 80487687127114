import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from 'components';
import {declineDeal} from '../../deals.reducer';
import {DealBtnComponentInterface} from '../../typings';
import {RefuseDealModal} from './RefuseDealModal.component';


export const RefuseBtnComponent: FC<DealBtnComponentInterface> = ({className, deal, eventCategory, eventLabel, eventName, updateDataMethod}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);


    const handleDoneClick = () => {
        setShowModal(true);
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleConfirmClick = () => {
        dispatch(declineDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
        setShowModal(false);
    };


    return (
        <>
            <Button className={className}
                    eventCategory={eventCategory}
                    eventLabel={eventLabel}
                    eventName={eventName}
                    onClick={handleDoneClick}
                    textContent={t('Cancel')}
                    theme={'ghost_error'}
                    type="button"/>
            <RefuseDealModal handleCancelClick={handleCancelClick} handleConfirmClick={handleConfirmClick} setShowModal={setShowModal} showModal={showModal}/>
        </>
    );
};
