import {AxiosRequestConfig} from 'axios';
import {RequestPayloadType, SendRequestParamsInterface} from 'typings';

const requestHeaders: any = {};

export const createSendRequestParams = <RequestBodyInterface = undefined>(route: any, payload?: RequestPayloadType<RequestBodyInterface>): SendRequestParamsInterface<RequestBodyInterface> => {
    const {method = 'GET', routeParams, data, callbacks, queryParams, signal} = payload ?? {};
    const url = route;
    const request: AxiosRequestConfig<RequestBodyInterface> = {
        params: queryParams,
        url,
        method,
        data,
        signal,
    };
    return {
        requestHeaders,
        request,
        callbacks: {
            handleServiceError: () => {
            },
            ...callbacks,
        },
    };
};
