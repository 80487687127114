import {RouteObject} from 'react-router-dom';
import {PageNotFound} from 'core/PageNotFound/PageNotFound';
import {DealsRoutesPathEnum} from 'core/Routes/typings/enums/DealsRoutesPath.enum';
import DealList from '../pages/dealList/DealList';
import Deal from '../pages/deal/Deal';


export const dealsRoutes: RouteObject[] = [
    {element: <DealList/>, index: true},
    {element: <Deal/>, path: `${DealsRoutesPathEnum.DEAL}/*`},
    {element: <PageNotFound/>, path: '/*'},
];
