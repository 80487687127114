import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CommentInterface, DealInterface, OfferInterface, PartnerInterface, RequestInterface} from 'typings';
import {DealStoreInterface} from './typings';


const defaultDealsState: DealStoreInterface = {
    comments: [],
    dealList: [],
    isError: false,
    isChatLoading: false,
    isLoading: false,
};

export const dealSlice = createSlice({
    name: 'deal',
    initialState: defaultDealsState,
    reducers: {
        resetDealToInitialState: () => defaultDealsState,
        hideDealsError: state => ({...state, isError: false}),
        hideDealsChatLoader: state => ({...state, isChatLoading: false}),
        hideDealsLoader: state => ({...state, isLoading: false}),
        showDealsError: state => ({...state, isError: true}),
        showDealsChatLoader: state => ({...state, isChatLoading: true}),
        showDealsLoader: state => ({...state, isLoading: true}),
        setDealList: (state, action: PayloadAction<DealInterface<OfferInterface | RequestInterface>[]>) => ({...state, dealList: action.payload}),
        setDeal: (state, action: PayloadAction<DealInterface<OfferInterface | RequestInterface>>) => ({...state, deal: action.payload}),
        setComments: (state, action: PayloadAction<CommentInterface[]>) => ({...state, comments: action.payload}),
        setDealFormErrors: (state, action: PayloadAction<{ [key: string]: string[]; }>) => ({...state, formErrors: action.payload}),
        clearDealFormError: (state, action: PayloadAction<{ inputName: string; }>) => {
            const controlName = action.payload.inputName;
            const newFormError = controlName ? {...state.formErrors} : {};
            delete newFormError?.[controlName];
            return {...state, formErrors: newFormError};
        },
        setResetDealsForm: (state, action: PayloadAction<boolean>) => ({...state, isResetForm: action.payload}),
        setShowSuggestionForm: (state, action: PayloadAction<boolean>) => ({...state, isShowForm: action.payload}),
        setDealPartner: (state, action: PayloadAction<PartnerInterface>) => ({...state, partner: action.payload}),
        setDealListCount: (state, action: PayloadAction<number>) => ({...state, dealCount: action.payload}),

        getDealList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getDeal(state, action: PayloadAction<{ dealId: string; }>) {},
        updateChat(state, action: PayloadAction<{ dealId: string; }>) {},
        postDeal(state, action: PayloadAction<{ dealId: string; data: any }>) {},
        approveDeal(state, action: PayloadAction<{ dealId: string; data: any; updateDataMethod: any; }>) {},
        revokeApproveDeal(state, action: PayloadAction<{ dealId: string; data: any; updateDataMethod: any; }>) {},
        getDealPartner(state, action: PayloadAction<{ partnerId: string }>) {},
        declineDeal(state, action: PayloadAction<{ dealId: string; data: any; updateDataMethod: any; }>) {},
        markAsReadyDeal(state, action: PayloadAction<{ dealId: string; data: any; updateDataMethod: any; }>) {},
    },
});

export const {
    approveDeal,
    clearDealFormError,
    declineDeal,
    getDeal,
    getDealList,
    getDealPartner,
    hideDealsChatLoader,
    hideDealsError,
    hideDealsLoader,
    markAsReadyDeal,
    postDeal,
    resetDealToInitialState,
    revokeApproveDeal,
    setComments,
    setDeal,
    setDealFormErrors,
    setDealList,
    setDealListCount,
    setDealPartner,
    setResetDealsForm,
    setShowSuggestionForm,
    showDealsChatLoader,
    showDealsError,
    showDealsLoader,
    updateChat,
} = dealSlice.actions;

export const dealsReducer = dealSlice.reducer;
