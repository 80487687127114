import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Delete} from 'assets/images/dropzone/delete.svg';
import {ReactComponent as Failed} from 'assets/images/dropzone/failed.svg';
import {ReactComponent as Refresh} from 'assets/images/dropzone/refresh.svg';
import {FileInterface} from 'typings';
import {formatBytes} from 'utils';
import fileStyles from './File.module.scss';


export const FileComponent: FC<{ file: FileInterface, onDelete: (fileId: string) => void, onRefresh: (fileId: string, file: File) => void }> = ({file, onDelete, onRefresh}) => {
    const {t} = useTranslation();

    const handleDeleteClick = () => {
        onDelete(file.id);
    };

    const handleRefreshClick = () => {
        onRefresh(file.id, file.file as File);
    };


    return (
        <>
            {file.meta.success && (<div className={fileStyles.file}>
                <span className={fileStyles.fileText}>{file.uploadedAttachments?.name}</span>
                <span className={fileStyles.fileSubtext}>{formatBytes(file.uploadedAttachments?.size)}</span>
                <button className={fileStyles.fileAction} type="button" onClick={handleDeleteClick}><Delete/></button>
            </div>)}
            {file.meta.failure && (<div className={fileStyles.file}>
                <span className={fileStyles.fileText}>{t('Upload failed')}</span>
                <span className={fileStyles.fileSubtext}>{file.file?.name} — {formatBytes(file.file?.size)}</span>
                <button className={fileStyles.fileAction} type="button" onClick={handleRefreshClick}><Refresh/></button>
                <Failed/>
            </div>)}
            {typeof file.meta.progress == 'number' && (<div className={fileStyles.file} style={{background: `linear-gradient(90deg, #f5f3ff ${file.meta.progress | 0}%, #fff 0), #fff`}}>
                <span className={fileStyles.fileText}>{t('Uploading...')}</span>
                <span className={fileStyles.fileSubtext}>{file.meta.progress | 0}%</span>
                {/*TODO: cancel*/}
            </div>)}
        </>
    );
};
