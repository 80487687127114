import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router-dom';
import acceptedIcon from 'assets/images/statuses/accepted.svg';
import awaitingIcon from 'assets/images/statuses/awaiting.svg';
import rejectedIcon from 'assets/images/statuses/rejected.svg';
import {AttachmentsPreviewComponent, Button} from 'components';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {ChangeRequestStatusEnum, GtagCategoryEnum, GtagNameEnum} from 'typings';
import {MakeDealBtnComponent} from '../btnMakeDeal/MakeDealBtn.component';
import {RevokeApprovalBtnComponent} from '../btnRevokeApproval/RevokeApprovalBtn.component';
import commentStyle from './Comment.module.scss';
import {CommentComponentInterface} from './CommentComponent.interface';


export const CommentComponent: FC<CommentComponentInterface> = (props) => {
    const {attachments, className, currency, deal, dealId, price, priceInclFee, placementEndDate, placementStartDate, showMakeDealBtn, status, text, updateDataMethod} = props;
    const {t} = useTranslation();


    const statuses = {
        [ChangeRequestStatusEnum.APPROVED]: <div className={commentStyle.commentStatus}><img src={acceptedIcon} alt=""/>{t('The deal has been made')}</div>,
        [ChangeRequestStatusEnum.DECLINED]: <div className={commentStyle.commentStatus}><img src={rejectedIcon} alt=""/>{t('The deal has been declined')}</div>,
        [ChangeRequestStatusEnum.PENDING]: <div className={`${commentStyle.commentStatus} ${commentStyle.commentStatus_awaiting}`}>
            <img src={awaitingIcon} alt=""/>
            {t('Pending decision')}
            <RevokeApprovalBtnComponent className={commentStyle.commentStatusActions}
                                        deal={deal}
                                        eventCategory={GtagCategoryEnum.DEALS}
                                        eventLabel={`${deal.name} - ${deal.id}`}
                                        eventName={`${GtagNameEnum.DEAL}${GtagNameEnum.CLICK} revoke last offer button`}
                                        updateDataMethod={updateDataMethod}/>
        </div>,
    };


    return (
        <div className={`${commentStyle.comment} ${className || ''}`}>
            <dl className={commentStyle.commentDl}>
                {price && <>
                    <dt className={`textBody_04 ${commentStyle.commentDt}`}>{priceInclFee ? t('Offer price (incl. fee)') : t('Offer price (ex fee)')}</dt>
                    <dd className={`textBody_02 ${commentStyle.commentDd}`}>{Number(price).toLocaleString()}&nbsp;{currency}</dd>
                </>}
                {(placementEndDate || placementStartDate) && <>
                    <dt className={`textBody_04 ${commentStyle.commentDt}`}>{t('Execution dates')}</dt>
                    <dd className={`textBody_02 ${commentStyle.commentDd}`}>
                        {placementStartDate && <>{t('from')}&nbsp;{new Date(placementStartDate).toLocaleDateString()}&ensp;</>}
                        {placementEndDate && <>{t('until')}&nbsp;{new Date(placementEndDate).toLocaleDateString()}</>}
                    </dd>
                </>}
            </dl>
            {attachments && <AttachmentsPreviewComponent attachments={attachments}/>}
            {!!text && <p className={commentStyle.commentDescription}>{text}</p>}
            <div className="controls">
                {showMakeDealBtn && <MakeDealBtnComponent deal={deal}
                                                          eventCategory={GtagCategoryEnum.DEALS}
                                                          eventLabel={`${deal.name} - ${deal.id}`}
                                                          eventName={`${GtagNameEnum.DEAL} - make an offer button`}
                                                          showConfirmDialog={deal.companion_info.id === deal.implementer.id}
                                                          updateDataMethod={updateDataMethod}/>}
                {dealId && <Button to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId})} theme={'stroke'} textContent={t('Open chat')}/>}
            </div>
            {!showMakeDealBtn && status && statuses[status]}
        </div>
    );
};
