import {generatePath} from 'react-router-dom';
import {call, put, StrictEffect, take, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {FallbackErrorTextConst} from 'consts';
import {history} from 'index';
import {RequestPayloadType, UserInterface} from 'typings';
import {notificationListPushItem} from '../NotificationList';
import {DealsRoutesPathEnum, MainRoutesEnum} from '../Routes';
import {postFileRequest} from '../uploadFile';
import {
    getAppConfigRequest,
    getCategoriesRequest,
    getCurrenciesRequest,
    getDealEventsRequest,
    getGeoRequest,
    getMyBalanceRequest,
    getPlacementTypesRequest,
    getUserRequest,
    getWebsiteTypesRequest,
    postMarkAllEventsAsReadRequest,
    postMarkDealEventsAsReadRequest,
    repeatDealRequest,
} from './common.api';
import {
    clearDealRepeatedFormError,
    getAppConfig,
    getBalances,
    getCategories,
    getCurrencies,
    getEvents,
    getGeo,
    getPlacementTypes,
    getUser,
    getWebsiteTypes,
    hideLoader,
    markAllEventsAsRead,
    markDealAsRead,
    repeatDeal,
    setAppConfig,
    setAttachment,
    setAttachmentError,
    setAttachmentProgress,
    setBalances,
    setCategories,
    setCurrencies,
    setDealRepeatedFormErrors,
    setEvents,
    setGeo,
    setPlacementType,
    setUploadedAttachment,
    setUser,
    setWebsiteType,
    showLoader,
    uploadFile,
} from './common.reducer';


function* getAppConfigWorker(): Generator<StrictEffect, void, any> {
    try {
        const appConfig: any = yield call(getAppConfigRequest);
        yield put(setAppConfig(appConfig));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getBalancesWorker(): Generator<StrictEffect, void, any> {
    try {
        const data: any = yield call(getMyBalanceRequest);
        yield put(setBalances(data));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getCategoriesWorker(): Generator<StrictEffect, void, any> {
    try {
        const categories: any = yield call(getCategoriesRequest);
        yield put(setCategories(categories));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getCurrenciesWorker(): Generator<StrictEffect, void, any> {
    try {
        const currencies: any = yield call(getCurrenciesRequest);
        yield put(setCurrencies(currencies));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getDealEventsWorker(): Generator<StrictEffect, void, any> {
    try {
        const eventList: any = yield call(getDealEventsRequest);
        yield put(setEvents(eventList));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}


function* getGeoWorker(): Generator<StrictEffect, void, any> {
    try {
        const data: any = yield call(getGeoRequest);
        yield put(setGeo(data));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getPlacementTypeWorker(): Generator<StrictEffect, void, any> {
    try {
        const placementTypes: any = yield call(getPlacementTypesRequest);
        yield put(setPlacementType(placementTypes));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getUserWorker(): Generator<StrictEffect, void, any> {
    try {
        const data: UserInterface = yield call(getUserRequest);
        yield put(setUser(data));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getWebsiteTypeWorker(): Generator<StrictEffect, void, any> {
    try {
        const websiteTypes: any = yield call(getWebsiteTypesRequest);
        yield put(setWebsiteType(websiteTypes));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* markAllEventsAsReadWorker(): Generator<StrictEffect, void, any> {
    try {
        yield call(postMarkAllEventsAsReadRequest, {});
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* markDealAsReadWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(postMarkDealEventsAsReadRequest, requestParams);
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* repeatDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(clearDealRepeatedFormError());
        yield put(showLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
            data: payload.payload.data,
        };
        const repeatDeal = yield call(repeatDealRequest, requestParams);
        history.push(generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${repeatDeal.id}`}));
    } catch (error) {
        yield put(setDealRepeatedFormErrors(error));
    } finally {
        yield put(hideLoader());
    }
}

function* uploadAttachmentWorker(payload: any): Generator<StrictEffect, void, any> {
    const formData = new FormData();
    const fileId = `${Date.now()}_${payload.payload.file.name}`;
    yield put(setAttachment({fileId, file: payload.payload.file}));
    formData.append('file', payload.payload.file);
    const channel = yield call(postFileRequest, formData);
    while (channel) {
        try {
            const data = yield take(channel);
            if (typeof data == 'number') {
                yield put(setAttachmentProgress({fileId, progress: data}));
            } else {
                yield put(setUploadedAttachment({fileId, data}));
            }
        } catch (error) {
            yield put(notificationListPushItem({
                id: Date.now().toString(),
                title: `${error.response?.data?.file || error.response?.statusText || error.detail || FallbackErrorTextConst}`,
                theme: ENotificationsThemes.critical,
            }));
            yield put(setAttachmentError({fileId, file: payload.payload.file}));
        }
    }
}


export function* commonWatcher() {
    yield takeEvery(getAppConfig.type, getAppConfigWorker);
    yield takeEvery(getBalances.type, getBalancesWorker);
    yield takeEvery(getCategories.type, getCategoriesWorker);
    yield takeEvery(getCurrencies.type, getCurrenciesWorker);
    yield takeEvery(getEvents.type, getDealEventsWorker);
    yield takeEvery(getGeo.type, getGeoWorker);
    yield takeEvery(getPlacementTypes.type, getPlacementTypeWorker);
    yield takeEvery(getUser.type, getUserWorker);
    yield takeEvery(getWebsiteTypes.type, getWebsiteTypeWorker);
    yield takeEvery(markAllEventsAsRead.type, markAllEventsAsReadWorker);
    yield takeEvery(markDealAsRead.type, markDealAsReadWorker);
    yield takeEvery(repeatDeal.type, repeatDealWorker);
    yield takeEvery(uploadFile.type, uploadAttachmentWorker);
}
