import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {placementsRoutes} from './constants';


const Placements: FC = () => {
    return useRoutes(placementsRoutes);
};

export {Placements};
export default Placements;
