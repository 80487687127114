import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {TabInterface} from 'typings';
import {gtagSendEvent} from 'utils';
import tabsStyle from './Tabs.module.scss';


export const LinkTabsComponent: FC<{ className?: string; tabs: Omit<TabInterface, 'paramValue'>[]; }> = ({className, tabs}) => {
    const activeClassName = `${tabsStyle.tabItem} ${tabsStyle.tabItem_active}`;
    const passiveClassName = tabsStyle.tabItem;


    return (
        <div className={`${className ? className : ''} ${tabsStyle.tabList}`}>
            {tabs.map((tab: Omit<TabInterface, 'paramValue'>) => (
                <NavLink to={tab.linkValue}
                         key={tab.linkValue}
                         {...tab.eventName && {onClick: gtagSendEvent(tab.eventName, tab.eventCategory, tab.eventLabel)}}
                         className={({isActive}) => isActive ? activeClassName : passiveClassName}>
                    {tab.linkText}
                </NavLink>
            ))}
        </div>
    );
};
