import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {IDropdownOption} from '@design-system/ui-kit';
import CategoriesIcon from 'assets/images/icons/categories.svg';
import GeosIcon from 'assets/images/icons/geos.svg';
import {LinkComponent, PartnerNameWebsiteTypeComponent, PlacementInfoBoxComponent} from 'components';
import {useCommonSelector} from 'hooks';
import {UserInterface} from 'typings';
import {getStringArrByMatch} from 'utils';
import {PlacementActionsComponent} from '../actions/PlacementActions.component';
import placementCardStyle from './PlacementCard.module.scss';
import {PlacementCardComponentInterface} from './PlacementCardComponent.interface';


export const PlacementCardComponent: FC<PlacementCardComponentInterface> = ({isRequest, partnerLink, placement, placementLink}) => {
    const {t} = useTranslation();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const [geos, setGeos] = useState<string[]>([]);
    const [category, setCategory] = useState<string[]>([]);
    const infoArrLength = 3;


    useEffect(() => {
        setGeos(getStringArrByMatch(geo, placement.geos));
        if (placement.website) {
            setCategory(getStringArrByMatch(categories, placement.website.categories));
        }
    }, [categories, geo, placement]);


    return (
        <article className={placementCardStyle.card}>
            <div className={placementCardStyle.cardAside}>
                <PlacementInfoBoxComponent name={placement.placement_type_name}
                                           logo={placement.placement_type_logo}
                                           compactView
                                           daysToImplement={placement.days_to_implement}
                                           startDate={placement.placement_start_date}
                                           endDate={placement.placement_end_date}
                                           price={isRequest ? placement.price : placement.price_with_commission}
                                           priceInclFee={!isRequest}
                                           currency={placement.currency}/>
                <PlacementActionsComponent className={placementCardStyle.cardControls}
                                           isAllowedToRespond={isRequest ? user.is_allowed_to_be_implementer : user.is_allowed_to_be_payer}
                                           isRequest={isRequest}
                                           placement={placement}/>
            </div>
            <div className={placementCardStyle.cardContent}>
                <h2 className={`textHeaderMedium_06 ${placementCardStyle.cardName}`}>
                    <Link className={placementCardStyle.cardNameLink} to={placementLink}>{placement.name}</Link>
                </h2>
                <PartnerNameWebsiteTypeComponent isImplementer={!isRequest} partner={placement.created_by} to={partnerLink} website={placement.website}/>
                <p className={placementCardStyle.cardDescription}>{placement.description}</p>
                <ul className={placementCardStyle.cardInfoList}>
                    <li className={placementCardStyle.cardInfoItem}>
                        <img className={placementCardStyle.cardInfoListIcon} src={GeosIcon} alt={t('Regions')}/>
                        {!!geos.length
                            ? <>{geos.length > infoArrLength
                                ? <>{geos?.slice(0, infoArrLength).join(', ')} and <LinkComponent to={placementLink} textContent={t('{{val}} more...', {val: geos.length - infoArrLength})}/></>
                                : <>{geos.join(', ')}</>}</>
                            : <>{t('Worldwide')}</>}
                    </li>
                    {!!category.length && <li className={placementCardStyle.cardInfoItem}>
                        <img className={placementCardStyle.cardInfoListIcon} src={CategoriesIcon} alt={t('Categories')}/>
                        {category.length > infoArrLength
                            ? <>{category?.slice(0, infoArrLength).join(', ')} and <LinkComponent to={placementLink} textContent={t('{{val}} more...', {val: category.length - infoArrLength})}/></>
                            : <>{category.join(', ')}</>}
                    </li>}
                    {!!placement.coverage && <li className={placementCardStyle.cardInfoItem}>
                        {t('Coverage')}: {placement.coverage}
                    </li>}
                </ul>
            </div>
        </article>
    );
};
