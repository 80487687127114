import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FinanceRoutesPathEnum, MainRoutesEnum} from 'core';
import {BalanceInterface, GtagCategoryEnum, UserInterface} from 'typings';
import {gtagSendEvent} from 'utils';
import style from './TooltipUserContent.module.scss';


export const TooltipUserContentComponent: FC<{ balances: BalanceInterface[]; user: UserInterface; }> = ({balances, user}) => {
    const {t} = useTranslation();


    return (
        <div className={style.tooltipUser__content}>
            <div className={style.tooltipUser__top}>
                <div className={`textBodyMedium_03 ${style.tooltipUser__name}`}>{user.first_name} {user.last_name?.[0]}.</div>
                <div className={style.tooltipUser__email}>{user.email}</div>
            </div>
            {(user.is_allowed_to_be_implementer || user.is_allowed_to_be_payer) && <Link to={`${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.BALANCE_HISTORY}`}
                                                                                         onClick={gtagSendEvent('balance button', GtagCategoryEnum.HEADER)}
                                                                                         className={`textBody_03 ${style.tooltipUser__btn}`}>
                {t('Balance')}
                <span className={style.tooltipUser__balance}>{Number(balances[0]?.balance).toLocaleString()} {balances[0]?.currency_id}</span>
            </Link>}
            <a className={`textBody_03 ${style.tooltipUser__btn}`} href="/account/logout/">{t('Log out')}</a>
        </div>
    );
};
