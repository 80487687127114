import React, {FC} from 'react';
import classNames from 'classnames/bind';
import counterStyle from './Counter.module.scss';


export const CounterComponent: FC<{ className?: string; counter: number; theme?: 'fill' | 'stroke' }> = ({className, counter, theme = 'fill'}) => {
    const counterClassName = classNames(
        className,
        counterStyle.counter,
        [counterStyle[`counter_theme_${theme}`]],
    );


    return (
        <span className={counterClassName}>{counter}</span>
    );
};
