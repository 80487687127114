import React, {FC} from 'react';
import {ELoaderSizes, Loader} from '@design-system/ui-kit';
import {ReactComponent as ErrorIcon} from 'assets/images/error.svg';
import {ReactComponent as Delete} from 'assets/images/dropzone/delete.svg';
import FilePlaceholderIcon from 'assets/images/filePlaceholder.svg';
import {FileInterface} from 'typings';
import dealFileStyle from './DealFile.module.scss';


export const DealFileComponent: FC<{ file: FileInterface; handleDeleteClick: (fileId: string) => void; }> = ({file, handleDeleteClick}) => {
    return (
        <div className={dealFileStyle.file}>
            {file.meta.failure && <ErrorIcon className={dealFileStyle.fileErrorIcon}/>}
            {file.meta.success && <>
                {file.uploadedAttachments?.type?.includes('image')
                    ? <img className={dealFileStyle.fileImg} src={file.uploadedAttachments?.file_url} loading="lazy" alt={file.uploadedAttachments?.name}/>
                    : <img className={dealFileStyle.fileImgPlaceholder} src={FilePlaceholderIcon} alt={file.uploadedAttachments?.name}/>
                }
            </>}
            {typeof file.meta.progress == 'number'
                ? <Loader inline noMargin={false} size={ELoaderSizes.s}/>
                : <button className={dealFileStyle.fileBtn} type="button" onClick={() => handleDeleteClick((file.id))}><Delete/></button>
            }
        </div>
    );
};
