import {all} from 'redux-saga/effects';
import {catalogWatcher, dealsWatcher, financeWatcher, offersWatcher, onboardingWatcher, websitesWatcher} from 'modules';
import {commonWatcher} from '../common';


export function* rootWatcher() {
    yield all([
        catalogWatcher(),
        commonWatcher(),
        dealsWatcher(),
        financeWatcher(),
        offersWatcher(),
        onboardingWatcher(),
        websitesWatcher(),
    ]);
}
