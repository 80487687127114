import {useDispatch, useSelector} from 'react-redux';
import {CommonStoreInterface, getAppConfig, getBalances, getCategories, getCurrencies, getEvents, getGeo, getPlacementTypes, getSocket, getUser, getWebsiteTypes, RootState, setFetched} from '../core';


export const useCommonSelector = <T>(prop: (keyof CommonStoreInterface)): T => {
    const dispatch = useDispatch();
    const dispatchMap = {
        appConfig: getAppConfig,
        balances: getBalances,
        categories: getCategories,
        currencies: getCurrencies,
        dealEventsList: getEvents,
        geo: getGeo,
        placementTypes: getPlacementTypes,
        socket: getSocket,
        user: getUser,
        websiteSubTypes: {fetchedProp: 'websiteTypes'},
        websiteSubTypesMap: {fetchedProp: 'websiteTypes'},
        websiteTypes: getWebsiteTypes,
        websiteTypesMap: {fetchedProp: 'websiteTypes'},
    };
    const fetchedProp = dispatchMap[prop].fetchedProp || prop;
    const fetched: boolean = useSelector((state: RootState) => state.commonReducer.fetched[fetchedProp]);
    if (!fetched) {
        dispatch(dispatchMap[fetchedProp]());
        dispatch(setFetched(fetchedProp));
    }
    return useSelector((state: RootState) => state.commonReducer[prop]) as T;
};
