import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ReactComponent as DeleteIcon} from 'assets/images/icons/delete.svg';
import {useCommonSelector} from 'hooks';
import {WebsiteInterface} from 'typings';
import {setSiteIdsToDelete} from '../../onboarding.reducer';
import siteItemStyle from './SiteItem.module.scss';


export const SiteItemComponent: FC<{ site: WebsiteInterface; }> = ({site}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');

    const handleDeleteSiteClick = (id) => () => {
        dispatch(setSiteIdsToDelete(id));
    };


    return (
        <tr>
            <td className={`textBodyMedium_03 ${siteItemStyle.siteItemCell}`}>{site.name}</td>
            <td className={`textBody_04 ${siteItemStyle.siteItemCell} ${siteItemStyle.siteItemType}`}>
                {!!site.website_type_logo && <img className={siteItemStyle.siteItemTypeImg} src={site.website_type_logo} alt=""/>}
                {(site.subtype ? websiteSubTypesMap[site.subtype] : websiteTypesMap[site.type])?.text}
            </td>
            <td className={`word-break textBody_04 ${siteItemStyle.siteItemCell}`}>{site.url}</td>
            <td className={siteItemStyle.siteItemCell}>
                <button onClick={handleDeleteSiteClick(site.id)} title={t('Delete')} className="table__action"><DeleteIcon/></button>
            </td>
        </tr>
    );
};
