import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BakcIcon} from 'assets/images/back.svg';
import {history} from 'index';
import pageTitleStyle from './PageTitle.module.scss';


export const PageTitleComponent: FC<{ text?: string; }> = (props) => {
    const {children, text} = props;
    const {t} = useTranslation();

    const onHistoryBackClick = () => {
        history.back();
    };


    return (
        <h1 className={`${pageTitleStyle.pageTitle} textHeader_03`}>
            <button onClick={onHistoryBackClick} className={pageTitleStyle.pageTitleIcon} title={t('Go back')}><BakcIcon/></button>
            {!!text ? text : children}
        </h1>
    );
};
