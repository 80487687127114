export enum RepeatDealControlsEnum {
    ATTACHMENTS = 'attachments',
    CURRENCY = 'currency',
    DESCRIPTION = 'description',
    NAME = 'name',
    PLACEMENT_END_DATE = 'placement_end_date',
    PLACEMENT_START_DATE = 'placement_start_date',
    PRICE = 'price',
    PRICE_WITH_COMMISSION = 'price_with_commission',
}
