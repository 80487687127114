import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router-dom';
import {LinkComponent} from 'components';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {DealInterface, DealStatusEnum, GtagCategoryEnum, GtagNameEnum} from 'typings';
import lastSentenceStyle from './DealLastSentence.module.scss';


export const DealLastSentenceComponent: FC<{ deal: DealInterface; myRole: string; state: string;}> = ({deal, myRole, state}) => {
    const {t} = useTranslation();


    return (
        <div className={`textBody_02 ${lastSentenceStyle.lastSentence}`}>
            <span>
                {[DealStatusEnum.CLOSED, DealStatusEnum.DONE, DealStatusEnum.IN_PROGRESS].includes(deal.status) ? t('Deal execution dates') : t('Latest proposal')}:
            </span>
            <span className={lastSentenceStyle.lastSentence__em}>
                {[DealStatusEnum.FIRST_RESPONSE, DealStatusEnum.CORRECTION_REQUESTED, DealStatusEnum.DECLINED].includes(deal.status) && <>
                    {deal.last_comment
                        ? <>
                            {new Date(deal.last_comment.created_at).toLocaleDateString()}&ensp;
                            (<LinkComponent eventCategory={GtagCategoryEnum.DEALS}
                                            eventLabel={`${deal.name} - ${deal.id}`}
                                            eventName={`${GtagNameEnum.MY_DEALS} - ${myRole} - ${state}${GtagNameEnum.CLICK} show message`}
                                            textContent={t('Show message')}
                                            to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}/>)
                        </>
                        : <span className={lastSentenceStyle.lastSentence__em_ghost}>{t('N/A')}</span>}
                </>}
                {[DealStatusEnum.CLOSED, DealStatusEnum.DONE, DealStatusEnum.IN_PROGRESS].includes(deal.status) && <>
                    {new Date(deal.approved_at || '').toLocaleDateString()}
                    &ensp;—&ensp;
                    {deal.closed_at ? new Date(deal.closed_at).toLocaleDateString() : <span className={lastSentenceStyle.lastSentence__em_ghost}>{t('Ongoing')}</span>}
                </>}
            </span>
        </div>
    );
};
