import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {INotificationsItem, Notifications} from '@design-system/ui-kit';
import {RootState} from '../store';
import {notificationListDeleteItem, notificationListDeleteItemId, notificationListSetItems} from './notificationList.reducer';


export const NotificationsList: FC = () => {

    const {notificationList, notificationIdList} = useSelector((state: RootState) => state.notificationListReducer);

    const dispatch = useDispatch();

    const handleItemsChange = (notificationList: INotificationsItem[]) => {
        dispatch(notificationListSetItems(notificationList));
    };


    useEffect(() => {
        notificationIdList.forEach(notificationId => {
            setTimeout(() => {
                dispatch(notificationListDeleteItem(notificationId));
            }, 3000);
            dispatch(notificationListDeleteItemId(notificationId));
        });
    }, [dispatch, notificationIdList]);


    return (
        <Notifications items={notificationList} onItemsChange={handleItemsChange}/>
    );
};
