export enum GtagCategoryEnum {
    ADVERTISER_OFFER =  'marketplacex - advertiser offer',
    CATALOG = 'marketplacex - catalog',
    DEALS = 'marketplacex - my deals',
    FINANCE = 'marketplacex - finance',
    HEADER = 'marketplacex - header',
    OFFERS = 'marketplacex - my offers',
    SITES = 'marketplacex - my sites',
    WEBMASTER_OFFER =  'marketplacex - webmaster offer',
    WELCOME = 'marketplacex - welcome page',
}
