import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {SiteItemComponent} from '../siteItem/SiteItem.component';
import siteListStyle from './SiteList.module.scss';


export const SiteListComponent: FC = () => {
    const {siteIdsToDelete, siteList} = useSelector((state: RootState) => state.onboardingReducer);


    return (
        <table className={siteListStyle.siteListWrap}>
            <tbody className={siteListStyle.siteListBody}>
                {siteList.map(site => (!siteIdsToDelete.includes(site.id) && <SiteItemComponent key={site.id} site={site}/>))}
            </tbody>
        </table>
    );
};
