import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {PlacementTabComponent, WebsiteNameTypeComponent} from 'components';
import {RootState} from 'core';
import {OfferInterface, PlacementType, RequestInterface, WebsiteInterface} from 'typings';


export const DealPlacementInfoComponent: FC = () => {
    const {deal} = useSelector((state: RootState) => state.dealsReducer);


    return (
        <>
            {!!deal && <div>
                <h2 className="textHeaderMedium_04 word-break">{((deal.placement || deal.placement_request) as OfferInterface | RequestInterface).name}</h2>
                {!!deal.website && <WebsiteNameTypeComponent website={deal.website as WebsiteInterface}/>}
                <PlacementTabComponent placement={(deal.placement || deal.placement_request) as unknown as PlacementType} isRequest={!!deal.placement_request}/>
            </div>}
        </>
    );
};
