import React, {FC} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {TabInterface} from 'typings';
import {gtagSendEvent} from 'utils';
import tabsStyle from './Tabs.module.scss';
import {TabListComponentInterface} from './TabListComponent.interface';


export const TabsComponent: FC<TabListComponentInterface> = ({className, defaultParamValue, link, paramName, tabs, withParams = false}) => {
    const [searchParams] = useSearchParams();
    const getParams = (paramValue) => {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set(paramName, paramValue);
        return newSearchParams.toString();
    };


    return (
        <div className={`${className} ${tabsStyle.tabList}`}>
            {tabs.map((tab: Omit<TabInterface, 'linkValue'>) => (
                <Link to={`${link}${withParams ? '&' : '?'}${getParams(tab.paramValue)}`}
                      key={tab.paramValue}
                      {...tab.eventName && {onClick: gtagSendEvent(tab.eventName, tab.eventCategory, tab.eventLabel)}}
                      className={`${tabsStyle.tabItem} ${(tab.paramValue === (searchParams.get(paramName) || defaultParamValue)) ? tabsStyle.tabItem_active : ''}`}>
                    {tab.linkText}
                </Link>
            ))}
        </div>
    );
};
