import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox, Dropdown, EDropdownOptionsPosition, ETextAreaThemes, IDropdownOption, Input, TDropdownOptionValue, TextArea, ValidationTooltipWrapper} from '@design-system/ui-kit';
import {useCommonSelector} from 'hooks';
import {WebsiteFormControlsEnum} from 'typings';
import {FileDropComponent} from '../FileDrop/FileDrop.component';
import {WebsiteCreationFormComponentInterface} from './WebsiteCreationFormComponent.interface';


export const WebsiteCreationFormComponent: FC<WebsiteCreationFormComponentInterface> = ({
                                                                                            withLogo = true,
                                                                                            handleDelete,
                                                                                            handleDrop,
                                                                                            files,
                                                                                            formErrors,
                                                                                            formState,
                                                                                            handleInputChange,
                                                                                            setFormState,
                                                                                            dropdownPositionTop = false,
                                                                                            websiteSubTypesForDropdown,
                                                                                            setWebsiteSubTypesForDropdown,
                                                                                        }) => {
    const {t} = useTranslation();
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const websiteTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('websiteTypes');
    const websiteSubTypes: { [key: number]: IDropdownOption[]; } = useCommonSelector<{ [key: number]: IDropdownOption[]; }>('websiteSubTypes');
    const [supportedGeosList, setSupportedGeosList] = useState<IDropdownOption[]>([]);
    const [websiteCategoriesForDropdown, setWebsiteCategoriesForDropdown] = useState<IDropdownOption[]>([]);
    const [websiteTypesForDropdown, setWebsiteTypesForDropdown] = useState<IDropdownOption[]>([]);

    useEffect(() => {
        setSupportedGeosList(geo);
    }, [geo]);
    useEffect(() => {
        setWebsiteTypesForDropdown(websiteTypes);
    }, [websiteTypes]);
    useEffect(() => {
        setWebsiteCategoriesForDropdown(categories);
    }, [categories]);

    const onInputChange = (controlName: WebsiteFormControlsEnum) => (newValue: string | TDropdownOptionValue | null | TDropdownOptionValue[]) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputTypeChange = (newValue: TDropdownOptionValue | null) => {
        setFormState({
            ...formState,
            [WebsiteFormControlsEnum.TYPE]: newValue as number,
            [WebsiteFormControlsEnum.SUBTYPE]: null as unknown as number,
        });

        handleInputChange(WebsiteFormControlsEnum.TYPE);

        setWebsiteSubTypesForDropdown(websiteSubTypes[newValue as number] || []);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const onCheckboxChange = (controlName: WebsiteFormControlsEnum) => (checked: boolean) => {
        setFormState({
            ...formState,
            [controlName]: checked,
        });

        handleInputChange(controlName);
    };


    return (
        <>
            <fieldset className="form__blank form__fieldset">
                <p className="textHeader_04 form__legend">{t('General information')}</p>
                <Input name={WebsiteFormControlsEnum.NAME}
                       placeholder={t('Name')}
                       required
                       value={formState[WebsiteFormControlsEnum.NAME]}
                       onChange={onInputChange(WebsiteFormControlsEnum.NAME)}
                       errorMessage={formErrors?.[WebsiteFormControlsEnum.NAME]?.toString()}
                       onBlur={onInputBlur}/>
                <Input name={WebsiteFormControlsEnum.URL}
                       placeholder={t('URL')}
                       required
                       value={formState[WebsiteFormControlsEnum.URL]}
                       onChange={onInputChange(WebsiteFormControlsEnum.URL)}
                       errorMessage={formErrors?.[WebsiteFormControlsEnum.URL]?.toString()}
                       onBlur={onInputBlur}/>
                <ValidationTooltipWrapper errorMessage={formErrors?.[WebsiteFormControlsEnum.IS_ACTIVE]?.toString()}>
                    <Checkbox name={WebsiteFormControlsEnum.IS_ACTIVE}
                              checked={formState[WebsiteFormControlsEnum.IS_ACTIVE]}
                              onChange={onCheckboxChange(WebsiteFormControlsEnum.IS_ACTIVE)}>
                        {t('This site is active')}
                    </Checkbox>
                </ValidationTooltipWrapper>
                <ValidationTooltipWrapper errorMessage={formErrors?.[WebsiteFormControlsEnum.DESCRIPTION]?.toString()}>
                    <TextArea placeholder={t('Description')}
                              value={formState[WebsiteFormControlsEnum.DESCRIPTION]}
                              theme={formErrors?.[WebsiteFormControlsEnum.DESCRIPTION] ? ETextAreaThemes.error : ETextAreaThemes.base}
                              className="textarea"
                              onChange={onInputChange(WebsiteFormControlsEnum.DESCRIPTION)}/>
                </ValidationTooltipWrapper>
                {(withLogo && files && handleDelete && handleDrop) && <div>
                    <p className="form__text">{t('Logo')}</p>
                    <FileDropComponent accept={['image/*']}
                                       files={files}
                                       handleDelete={handleDelete}
                                       handleDrop={handleDrop}
                                       multiple={false}/>
                    {formErrors?.[WebsiteFormControlsEnum.LOGO] && (<p className="form__error">{formErrors?.[WebsiteFormControlsEnum.LOGO]}</p>)}
                </div>}
            </fieldset>
            <fieldset className="form__blank form__fieldset">
                <p className="textHeader_04 form__legend">{t('Site settings')}</p>
                <div className="form__dropdownWrap">
                    <Dropdown value={formState[WebsiteFormControlsEnum.GEOS]}
                              placeholder={t('Countries')}
                              uncheckOptionByClick
                              optionsListMaxHeight={supportedGeosList.length ? 300 : undefined}
                              multiple
                              required
                              onMultipleChange={onInputChange(WebsiteFormControlsEnum.GEOS)}
                              {...dropdownPositionTop && {optionsPosition: EDropdownOptionsPosition.top}}
                              options={supportedGeosList}/>
                    {formErrors?.[WebsiteFormControlsEnum.GEOS] && (<p className="form__error">{formErrors?.[WebsiteFormControlsEnum.GEOS]}</p>)}
                </div>
                <Input type="number"
                       name={WebsiteFormControlsEnum.COVERAGE}
                       placeholder={t('Coverage, visitors/mo.')}
                       required
                       value={formState[WebsiteFormControlsEnum.COVERAGE]?.toString()}
                       onChange={onInputChange(WebsiteFormControlsEnum.COVERAGE)}
                       errorMessage={formErrors?.[WebsiteFormControlsEnum.COVERAGE]?.toString()}
                       onBlur={onInputBlur}/>
                <div className="form__line">
                    <div className="form__dropdownWrap">
                        <Dropdown value={formState[WebsiteFormControlsEnum.TYPE]}
                                  placeholder={t('Type')}
                                  uncheckOptionByClick
                                  optionsListMaxHeight={websiteTypesForDropdown.length ? 300 : undefined}
                                  required
                                  onChange={onInputTypeChange}
                                  {...dropdownPositionTop && {optionsPosition: EDropdownOptionsPosition.top}}
                                  options={websiteTypesForDropdown}/>
                        {formErrors?.[WebsiteFormControlsEnum.TYPE] && (<p className="form__error">{formErrors?.[WebsiteFormControlsEnum.TYPE]}</p>)}
                    </div>
                    <div className="form__dropdownWrap">
                        <Dropdown value={formState[WebsiteFormControlsEnum.SUBTYPE]}
                                  placeholder={t('Subtypes')}
                                  uncheckOptionByClick
                                  optionsListMaxHeight={websiteSubTypesForDropdown.length ? 300 : undefined}
                                  onChange={onInputChange(WebsiteFormControlsEnum.SUBTYPE)}
                                  {...dropdownPositionTop && {optionsPosition: EDropdownOptionsPosition.top}}
                                  options={websiteSubTypesForDropdown}/>
                        {formErrors?.[WebsiteFormControlsEnum.SUBTYPE] && (<p className="form__error">{formErrors?.[WebsiteFormControlsEnum.SUBTYPE]}</p>)}
                    </div>
                </div>
                <div className="form__dropdownWrap">
                    <Dropdown value={formState[WebsiteFormControlsEnum.CATEGORIES]}
                              placeholder={t('Categories')}
                              uncheckOptionByClick
                              optionsListMaxHeight={websiteCategoriesForDropdown.length ? 300 : undefined}
                              multiple
                              onMultipleChange={onInputChange(WebsiteFormControlsEnum.CATEGORIES)}
                              {...dropdownPositionTop && {optionsPosition: EDropdownOptionsPosition.top}}
                              options={websiteCategoriesForDropdown}/>
                    {formErrors?.[WebsiteFormControlsEnum.CATEGORIES] && (<p className="form__error">{formErrors?.[WebsiteFormControlsEnum.CATEGORIES]}</p>)}
                </div>
            </fieldset>
        </>
    );
};
