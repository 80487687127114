import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    AppConfigInterface,
    BalanceInterface,
    CodeNameInterface,
    DealEventsInterface,
    EventInterface,
    EventPayloadInterface,
    IdNameInterface,
    UploadedAttachmentInterface,
    UserInterface,
    WebsiteTypeInterface,
} from 'typings';
import {CommonStoreInterface} from './typings';


const defaultCommonState: CommonStoreInterface = {
    appConfig: {} as AppConfigInterface,
    balances: [],
    categories: [],
    currencies: [],
    dealEventsList: [],
    fetched: {},
    files: {},
    geo: [],
    isLoading: false,
    placementTypes: [],
    user: {
        email: '',
        first_name: '',
        id: '',
        is_allowed_to_be_payer: false,
        is_allowed_to_be_implementer: false,
        is_passed_onboarding: false,
        last_name: '',
        username: '',
    },
    websiteSubTypes: {},
    websiteSubTypesMap: {},
    websiteTypes: [],
    websiteTypesMap: {},
};


export const commonSlice = createSlice({
    name: 'common',
    initialState: defaultCommonState,
    reducers: {
        showLoader: state => ({...state, isLoading: true}),
        hideLoader: state => ({...state, isLoading: false}),
        addEvent: (state, action: PayloadAction<EventPayloadInterface>) => {
            let currentDealEvents: DealEventsInterface = {
                id: action.payload.deal_id,
                name: action.payload.deal_name,
                companion_info: action.payload.companion_info,
                events: [{
                    id: action.payload.id,
                    text: action.payload.text,
                    title: action.payload.title,
                }],
            };
            let restDealEvents: DealEventsInterface[] = [];
            state.dealEventsList.forEach(events => {
                events.id === action.payload.deal_id
                    ? currentDealEvents.events = [...currentDealEvents.events, ...events.events]
                    : restDealEvents.push(events);
            });
            return {...state, dealEventsList: [currentDealEvents, ...restDealEvents]};
        },
        clearEvents: (state) => ({...state, dealEventsList: []}),
        deleteEvent: (state, action: PayloadAction<DealEventsInterface>) => {
            const events: DealEventsInterface[] = [...state.dealEventsList].filter(event => event.id !== action.payload.id);
            return {...state, dealEventsList: events};
        },
        setEvents: (state, action: PayloadAction<DealEventsInterface[]>) => {
            return {...state, dealEventsList: action.payload};
        },
        setLastMsg: (state, action: PayloadAction<EventInterface>) => ({...state, lastMsg: action.payload}),

        setAppConfig: (state, action: PayloadAction<AppConfigInterface>) => ({...state, appConfig: action.payload}),
        setBalances: (state, action: PayloadAction<BalanceInterface[]>) => ({...state, balances: action.payload}),
        setCategories: (state, action: PayloadAction<IdNameInterface[]>) => {
            const categories = action.payload.map((websiteType: IdNameInterface) => ({value: websiteType.id, text: websiteType.name}));
            return {...state, categories};
        },
        setCurrencies: (state, action: PayloadAction<CodeNameInterface[]>) => ({
            ...state,
            currencies: action.payload.map((currency: CodeNameInterface) => ({value: currency.code, text: currency.name})),
        }),
        setGeo: (state, action: PayloadAction<CodeNameInterface[]>) => ({...state, geo: action.payload.map((geo: CodeNameInterface) => ({value: geo.code, text: geo.name}))}),
        setPlacementType: (state, action: PayloadAction<IdNameInterface[]>) => ({...state, placementTypes: action.payload.map((type: IdNameInterface) => ({value: type.id, text: type.name}))}),
        setUser: (state, action: PayloadAction<UserInterface>) => ({...state, user: action.payload}),
        setWebsiteType: (state, action: PayloadAction<WebsiteTypeInterface[]>) => {
            const websiteTypes = action.payload.map((websiteType: WebsiteTypeInterface) => ({value: websiteType.id, text: websiteType.name}));
            const websiteTypesMap = action.payload.reduce((types: any, currentType: WebsiteTypeInterface) => ({
                ...types,
                [currentType.id]: {value: currentType.id, text: currentType.name},
            }), {});
            const websiteSubTypes = action.payload.reduce((types: any, currentType: WebsiteTypeInterface) => ({
                ...types,
                [currentType.id]: currentType.subtypes.map((subType: Omit<WebsiteTypeInterface, 'subtypes'>) => ({value: subType.id, text: subType.name})),
            }), {});
            const websiteSubTypesMap = action.payload.reduce((types: any, currentType: WebsiteTypeInterface) => {
                currentType.subtypes.forEach(subType => types[subType.id] = {value: subType.id, text: subType.name});
                return types;
            }, {});
            return {...state, websiteSubTypes, websiteSubTypesMap, websiteTypes, websiteTypesMap};
        },

        setFetched: (state, action: PayloadAction<string>) => ({...state, fetched: {...state.fetched, [action.payload]: true}}),

        getAppConfig() {},
        getBalances() {},
        getCategories() {},
        getCurrencies() {},
        getGeo() {},
        getPlacementTypes() {},
        getUser() {},
        getWebsiteTypes() {},
        getEvents() {},
        markAllEventsAsRead() {},
        markDealAsRead(state, action: PayloadAction<{ dealId: number; }>) {},

        setDealRepeatedFormErrors: (state, action: PayloadAction<{ [key: string]: string[]; }>) => ({...state, repeatedFormErrors: action.payload}),
        clearDealRepeatedFormError: (state, action: PayloadAction<{ inputName?: string; } | undefined>) => {
            const controlName = action.payload?.inputName;
            const newFormError = controlName ? {...state.repeatedFormErrors} : {};
            if (controlName) {
                delete newFormError?.[controlName];
            }
            return {...state, repeatedFormErrors: newFormError};
        },
        repeatDeal(state, action: PayloadAction<{ dealId: string; data: any; }>) {},


        uploadFile(state, action: PayloadAction<{ file: File; }>) {},
        deleteAttachment: (state, action: PayloadAction<string>) => {
            let {[action.payload]: deletedFile, ...files} = state.files;
            return {...state, files};
        },
        clearAttachments: state => ({...state, files: {}}),
        setInitialAttachments: (state, action: PayloadAction<{ attachments: UploadedAttachmentInterface | UploadedAttachmentInterface[]; }>) => {
            if (!action.payload.attachments) return state;
            const files = Array.isArray(action.payload.attachments)
                ? Object.fromEntries(action.payload.attachments.map(attachment => [attachment.file_id, {
                    id: `${attachment.file_id}`,
                    meta: {success: true},
                    uploadedAttachments: attachment,
                }]))
                : {
                    [action.payload.attachments.file_id]: {
                        id: `${action.payload.attachments.file_id}`,
                        meta: {success: true},
                        uploadedAttachments: action.payload.attachments,
                    },
                };

            return {...state, files};
        },
        setAttachment: (state, action: PayloadAction<{ fileId: string; file: File; }>) => {
            let files = {
                ...state.files,
                [action.payload.fileId]: {
                    id: action.payload.fileId,
                    file: action.payload.file,
                    meta: {progress: 0},
                },
            };
            return {...state, files};
        },
        setUploadedAttachment: (state, action: PayloadAction<{ fileId: string; data: UploadedAttachmentInterface }>) => {
            const fileData = state.files[action.payload.fileId];
            let files = {
                ...state.files,
                [action.payload.fileId]: {
                    ...fileData,
                    uploadedAttachments: action.payload.data,
                    meta: {success: true},
                },
            };
            return {...state, files};
        },
        setAttachmentProgress: (state, action: PayloadAction<{ fileId: string; progress: number; }>) => {
            const fileData = state.files[action.payload.fileId];
            let files = {
                ...state.files,
                [action.payload.fileId]: {
                    ...fileData,
                    id: action.payload.fileId,
                    meta: {progress: action.payload.progress},
                },
            };
            return {...state, files};
        },
        setAttachmentError: (state, action: PayloadAction<{ fileId: string; file: File; }>) => {
            const fileData = state.files[action.payload.fileId];
            let files = {
                ...state.files,
                [action.payload.fileId]: {
                    ...fileData,
                    file: action.payload.file,
                    meta: {failure: true},
                },
            };
            return {...state, files};
        },

        getSocket: (state) => {
            return {...state, socket: new WebSocket(`${window.location.protocol.replace('http', 'ws')}//${window.location.host}/api/ws/chat/${state.user.id}/`)};
        },
    },
});

export const {
    addEvent,
    clearAttachments,
    clearEvents,
    clearDealRepeatedFormError,
    deleteAttachment,
    deleteEvent,
    getAppConfig,
    getBalances,
    getCategories,
    getCurrencies,
    getEvents,
    getGeo,
    getPlacementTypes,
    getSocket,
    getUser,
    getWebsiteTypes,
    hideLoader,
    markAllEventsAsRead,
    markDealAsRead,
    repeatDeal,
    setAppConfig,
    setAttachment,
    setAttachmentError,
    setAttachmentProgress,
    setBalances,
    setCategories,
    setCurrencies,
    setDealRepeatedFormErrors,
    setFetched,
    setEvents,
    setGeo,
    setInitialAttachments,
    setLastMsg,
    setPlacementType,
    setUser,
    setWebsiteType,
    setUploadedAttachment,
    showLoader,
    uploadFile,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
