import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {useCommonSelector} from 'hooks';
import {DealUserInterface, WebsiteInterface} from 'typings';
import {AvatarComponent} from '../Avatar/Avatar.component';
import partnerNameWebsiteTypeStyle from './PartnerNameWebsiteType.module.scss';


export const PartnerNameWebsiteTypeComponent: FC<{ isImplementer?: boolean; partner: DealUserInterface; to: string; website?: WebsiteInterface; }> = ({isImplementer, partner, to, website}) => {
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');


    return (
        <div className={partnerNameWebsiteTypeStyle.shortInfo}>
            <Link to={to} className={partnerNameWebsiteTypeStyle.shortInfoLink}>
                <AvatarComponent text={`${partner.first_name[0]}${partner.last_name[0]}`} size={'s'} isImplementer={!!isImplementer}/>
                <span className={partnerNameWebsiteTypeStyle.shortInfoPartnerName}>{partner.first_name} {partner.last_name?.[0]}.</span>
            </Link>
            {!!website && <>
                <span className={partnerNameWebsiteTypeStyle.shortInfoDelimiter}>|</span>
                <a href={website.url} className={partnerNameWebsiteTypeStyle.websiteType} target="_blank">
                    {!!website.website_type_logo && <img className={partnerNameWebsiteTypeStyle.websiteTypeLogo}
                                                         src={website.website_type_logo}
                                                         alt=""
                                                         width={20}
                                                         height={20}/>}
                    {(website.subtype ? websiteSubTypesMap[website.subtype] : websiteTypesMap[website.type])?.text}
                </a>
            </>}
        </div>
    );
};
