import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {IDropdownOption, SmartTable, TDropdownOptionValue, TTableColumns, TTableRows} from '@design-system/ui-kit';
import acceptedIcon from 'assets/images/statuses/accepted.svg';
import holdIcon from 'assets/images/statuses/hold.svg';
import rejectedIcon from 'assets/images/statuses/rejected.svg';
import {FilterSearchSortComponent, LinkTabsComponent} from 'components';
import {TableTexts} from 'consts';
import {FinanceRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {PaginationEnum, TabInterface, UserInterface} from 'typings';
import {FinanceDateFilterComponent, PaymentFilterComponent} from '../../components';
import {FinanceOperationTypeTextConst, FinanceStatusTextConst} from '../../constants';
import {FinanceFilterEnum, PaymentRequestInterface, PaymentStatusEnum} from '../../typings';
import {getBalanceTransactionRequestsList, resetFinanceToInitialState} from '../../finance.reducer';


export const PaymentRequests: FC<{ user: UserInterface; }> = ({user}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {paymentRequestsCount, paymentRequestsList, isLoading, isError} = useSelector((state: RootState) => state.financeReducer);
    const isNarrowScreen = useMedia();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableBalanceTransactionRequests, setBalanceTransactionRequests] = useState<TTableRows>([]);


    const tabs: Omit<TabInterface, 'paramValue'>[] = [
        {linkText: t('Balance history'), linkValue: `${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.BALANCE_HISTORY}`},
        {linkText: t('Payment requests'), linkValue: `${MainRoutesEnum.FINANCE}${FinanceRoutesPathEnum.PAYMENT_REQUESTS}`},
    ];

    const orderingOptions: IDropdownOption[] = [
        {value: 'created_at', text: t('Date (oldest first)')},
        {value: '-created_at', text: t('Date (newest first)')},
        {value: 'amount', text: t('Amount (ascending)')},
        {value: '-amount', text: t('Amount (descending)')},
    ];

    const tableColumn: TTableColumns = [
        {key: 'id', value: t('ID')},
        {key: 'dateCell', value: t('Date')},
        {key: 'operationTypeCell', value: t('Type')},
        {key: 'statusCell', value: t('Status')},
        {key: 'amountCell', value: t('Amount')},
    ];

    const statusItem = {
        [PaymentStatusEnum.APPROVED]: <>
            <img src={acceptedIcon} alt=""/>
            {FinanceStatusTextConst[PaymentStatusEnum.APPROVED]}
        </>,
        [PaymentStatusEnum.NEW]: <>
            <img src={holdIcon} alt=""/>
            {FinanceStatusTextConst[PaymentStatusEnum.NEW]}
        </>,
        [PaymentStatusEnum.REJECTED]: <>
            <img src={rejectedIcon} alt=""/>
            {FinanceStatusTextConst[PaymentStatusEnum.REJECTED]}
        </>,
    };


    const handlePaginationChange = (params: any) => {
        if (!Object.entries(params).every(([key, value]) => Number(searchParams.get(key)) === value)) {
            const newSearchParams = new URLSearchParams(searchParams);
            Object.entries(params).forEach(([key, value]) => {
                newSearchParams.set(key, value as string);
            });
            setSearchParams(newSearchParams);
        }
    };

    const handleSearchAndOrderingChange = (controlName: string) => (value: string | TDropdownOptionValue | null) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);
    };


    useEffect(() => () => {
        dispatch(resetFinanceToInitialState());
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        dispatch(getBalanceTransactionRequestsList({queryParams: newSearchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => {
        if (!paymentRequestsList) return;
        const tableData = paymentRequestsList.map((item: PaymentRequestInterface) => ({
            ...item,
            dateCell: (<span className="table__secondary">{new Date(item.created_at).toLocaleDateString()}</span>),
            amountCell: (<span className="table__main">{Number(item.amount).toLocaleString()} {item.currency}</span>),
            statusCell: (<span className="table__secondary table__statusCell">{statusItem[item.status]}</span>),
            operationTypeCell: (<span className="table__secondary">{FinanceOperationTypeTextConst[item.operation_type]}</span>),
        })) as TTableRows;
        setBalanceTransactionRequests(tableData);
    }, [paymentRequestsList, t]);


    return (
        <>
            {!isNarrowScreen && <aside className="page__aside page__aside_sticky blank"><PaymentFilterComponent user={user}/></aside>}

            <section className="blank page__content page__blank">
                <LinkTabsComponent tabs={tabs}/>
                <FilterSearchSortComponent AdditionalFilterComponent={FinanceDateFilterComponent}
                                           filter={{component: <PaymentFilterComponent user={user}/>}}
                                           sort={{
                                               onChange: handleSearchAndOrderingChange(FinanceFilterEnum.ORDERING),
                                               options: orderingOptions,
                                               placeholder: t('Sort by'),
                                               value: searchParams.get(FinanceFilterEnum.ORDERING) || '',
                                           }}/>
                <SmartTable columns={tableColumn}
                            texts={TableTexts}
                            rows={tableBalanceTransactionRequests}
                            showPendingState={isLoading}
                            total={paymentRequestsCount}
                            limit={Number(searchParams.get(PaginationEnum.LIMIT) || 20)}
                            offset={Number(searchParams.get(PaginationEnum.OFFSET))}
                            onPageChange={handlePaginationChange}
                            showErrorState={isError}
                            isMobile={isNarrowScreen}/>
            </section>
        </>
    );
};
