import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, Link, useSearchParams} from 'react-router-dom';
import {IDropdownOption, SmartTable, TDropdownOptionValue, TTableColumns, TTableRows} from '@design-system/ui-kit';
import {ReactComponent as AddIcon} from 'assets/images/icons/add.svg';
import {ReactComponent as DeleteIcon} from 'assets/images/icons/delete.svg';
import {ReactComponent as EditIcon} from 'assets/images/icons/edit.svg';
import {Button, FilterSearchSortComponent, LinkComponent, PageTitleComponent, TabsComponent} from 'components';
import {TableTexts} from 'consts';
import {CatalogRoutesPathEnum, MainRoutesEnum, PlacementRoutesPathEnum, RootState} from 'core';
import {useCommonSelector, useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum, RequestInterface, TabInterface, UserInterface} from 'typings';
import {getEventDimension, gtagSendEvent} from 'utils';
import {ConfirmDeleteModal, PlacementFilterComponent} from '../../../components';
import {PlacementFilterEnum, PlacementStatusEnum} from '../../../typings';
import {deleteRequest, getRequestList, resetPlacementToInitialState} from '../../../placements.reducer';


const RequestList: FC = () => {
    const {t} = useTranslation();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const dispatch = useDispatch();
    const {isLoading, isError, requestsCount, requestList} = useSelector((state: RootState) => state.placementReducer);
    const isNarrowScreen = useMedia();
    const [showModal, setShowModal] = useState(false);
    const [selectedPlacementId, setSelectedPlacementId] = useState<null | number>(null);
    const [firstInit, setFirstInit] = useState<boolean>(true);


    const tabs: Omit<TabInterface, 'linkValue'>[] = [{
        eventCategory: GtagCategoryEnum.OFFERS,
        eventLabel: 'active offers',
        eventName: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Active offers'),
        paramValue: PlacementStatusEnum.ACTIVE,
    }, {
        eventCategory: GtagCategoryEnum.OFFERS,
        eventLabel: 'completed offers',
        eventName: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Completed offers'),
        paramValue: PlacementStatusEnum.COMPLETED,
    }, {
        eventCategory: GtagCategoryEnum.OFFERS,
        eventLabel: 'draft',
        eventName: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.STATUS_OFFERS}`,
        linkText: t('Draft'),
        paramValue: PlacementStatusEnum.DRAFT,
    }];

    const orderingOptions: IDropdownOption[] = [
        {value: 'name', text: t('Name (A–Z)')},
        {value: '-name', text: t('Name (Z–A)')},
        {value: 'website_name', text: t('Site name (A–Z)')},
        {value: '-website_name', text: t('Site name (Z–A)')},
        {value: 'placement_start_date', text: t('Placement date (oldest first)')},
        {value: '-placement_start_date', text: t('Placement date (newest first)')},
        {value: 'coverage', text: t('Coverage (ascending)')},
        {value: '-coverage', text: t('Coverage (descending)')},
        {value: 'price', text: t('Price (lowest first)')},
        {value: '-price', text: t('Price (highest first)')},
    ];

    const tableColumn: TTableColumns = [
        {key: 'titleCell', value: t('Name')},
        {key: 'typeCell', value: t('Type')},
        {key: 'siteCell', value: t('Site')},
        {key: 'dateCell', value: t('Date placed')},
        {key: 'actionCell', value: t('Actions')},
    ];


    const handleConfirmClick = () => {
        dispatch(deleteRequest({placementId: `${selectedPlacementId}`, queryParams: searchParams}));
        setSelectedPlacementId(null);
        setShowModal(false);
    };
    const handleCancelClick = () => {
        setSelectedPlacementId(null);
        setShowModal(false);
    };

    const handleDeletePlacementClick = (id: number, eventName, eventCategory, eventLabel) => {
        setSelectedPlacementId(id);
        setShowModal(true);
        gtag('event', eventName, getEventDimension(eventCategory, eventLabel));
    };

    let [searchParams, setSearchParams] = useSearchParams();

    const handlePaginationChange = (params: any) => {
        if (!Object.entries(params).every(([key, value]) => Number(searchParams.get(key)) === value)) {
            const newSearchParams = new URLSearchParams(searchParams);
            Object.entries(params).forEach(([key, value]) => {
                newSearchParams.set(key, value as string);
            });
            setSearchParams(newSearchParams);
        }
    };

    const handleSearchAndOrderingChange = (controlName: string) => (value: string | TDropdownOptionValue | null) => {
        if (firstInit) {
            setFirstInit(false);
            return;
        }
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);
        const searchOrderingMap = {
            [PlacementFilterEnum.SEARCH]: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.SEARCH}`,
            [PlacementFilterEnum.ORDERING]: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.SORT_BY}`,
        };
        gtag('event', searchOrderingMap[controlName], getEventDimension(GtagCategoryEnum.OFFERS, value));
    };


    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        dispatch(getRequestList({queryParams: newSearchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => () => {
        dispatch(resetPlacementToInitialState());
    }, []);

    const [tableOffers, setTableOffers] = useState<TTableRows>([]);

    useEffect(() => {
        const tableData = requestList.map((item: RequestInterface) => ({
            ...item,
            titleCell: (<LinkComponent eventCategory={GtagCategoryEnum.OFFERS}
                                       eventLabel={`${item.name} - ${item.id}`}
                                       eventName={`${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.CLICK} name`}
                                       textContent={item.name}
                                       to={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST_DEAL_LIST}`, {placementId: `${item.id}`})}/>),
            typeCell: (<span className="table__secondary">{item.placement_type_name || item.placement_type}</span>),
            siteCell: (<span className="table__secondary">{item.website_name || item.website}</span>),
            dateCell: (<span className="table__main">
                {item.placement_start_date && `from ${new Date(item.placement_start_date).toLocaleDateString()}`}
                <br/>
                {item.placement_end_date && `to ${new Date(item.placement_end_date).toLocaleDateString()}`}
            </span>),
            actionCell: (<div className="table__actions">
                <Link className="table__action"
                      onClick={gtagSendEvent(`${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.CLICK} edit`, GtagCategoryEnum.OFFERS, `${item.name} - ${item.id}`)}
                      title={t('Edit')}
                      to={generatePath(`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_EDIT}`, {placementId: `${item.id}`})}>
                    <EditIcon/>
                </Link>
                {item.is_published && <button className="table__action"
                                              onClick={() => handleDeletePlacementClick(item.id as number, `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.CLICK} delete`, GtagCategoryEnum.OFFERS, `${item.name} - ${item.id}`)}
                                              title={t('Delete')}><DeleteIcon/></button>}
            </div>),
        })) as TTableRows;
        setTableOffers(tableData);
    }, [requestList, t]);


    return (
        <>
            <main className="page page_aside-left">
                <PageTitleComponent text={t('My offers')}/>
                {!isNarrowScreen && <aside className="page__aside page__aside_sticky blank"><PlacementFilterComponent/></aside>}
                <section className="page__content page__blank">
                    {user.is_allowed_to_be_implementer && <div className="blank topBlock">
                        <div className="btnTabs">
                            <Button eventCategory={GtagCategoryEnum.OFFERS}
                                    eventLabel="publisher offers"
                                    eventName={`${GtagNameEnum.MY_OFFERS}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Publisher offers')}
                                    to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.OFFER_LIST}?status=active`}/>
                            <Button eventCategory={GtagCategoryEnum.OFFERS}
                                    eventLabel="advertiser offers"
                                    eventName={`${GtagNameEnum.MY_OFFERS}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Advertiser offers')}
                                    to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}?status=active`}/>
                        </div>
                        {!!requestsCount && <span className="topBlockTotal">{t('Offers found')}: {requestsCount}</span>}
                    </div>}
                    <div className="blank page__blank">
                        <div className="tabsBlock">
                            <TabsComponent className="tabsBlockTabs"
                                           tabs={tabs}
                                           link={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}`}
                                           paramName={PlacementFilterEnum.STATUS}/>
                            <Button eventCategory={GtagCategoryEnum.OFFERS}
                                    eventName={`${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - add offer button`}
                                    Icon={AddIcon}
                                    textContent={t('Add offer')}
                                    to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_CREATE}`}/>
                        </div>
                        <FilterSearchSortComponent filter={{component: <PlacementFilterComponent/>}}
                                                   search={{
                                                       onChange: handleSearchAndOrderingChange(PlacementFilterEnum.SEARCH),
                                                       placeholder: t('Search'),
                                                       value: searchParams.get(PlacementFilterEnum.SEARCH) || '',
                                                   }}
                                                   sort={{
                                                       onChange: handleSearchAndOrderingChange(PlacementFilterEnum.ORDERING),
                                                       options: orderingOptions,
                                                       placeholder: t('Sort by'),
                                                       value: searchParams.get(PlacementFilterEnum.ORDERING) || '',
                                                   }}/>
                        <SmartTable columns={tableColumn}
                                    texts={TableTexts}
                                    rows={tableOffers}
                                    showPendingState={isLoading}
                                    total={requestsCount}
                                    limit={Number(searchParams.get(PaginationEnum.LIMIT) || 20)}
                                    offset={Number(searchParams.get(PaginationEnum.OFFSET))}
                                    onPageChange={handlePaginationChange}
                                    showErrorState={isError}
                                    isMobile={isNarrowScreen}/>
                    </div>
                </section>
            </main>
            <ConfirmDeleteModal handleCancelClick={handleCancelClick}
                                handleConfirmClick={handleConfirmClick}
                                gtagCancel={{
                                    eventCategory: GtagCategoryEnum.OFFERS,
                                    eventLabel: `${selectedPlacementId}`,
                                    eventName: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.CLICK} no cancel delete button`,
                                }}
                                gtagConfirm={{
                                    eventCategory: GtagCategoryEnum.OFFERS,
                                    eventLabel: `${selectedPlacementId}`,
                                    eventName: `${GtagNameEnum.MY_OFFERS}${GtagNameEnum.ADVERTISER_OFFERS} - ${searchParams.get(PlacementFilterEnum.STATUS)}${GtagNameEnum.CLICK} yes delete button`,
                                }}
                                setShowModal={setShowModal}
                                showModal={showModal}/>
        </>
    );
};

export {RequestList};
export default RequestList;
