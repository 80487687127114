import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from 'components';
import {revokeApproveDeal} from '../../deals.reducer';
import {DealBtnComponentInterface} from '../../typings';


export const RevokeApprovalBtnComponent: FC<DealBtnComponentInterface> = ({className, deal, eventCategory, eventLabel, eventName, updateDataMethod}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const handleRevokeApprovalClick = () => {
        dispatch(revokeApproveDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
    };


    return <Button className={className}
                   eventCategory={eventCategory}
                   eventLabel={eventLabel}
                   eventName={eventName}
                   theme={'ghost'}
                   onClick={handleRevokeApprovalClick}
                   textContent={t('Revoke last proposal')}/>;
};
