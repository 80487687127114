import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {ReactComponent as DoneIcon} from 'assets/images/done.svg';
import {Button} from 'components';
import {CatalogRoutesPathEnum, DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {GtagCategoryEnum, GtagNameEnum} from 'typings';
import {createCatalogOfferDeal, createCatalogRequestDeal} from '../../catalog.reducer';
import placementActionsStyle from './PlacementActions.module.scss';
import {PlacementActionsComponentInterface} from './PlacementActionsComponent.interface';


export const PlacementActionsComponent: FC<PlacementActionsComponentInterface> = ({className, isAllowedToRespond, isRequest, placement}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const responsesLink = isRequest
        ? generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST_DEAL_LIST}`, {placementId: `${placement.id}`})
        : generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER_DEAL_LIST}`, {placementId: `${placement.id}`});


    const handleRespond = (isShowSuggestionForm?) => {
        isRequest ? dispatch(createCatalogRequestDeal({placementId: placement.id, isShowSuggestionForm})) : dispatch(createCatalogOfferDeal({placementId: placement.id, isShowSuggestionForm}));
    };


    const actions = {
        existingDealAction: <>
            <span className={placementActionsStyle.cardDoneIcon}><DoneIcon/></span>
            <Button theme={'stroke'}
                    size={'l'}
                    className={placementActionsStyle.cardBtn}
                    textContent={t('View Chat')}
                    to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${placement.deal_with_you_id}`})}/>
        </>,
        respondDealAction: <>
            <Button className={placementActionsStyle.cardBtn}
                    eventCategory={isRequest ? GtagCategoryEnum.ADVERTISER_OFFER : GtagCategoryEnum.WEBMASTER_OFFER}
                    eventLabel={`${placement.name} - ${placement.id}`}
                    eventName={`${isRequest ? GtagNameEnum.ADVERTISER_OFFER : GtagNameEnum.WEBMASTER_OFFER}${GtagNameEnum.CLICK} more info button`}
                    size={'l'}
                    textContent={t('More info')}
                    theme={'stroke'}
                    to={generatePath(`${MainRoutesEnum.CATALOG}${isRequest ? CatalogRoutesPathEnum.CATALOG_REQUEST : CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId: `${placement.id}`})}/>
            <Button eventCategory={isRequest ? GtagCategoryEnum.ADVERTISER_OFFER : GtagCategoryEnum.WEBMASTER_OFFER}
                    eventLabel={`${placement.name} - ${placement.id}`}
                    eventName={`${isRequest ? GtagNameEnum.ADVERTISER_OFFER : GtagNameEnum.WEBMASTER_OFFER}${GtagNameEnum.CLICK} chat button`}
                    onClick={handleRespond}
                    size={'l'}
                    textContent={t('Chat')}
                    theme={'confirm'}/>
        </>,
    };


    return (
        <div className={`controls ${!placement.is_mine && isAllowedToRespond && placement.deal_with_you_id ? '' : 'controls_align_vertical'} ${className}`}>
            {placement.is_mine
                ? <Button to={responsesLink} counter={placement.deals_count} size={'l'} textContent={t('Responses')} className={placementActionsStyle.cardBtn}/>
                : isAllowedToRespond && actions[placement.deal_with_you_id ? 'existingDealAction' : 'respondDealAction']
            }
        </div>
    );
};
