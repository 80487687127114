import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox, IDropdownOption} from '@design-system/ui-kit';
import {ReactComponent as ResetIcon} from 'assets/images/icons/reset.svg';
import {CounterComponent} from '../Counter/Counter.component';
import {LinkComponent} from '../Link/Link';
import filterChoicesStyle from './FilterChoices.module.scss';
import {FilterChoicesInterface} from './FilterChoices.interface';


export const FilterChoicesComponent: FC<FilterChoicesInterface> = ({choicesList, controlName, counter, filterLabel, filterValue, handleResetFilterClick, isFirst, onCheckboxChange}) => {
    const {t} = useTranslation();
    const lowerArrayLength = 3;
    const arrayLength = 5;
    const textLess = t('Hide');
    const textMore = t('Show more {{val}}', {val: choicesList.length - lowerArrayLength});
    const [displayedList, setDisplayedList] = useState<IDropdownOption[]>([]);
    const [filterBtnText, setFilterBtnText] = useState<string>(textMore);
    const [showAll, setShowAll] = useState<boolean>(false);


    useEffect(() => {
        setDisplayedList(choicesList?.slice(0, choicesList?.length > arrayLength ? lowerArrayLength : arrayLength));
        if (choicesList.length > arrayLength && !showAll) {
            setFilterBtnText(textMore);
        }
    }, [choicesList]);


    const handleOnClick = () => {
        if (showAll) {
            setDisplayedList(choicesList.slice(0, choicesList?.length > arrayLength ? lowerArrayLength : arrayLength));
            setFilterBtnText(textMore);
            setShowAll(false);
        } else {
            setDisplayedList(choicesList.slice());
            setFilterBtnText(textLess);
            setShowAll(true);
        }
    };


    return (
        <>
            <div className={`${filterChoicesStyle.filterChoices__header} ${isFirst ? filterChoicesStyle.filterChoices__header_first : ''}`}>
                <p className={`textBodyMedium_03 ${filterChoicesStyle.filterChoices__title}`}>{filterLabel}</p>
                {!!counter && <>
                    <CounterComponent className={filterChoicesStyle.filterChoices__counter} counter={counter}/>
                    <button className={`table__action ${filterChoicesStyle.filterChoices__resetBtn}`} onClick={handleResetFilterClick} title={t('Reset')}><ResetIcon/></button>
                </>}
            </div>
            <div className={`${filterChoicesStyle.filterChoices} ${showAll || choicesList.length <= arrayLength ? '' : filterChoicesStyle.filterChoices_truncated}`}>
                {displayedList.map((t: any) => (
                    <Checkbox onChange={onCheckboxChange(controlName, t.value)}
                              checked={filterValue[controlName]?.includes(`${t.value}`)}
                              key={t.value}>
                        {t.text}
                    </Checkbox>
                ))}
            </div>
            {choicesList.length > arrayLength && (
                <LinkComponent type="button"
                               onClick={handleOnClick}
                               textContent={filterBtnText}/>
            )}
        </>
    );
};
