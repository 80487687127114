import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import classNames from 'classnames/bind';
import {DealInterface} from 'typings';
import participantsStyle from './DealParticipants.module.scss';


export const DealParticipantsComponent: FC<{ deal: DealInterface; partnerLink: string; }> = ({deal, partnerLink}) => {
    const {t} = useTranslation();
    const executorClassName = classNames(
        'textBody_04',
        participantsStyle.participant__position,
        {[participantsStyle.participant__position_implementer]: deal.companion_info.id === deal.implementer.id},
        {[participantsStyle.participant__position_payer]: deal.companion_info.id === deal.payer.id},
    );


    return (
        <div className={participantsStyle.participant}>
            {deal.companion_info && <>
                <span className={executorClassName}>
                    {t('Executor')}:
                </span>
                <Link to={partnerLink} className={participantsStyle.participant__name}>{deal.companion_info.first_name} {deal.companion_info.last_name[0]}.</Link>
            </>}
        </div>
    );
};
