import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router-dom';
import classNames from 'classnames/bind';
import {ReactComponent as CoverageIcon} from 'assets/images/icons/coverage.svg';
import {ReactComponent as HideIcon} from 'assets/images/icons/hide.svg';
import {Button, LinkComponent, PartnerNameWebsiteTypeComponent, RepeatDealModalComponent} from 'components';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {CompanionInfoInterface, DealInterface, DealStatusEnum} from 'typings';
import dealCardStyle from './DealCard.module.scss';


export const DealCardComponent: FC<{ deal: DealInterface; hidePlacementMethod: any; }> = ({deal, hidePlacementMethod}) => {
    const {t} = useTranslation();
    const [showRepeatDealModal, setRepeatDealShowModal] = useState<boolean>(false);
    const datePriceClassName = classNames(
        dealCardStyle.dealCardDatePriceBlock,
        {[dealCardStyle.dealCardDatePriceBlock_empty]: !deal.last_comment},
        {[dealCardStyle.dealCardDatePriceBlock_common]: ![DealStatusEnum.CLOSED, DealStatusEnum.FIRST_RESPONSE].includes(deal.status) && deal.last_comment},
    );


    const handleRepeatDealClick = () => {
        setRepeatDealShowModal(true);
    };


    return (
        <>
            <div className={dealCardStyle.dealCardHeader}>
                <PartnerNameWebsiteTypeComponent isImplementer={deal.companion_info.id === deal.implementer.id}
                                                 partner={deal.companion_info}
                                                 to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_USER}`, {dealId: `${deal.id}`})}/>
                {!!(deal.companion_info as CompanionInfoInterface).coverage && <>
                    <hr className={dealCardStyle.dealCardHeaderHr}/>
                    <CoverageIcon/>
                    <span className={dealCardStyle.dealCardHeaderCoverage}>{(deal.companion_info as CompanionInfoInterface).coverage}</span>
                </>}
                <hr className={dealCardStyle.dealCardHeaderHr}/>
                <LinkComponent className={dealCardStyle.dealCardHeaderSites}
                               textContent={`${t('Active sites')}: ${(deal.companion_info as CompanionInfoInterface).active_websites_count}`}
                               to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_USER}`, {dealId: `${deal.id}`})}/>
                <Button Icon={HideIcon} iconRight onClick={hidePlacementMethod} textContent={deal.is_hidden ? t('Show offer') : t('Hide offer')} theme={'ghost_cancel'}/>
            </div>
            <div className={dealCardStyle.dealCardBody}>
                <p className={dealCardStyle.dealCardMsg}>{deal.last_comment ? deal.last_comment.text : t('The user made an offer, but did not leave comments')}</p>
                <div className={datePriceClassName}>
                    {deal.proposed_conditions.placement_start_date && deal.proposed_conditions.placement_end_date && <span className={`${dealCardStyle.dealCardDate}`}>
                        {deal.proposed_conditions.placement_start_date && <>{t('from')}&nbsp;{new Date(deal.proposed_conditions.placement_start_date).toLocaleDateString()}&ensp;</>}
                        {deal.proposed_conditions.placement_end_date && <>{t('until')}&nbsp;{new Date(deal.proposed_conditions.placement_end_date).toLocaleDateString()}</>}
                    </span>}
                    <span className={`${dealCardStyle.dealCardPrice} textBody_01`}>
                        {deal.currency}&nbsp;{Number(deal.companion_info.id === deal.payer.id ? deal.proposed_conditions.price : deal.proposed_conditions.price_with_commission).toLocaleString()}
                    </span>
                </div>
            </div>
            <div className={dealCardStyle.dealCardFooter}>
                {deal.status !== DealStatusEnum.CLOSED
                    ? <>
                        <Button textContent={t('Respond')}
                                theme={'confirm'}
                                to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}/>
                    </>
                    : <>
                        {deal.is_possible_to_repeat_deal && <>
                            <Button onClick={handleRepeatDealClick} theme={'fill'} textContent={t('Repeat')}/>
                            {showRepeatDealModal && <RepeatDealModalComponent dealId={`${deal.id}`} isImplementer={deal.companion_info.id !== deal.implementer.id}
                                                                              partnerName={`${deal.companion_info.first_name} ${deal.companion_info.last_name?.[0]}.`}
                                                                              setShowModal={setRepeatDealShowModal}
                                                                              showModal={showRepeatDealModal}/>}
                        </>}
                        <Button textContent={t('Open a chat')}
                                theme={'stroke'}
                                to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}/>
                    </>}
                <span className={dealCardStyle.dealCardLastMsg}>{t('Last message dated {{date}}', {date: new Date(deal.updated_at).toLocaleDateString()})}</span>
            </div>
        </>
    );
};
