import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {FallbackErrorTextConst, nonFieldErrorsKey} from 'consts';
import {getBalances, getDealPartnerRequest, notificationListPushItem} from 'core';
import {RequestPayloadType} from 'typings';
import {approveDealRequest, declineDealRequest, getDealCommentsRequest, getDealListRequest, getDealRequest, markAsReadyDealRequest, postDealCommentRequest, revokeApproveDealRequest} from './deal.api';
import {
    approveDeal,
    declineDeal,
    getDeal,
    getDealList,
    getDealPartner,
    hideDealsChatLoader,
    hideDealsError,
    hideDealsLoader,
    markAsReadyDeal,
    postDeal,
    revokeApproveDeal,
    setComments,
    setDeal,
    setDealFormErrors,
    setDealList,
    setDealListCount,
    setDealPartner,
    setResetDealsForm,
    showDealsChatLoader,
    showDealsError,
    showDealsLoader,
    updateChat,
} from './deals.reducer';


function* approveDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(approveDealRequest, requestParams);
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
        yield put(getBalances());
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: error.hash_sum || FallbackErrorTextConst,
            description: error[nonFieldErrorsKey],
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideDealsLoader());
    }
}

function* declineDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(declineDealRequest, requestParams);
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
        yield put(getBalances());
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error.detail || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideDealsLoader());
    }
}

function* getDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
        };
        const dataChat: any = yield call(getDealCommentsRequest, requestParams);
        const dataDeal: any = yield call(getDealRequest, requestParams);
        yield put(setDeal(dataDeal));
        yield put(setComments(dataChat));
    } catch (error) {
        yield put(showDealsError());
    } finally {
        yield put(hideDealsLoader());
    }
}

function* getDealListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data = yield call(getDealListRequest, requestParams);
        if (data) {
            yield put(setDealList(data.results));
            yield put(setDealListCount(data.count));
            yield put(hideDealsLoader());
        }
    } catch (e) {
        yield put(showDealsError());
        yield put(hideDealsLoader());
    }
}

function* getDealPartnerWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {partnerId: payload.payload.partnerId},
        };
        const data: any = yield call(getDealPartnerRequest, requestParams);
        yield put(setDealPartner(data));
    } catch (error) {
        yield put(showDealsError());
    } finally {
        yield put(hideDealsLoader());
    }
}

function* createDealCommentWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsChatLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
            routeParams: {dealId: payload.payload.dealId},
        };
        const requestParamsForGetComments: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(postDealCommentRequest, requestParams);
        const dataChat: any = yield call(getDealCommentsRequest, requestParamsForGetComments);
        const dataDeal: any = yield call(getDealRequest, requestParamsForGetComments);
        yield put(setDeal(dataDeal));
        yield put(setComments(dataChat));
        yield put(setResetDealsForm(true));
        yield put(getBalances());
    } catch (error) {
        // yield put(showDealsError());
        yield put(setDealFormErrors(error));
    } finally {
        yield put(hideDealsChatLoader());
    }
}

function* markAsReadyDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(markAsReadyDealRequest, requestParams);
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
        yield put(getBalances());
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error.hash_sum || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideDealsLoader());
    }
}

function* revokeApproveDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        yield put(showDealsLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
            routeParams: {dealId: payload.payload.dealId},
        };
        yield call(revokeApproveDealRequest, requestParams);
        if (payload.payload.updateDataMethod) {
            yield put(payload.payload.updateDataMethod);
        }
        yield put(getBalances());
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error.hash_sum || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideDealsLoader());
    }
}

function* updateChatWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideDealsError());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId},
        };
        const dataChat: any = yield call(getDealCommentsRequest, requestParams);
        const dataDeal: any = yield call(getDealRequest, requestParams);
        yield put(setDeal(dataDeal));
        yield put(setComments(dataChat));
    } catch (error) {
        yield put(showDealsError());
    }
}


export function* dealsWatcher() {
    yield takeEvery(approveDeal.type, approveDealWorker);
    yield takeEvery(declineDeal.type, declineDealWorker);
    yield takeEvery(getDeal.type, getDealWorker);
    yield takeEvery(getDealList.type, getDealListWorker);
    yield takeEvery(getDealPartner.type, getDealPartnerWorker);
    yield takeEvery(postDeal.type, createDealCommentWorker);
    yield takeEvery(markAsReadyDeal.type, markAsReadyDealWorker);
    yield takeEvery(revokeApproveDeal.type, revokeApproveDealWorker);
    yield takeEvery(updateChat.type, updateChatWorker);
}
