import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {UserRoleEnum} from 'typings';
import placementInfoBoxStyle from './PlacementInfoBox.module.scss';
import {PlacementInfoDlComponent} from './PlacementInfoDL.component';
import {PlacementInfoBoxComponentInterface} from './PlacementInfoBoxComponent.interface';


export const PlacementInfoBoxComponent: FC<PlacementInfoBoxComponentInterface> = (props) => {
    const {t} = useTranslation();
    const {logo, name, proposerRole, compactView} = props;


    return (
        <div className={`${placementInfoBoxStyle.infoBox} ${compactView ? placementInfoBoxStyle.infoBox_compact : ''}`}>
            {!!proposerRole
                ? <div className={`textBody_02 ${placementInfoBoxStyle.infoBoxHeader}`}>
                    {t('Last offer by')}:&ensp;
                    <span className={proposerRole === UserRoleEnum.IMPLEMENTER ? placementInfoBoxStyle.infoBoxHeader_implementer : placementInfoBoxStyle.infoBoxHeader_payer}>
                        {proposerRole === UserRoleEnum.IMPLEMENTER ? t('Publisher') : t('Advertiser')}
                    </span>
                </div>
                : <div className={`textBodyMedium_02 ${placementInfoBoxStyle.infoBoxTitle} ${compactView ? '' : placementInfoBoxStyle.infoBoxTitle_extended}`}>
                    {name}
                    {!!logo && <img src={logo} alt="" width={24} height={24}/>}
                </div>}
            <PlacementInfoDlComponent {...props} {...compactView}/>
        </div>
    );
};
