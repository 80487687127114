import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import successIcon from 'assets/images/success.svg';
import placementInfoBoxStyle from './PlacementInfoBox.module.scss';
import {PlacementInfoBoxComponentInterface} from './PlacementInfoBoxComponent.interface';


export const PlacementInfoDlComponent: FC<PlacementInfoBoxComponentInterface> = (props) => {
    const {t} = useTranslation();
    const {startDate, endDate, price, currency, daysToImplement, approvedAt, closedAt, isUpdatedPrice, isUpdatedDate, compactView, priceInclFee} = props;
    const updatedLabelEl = <span className={placementInfoBoxStyle.infoBoxUpdated}>(Updated)</span>;


    return (
        <>
            <dl className={placementInfoBoxStyle.infoBoxDl}>
                <dt className={`textBody_04 ${placementInfoBoxStyle.infoBoxDt}`}>{priceInclFee ? t('Offer price (incl. fee)') : t('Offer price (ex fee)')}{isUpdatedPrice && updatedLabelEl}</dt>
                <dd className={`${compactView ? '' : 'textBody_02'} ${placementInfoBoxStyle.infoBoxDd} ${placementInfoBoxStyle.infoBoxDd_em}`}>{Number(price).toLocaleString()}&nbsp;{currency}</dd>
                {/*{!!daysToImplement && <>*/}
                {/*    <dt className={`textBody_04 ${placementInfoBoxStyle.infoBoxDt}`}>{t('Placement approval period, days')}</dt>*/}
                {/*    <dd className={`${compactView ? '' : 'textBody_02'} ${placementInfoBoxStyle.infoBoxDd}`}>{daysToImplement}</dd>*/}
                {/*</>}*/}
                {(startDate || endDate) && <>
                    <dt className={`textBody_04 ${placementInfoBoxStyle.infoBoxDt}`}>{t('Date')}{isUpdatedDate && updatedLabelEl}</dt>
                    <dd className={`${compactView ? '' : 'textBody_02'} ${placementInfoBoxStyle.infoBoxDd}`}>
                        {startDate && `from ${new Date(startDate).toLocaleDateString()} `}
                        {endDate && `to ${new Date(endDate).toLocaleDateString()}`}
                    </dd>
                </>}
                {!!approvedAt && <>
                    <dt className={`textBody_04 ${placementInfoBoxStyle.infoBoxDt}`}>{t('Deal made')}:</dt>
                    <dd className={`${compactView ? '' : 'textBody_02'} ${placementInfoBoxStyle.infoBoxDd}`}>
                        {new Date(approvedAt).toLocaleDateString()}
                        {!closedAt && <img className={placementInfoBoxStyle.infoBoxIcon} src={successIcon} alt=""/>}
                    </dd>
                </>}
            </dl>
            {!!closedAt && <dl className={placementInfoBoxStyle.infoBoxDLFooter}>
                <dt className={`textBody_04 ${placementInfoBoxStyle.infoBoxDt} ${placementInfoBoxStyle.infoBoxFooterDt}`}>{t('Deal closed')}:</dt>
                <dd className={`${compactView ? '' : 'textBody_02'} ${placementInfoBoxStyle.infoBoxDd} ${placementInfoBoxStyle.infoBoxFooterDd}`}>
                    {new Date(closedAt).toLocaleDateString()}
                    <img className={placementInfoBoxStyle.infoBoxIcon} src={successIcon} alt=""/>
                </dd>
            </dl>}
        </>
    );
};
