import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from '@design-system/ui-kit';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {UserInterface, UserRoleEnum} from 'typings';
import {Step1Component, Step1FormComponent, Step2Component, Step3Component, WelcomeComponent, WelcomeUserComponent} from './components';
import {resetOnboardingToInitialState, setCurrentStep, setRole} from './onboarding.reducer';
import {StepEnum} from './typings';


const Onboarding: FC<{ setShowOnboardingModal: Dispatch<SetStateAction<boolean>>; showOnboardingModal: boolean; }> = ({setShowOnboardingModal, showOnboardingModal}) => {
    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {closeOnboarding, currentStep} = useSelector((state: RootState) => state.onboardingReducer);

    const stepMap = {
        [StepEnum.WELCOME]: <WelcomeComponent/>,
        [StepEnum.WELCOME_USER]: <WelcomeUserComponent/>,
        [StepEnum.STEP_1]: <Step1Component/>,
        [StepEnum.STEP_1_FORM]: <Step1FormComponent/>,
        [StepEnum.STEP_2]: <Step2Component/>,
        [StepEnum.STEP_3]: <Step3Component/>,
    };


    useEffect(() => () => {
        dispatch(resetOnboardingToInitialState());
    }, [dispatch]);

    useEffect(() => {
        if (closeOnboarding) {
            setShowOnboardingModal(false);
        }
    }, [closeOnboarding]);

    useEffect(() => {
        if (user.is_allowed_to_be_implementer === user.is_allowed_to_be_payer) {
            dispatch(setCurrentStep(StepEnum.WELCOME));
        } else {
            dispatch(setCurrentStep(StepEnum.WELCOME_USER));
            dispatch(setRole(user.is_allowed_to_be_implementer ? UserRoleEnum.IMPLEMENTER : UserRoleEnum.PAYER));
        }
    }, [user]);


    return (
        <Modal active={showOnboardingModal}
               setActive={setShowOnboardingModal}
               fixed
               closable={false}
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            {currentStep && stepMap[currentStep]}
        </Modal>
    );
};

export {Onboarding};
export default Onboarding;
