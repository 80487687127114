import {RouteObject} from 'react-router-dom';
import {PageNotFound} from 'core/PageNotFound/PageNotFound';
import {PlacementRoutesPathEnum} from 'core/Routes/typings/enums/PlacementRoutesPath.enum';
import {OfferCreate, OfferList, RequestCreate, RequestList} from '../pages';


export const placementsRoutes: RouteObject[] = [
    // {element: <OfferCreate/>, index: true},
    {element: <OfferCreate/>, path: `${PlacementRoutesPathEnum.OFFER_CREATE}`},
    {element: <OfferCreate/>, path: `${PlacementRoutesPathEnum.OFFER_EDIT}`},
    {element: <OfferList/>, path: `${PlacementRoutesPathEnum.OFFER_LIST}`},
    {element: <RequestCreate/>, path: `${PlacementRoutesPathEnum.REQUEST_CREATE}`},
    {element: <RequestCreate/>, path: `${PlacementRoutesPathEnum.REQUEST_EDIT}`},
    {element: <RequestList/>, path: `${PlacementRoutesPathEnum.REQUEST_LIST}`},
    {element: <PageNotFound/>, path: '/*'},
];
