import React, {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'components';
import {markAsReadyDeal} from '../../deals.reducer';
import {DealBtnComponentInterface} from '../../typings';
import {AcceptDealModal} from './AcceptDealModal.component';


export const JobIsDoneBtnComponent: FC<DealBtnComponentInterface> = ({className, deal, eventCategory, eventLabel, eventName, textContent, showConfirmDialog, updateDataMethod}) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);


    const handleDoneClick = () => {
        if (showConfirmDialog) {
            setShowModal(true);
        } else {
            dispatch(markAsReadyDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
        }
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleConfirmClick = () => {
        dispatch(markAsReadyDeal({dealId: `${deal?.id}`, data: {hash_sum: deal?.hash_sum}, updateDataMethod}));
        setShowModal(false);
    };


    return (
        <>
            <Button className={className}
                    eventCategory={eventCategory}
                    eventLabel={eventLabel}
                    eventName={eventName}
                    onClick={handleDoneClick}
                    textContent={textContent}
                    theme={'confirm'}
                    type="button"/>
            <AcceptDealModal handleCancelClick={handleCancelClick} handleConfirmClick={handleConfirmClick} setShowModal={setShowModal} showModal={showModal}/>
        </>
    );
};
