import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CategoriesIcon} from 'assets/images/icons/categories.svg';
import {ReactComponent as GeosIcon} from 'assets/images/icons/geos.svg';
import {ReactComponent as LinkIcon} from 'assets/images/icons/link.svg';
import {ReactComponent as TypeIcon} from 'assets/images/icons/type.svg';
import {ReactComponent as UrlIcon} from 'assets/images/icons/url.svg';
import {useCommonSelector} from 'hooks';
import {LinkComponent} from '../../Link/Link';
import {CardDescriptionListComponentInterface} from './CardDescriptionListComponent.interface';
import cardDescriptionListStyle from './CardDescriptionList.module.scss';


export const CardDescriptionListComponent: FC<CardDescriptionListComponentInterface> = ({categoryList, geoList, placementUrl, website}) => {
    const {t} = useTranslation();
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');


    return (
        <dl className={cardDescriptionListStyle.dl}>
            {website && <>
                <dt className={cardDescriptionListStyle.dt}>
                    <UrlIcon className={cardDescriptionListStyle.dtIcon}/>
                    {t('URL')}
                </dt>
                <dd className={cardDescriptionListStyle.dd}><LinkComponent className="word-break" href={website.url} textContent={website.url} target="_blank"/></dd>
                <dt className={cardDescriptionListStyle.dt}>
                    <TypeIcon className={cardDescriptionListStyle.dtIcon}/>
                    {t('Type')}
                </dt>
                <dd className={cardDescriptionListStyle.dd}><b>{websiteTypesMap[website.type].text}</b>{!!website.subtype && <>, {websiteSubTypesMap[website.subtype].text}</>}</dd>
            </>}
            {placementUrl && <>
                <dt className={cardDescriptionListStyle.dt}>
                    <LinkIcon className={cardDescriptionListStyle.dtIcon}/>
                    {t('Placement URL')}
                </dt>
                <dd className={cardDescriptionListStyle.dd}><LinkComponent className="word-break" href={placementUrl} textContent={placementUrl}/></dd>
            </>}
            <dt className={cardDescriptionListStyle.dt}>
                <GeosIcon className={cardDescriptionListStyle.dtIcon}/>
                {t('Regions')}
            </dt>
            <dd className={cardDescriptionListStyle.dd}>
                {!!geoList.length ? <>{geoList.join(', ')}</> : <>{t('Worldwide')}</>}
            </dd>
            {!!categoryList.length && <>
                <dt className={cardDescriptionListStyle.dt}>
                    <CategoriesIcon className={cardDescriptionListStyle.dtIcon}/>
                    {t('Categories')}
                </dt>
                <dd className={cardDescriptionListStyle.dd}>{categoryList.join(', ')}</dd>
            </>}
        </dl>
    );
};

