import {combineReducers} from '@reduxjs/toolkit';
import {catalogReducer, dealsReducer, financeReducer, onboardingReducer, placementReducer, websitesReducer} from 'modules';
import {commonReducer} from '../common';
import {notificationListReducer} from '../NotificationList';


export const rootReducer = combineReducers({
    catalogReducer,
    commonReducer,
    dealsReducer,
    financeReducer,
    notificationListReducer,
    onboardingReducer,
    placementReducer,
    websitesReducer,
});
