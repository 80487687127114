import React, {FC} from 'react';
import placementNavStyle from './PlacementNav.module.scss';


export const PlacementNavComponent: FC<{ className: string, anchorList: { href: string; text: string; }[]; }> = ({className, anchorList}) => {
    return (
        <aside className={`blank ${className} ${placementNavStyle.placementNav}`}>
            {anchorList.map(anchor =>
                <a key={anchor.href}
                   href={anchor.href}
                   className={`textBodyMedium_02 ${placementNavStyle.placementNav__link}`}>
                    {anchor.text}
                </a>,
            )}
        </aside>
    );
};
