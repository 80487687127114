import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useSearchParams} from 'react-router-dom';
import {IDropdownOption, Pagination, TDropdownOptionValue} from '@design-system/ui-kit';
import {Button, EmptyComponent, FilterSearchSortComponent, LoaderWithOverlay, PageTitleComponent} from 'components';
import {TableTexts} from 'consts';
import {CatalogRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, PaginationEnum} from 'typings';
import {getEventDimension} from 'utils';
import {CatalogFilterComponent, PlacementCardComponent} from '../../componets';
import {CatalogFilterEnum} from '../../typings';
import {getCatalogOfferList, resetCatalogToInitialState} from '../../catalog.reducer';


const CatalogOfferList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isLoading, isError, placementCount, placementList} = useSelector((state: RootState) => state.catalogReducer);
    const isNarrowScreen = useMedia();

    const orderingOptions: IDropdownOption[] = [
        {value: 'name', text: t('Name (A–Z)')},
        {value: '-name', text: t('Name (Z–A)')},
        {value: 'placement_start_date', text: t('Placement date (oldest first)')},
        {value: '-placement_start_date', text: t('Placement date (newest first)')},
        {value: 'price', text: t('Price (lowest first)')},
        {value: '-price', text: t('Price (highest first)')},
        {value: 'coverage', text: t('Coverage (ascending)')},
        {value: '-coverage', text: t('Coverage (descending)')},
    ];

    const [searchParams, setSearchParams] = useSearchParams();

    const [firstInit, setFirstInit] = useState<boolean>(true);


    const handlePaginationChange = (params: any) => {
        const limit = params.itemsPerPage || searchParams.get(PaginationEnum.LIMIT) || 20;
        const offset = `${limit * (params.pageNumber - 1)}`;
        if (searchParams.get(PaginationEnum.OFFSET) !== offset || Number(searchParams.get(PaginationEnum.OFFSET)) !== params.itemsPerPage) {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(PaginationEnum.LIMIT, limit);
            newSearchParams.set(PaginationEnum.OFFSET, offset);
            setSearchParams(newSearchParams);
        }
    };

    const handleSearchAndOrderingChange = (controlName: string) => (value: string | TDropdownOptionValue | null) => {
        if (firstInit) {
            setFirstInit(false);
            return;
        }
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);

        const searchOrderingMap = {
            [CatalogFilterEnum.ORDERING]: `${GtagNameEnum.CATALOG}${GtagNameEnum.WEBMASTER_OFFERS}${GtagNameEnum.SORT_BY}`,
            [CatalogFilterEnum.SEARCH]: `${GtagNameEnum.CATALOG}${GtagNameEnum.WEBMASTER_OFFERS}${GtagNameEnum.SEARCH}`,
        };
        gtag('event', searchOrderingMap[controlName], getEventDimension(GtagCategoryEnum.CATALOG, value));
    };


    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        window.scrollTo({top: 0});
        dispatch(getCatalogOfferList({queryParams: newSearchParams, controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams]);

    useEffect(() => () => {
        dispatch(resetCatalogToInitialState());
    }, [dispatch]);


    return (
        <>
            <main className="page page_aside-left">
                <PageTitleComponent text={t('Catalog')}/>

                {!isNarrowScreen && <aside className="page__aside page__aside_sticky blank"><CatalogFilterComponent/></aside>}

                <section className="page__content page__blank">
                    <div className="blank topBlock">
                        <div className="btnTabs">
                            <Button eventCategory={GtagCategoryEnum.CATALOG}
                                    eventLabel="Publisher offers"
                                    eventName={`${GtagNameEnum.CATALOG}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Publisher offers')}
                                    to={`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER_LIST}?status=active`}/>
                            <Button eventCategory={GtagCategoryEnum.CATALOG}
                                    eventLabel="Advertiser offers"
                                    eventName={`${GtagNameEnum.CATALOG}${GtagNameEnum.TYPE_OFFERS}`}
                                    isNavLink
                                    textContent={t('Advertiser offers')}
                                    to={`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_REQUEST_LIST}?status=active`}/>
                        </div>
                        <span className="topBlockTotal">{t('Offers found')}: {placementCount}</span>
                    </div>
                    <FilterSearchSortComponent filter={{component: <CatalogFilterComponent/>}}
                                               isSeparate
                                               search={{
                                                   onChange: handleSearchAndOrderingChange(CatalogFilterEnum.SEARCH),
                                                   placeholder: t('Search'),
                                                   value: searchParams.get(CatalogFilterEnum.SEARCH) || '',
                                               }}
                                               sort={{
                                                   onChange: handleSearchAndOrderingChange(CatalogFilterEnum.ORDERING),
                                                   options: orderingOptions,
                                                   placeholder: t('Sort by'),
                                                   value: searchParams.get(CatalogFilterEnum.ORDERING) || '',
                                               }}/>
                    <div className="loader__container">
                        {!isLoading && !placementList.length && !isError && <EmptyComponent title={TableTexts.noRowsPlaceholder}/>}
                        {isError && !placementList.length && <p><b>{TableTexts.responseValidationFailedTitle}</b><br/>{TableTexts.responseValidationFailedDescription}</p>}
                        {placementList.map(placement => <PlacementCardComponent key={placement.id}
                                                                                partnerLink={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_USER_INFO}`, {placementId: `${placement.id}`})}
                                                                                placement={placement}
                                                                                placementLink={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId: `${placement.id}`})}/>)}
                        {isLoading && <LoaderWithOverlay/>}
                    </div>
                    {(Number(placementCount) > 20) && <Pagination itemsCount={placementCount || 0}
                                                                  itemsPerPage={Number(searchParams.get(PaginationEnum.LIMIT)) || 20}
                                                                  pageNumber={Number(searchParams.get(PaginationEnum.OFFSET) || 0) / Number(searchParams.get(PaginationEnum.LIMIT) || 20) + 1}
                                                                  onChange={handlePaginationChange}
                                                                  hidePerPageDropdown={isNarrowScreen}
                                                                  hideElementsCounter={isNarrowScreen}
                                                                  perPageDropdownOptions={[20, 50, 100]}
                                                                  texts={TableTexts}/>}
                </section>
            </main>
        </>
    );
};

export {CatalogOfferList};
export default CatalogOfferList;
