import {OfferInterface, RequestInterface, RequestPayloadType} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';
import {OfferListResponseInterface, PlacementFormErrorsType, RequestListResponseInterface} from './typings';


// PLACEMENT API

export const getOfferListRequest = async (payload: RequestPayloadType<any>): Promise<OfferListResponseInterface | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('placement/'), payload);
    return await sendRequest<OfferListResponseInterface>(sendRequestParams);
};

export const postOfferRequest = async (payload: RequestPayloadType<any>): Promise<null | PlacementFormErrorsType> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('placement/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const patchOfferRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null | PlacementFormErrorsType> => {
    payload.method = 'PATCH';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement/${payload.routeParams.id}/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const deleteOfferRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null> => {
    payload.method = 'DELETE';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

// PLACEMENT_REQUEST API

export const getRequestListRequest = async (payload: RequestPayloadType<any>): Promise<RequestListResponseInterface | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('placement_request/'), payload);
    return await sendRequest<RequestListResponseInterface>(sendRequestParams);
};

export const postRequestRequest = async (payload: RequestPayloadType<any>): Promise<null | PlacementFormErrorsType> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('placement_request/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const patchRequestRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null | PlacementFormErrorsType> => {
    payload.method = 'PATCH';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement_request/${payload.routeParams.id}/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const deleteRequestRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<null> => {
    payload.method = 'DELETE';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement_request/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};
