import React, {FC, useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';
import {EThemeProviderThemes, ThemeProvider} from '@design-system/ui-kit';
import {FooterComponent, GtagComponent, HeaderComponent, MenuComponent} from 'components';
import {ModalContentIdConst} from 'consts';
import {useCommonSelector} from 'hooks';
import {Onboarding} from 'modules';
import {AppConfigInterface, UserInterface} from 'typings';
import {NotificationsList} from '../NotificationList';
import {AppRouter} from '../Routes';
import appStyle from './App.module.scss';


const App: FC = () => {
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false);


    useEffect(() => {
        if (user.id === null) {
            window.location.href = '/account/login/';
        }
        if (user.id && !user.is_passed_onboarding) {
            setShowOnboardingModal(true);
        }
    }, [user]);

    useEffect(() => {
        if (!appConfig?.project_name) return;
        document.title = appConfig.project_name.replace(/^./, str => str.toUpperCase());
    }, [appConfig]);


    return (
        <ThemeProvider theme={EThemeProviderThemes.convertSocialLight}>
            {!!user.id ? <>
                <div className={appStyle.page}>
                    <HeaderComponent/>
                    <MenuComponent/>
                    <div className={`${appStyle.pageContent} loader__container`}>
                        <AppRouter/>
                    </div>
                    <FooterComponent/>
                    <Onboarding showOnboardingModal={showOnboardingModal} setShowOnboardingModal={setShowOnboardingModal}/>
                </div>
                <NotificationsList/>
                <div id={ModalContentIdConst}/>
            </> : <div className="initialPageLoader"/>}
            <GtagComponent user={user}/>
        </ThemeProvider>
    );
};


export default Sentry.withProfiler(App);
