import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {websitesRoutes} from './constants';


const Websites: FC = () => {
    return useRoutes(websitesRoutes);
};

export {Websites};
export default Websites;
