import {
    AppConfigInterface,
    BalanceInterface,
    CodeNameInterface,
    DealEventsInterface,
    IdNameInterface,
    OfferInterface,
    RequestInterface,
    RequestPayloadType,
    UserInterface,
    WebsiteFormErrorsType,
    WebsitesResponseInterface,
    WebsiteTypeInterface,
} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';


export const getGeoRequest = async (): Promise<CodeNameInterface[]> => {
    const sendRequestParams = createSendRequestParams(getApiRoute('geo/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getUserRequest = async (): Promise<UserInterface | undefined> => {
    const sendRequestParams = createSendRequestParams(getApiRoute('me/'));
    return await sendRequest<UserInterface>(sendRequestParams);
};

export const getWebsiteTypesRequest = async (): Promise<WebsiteTypeInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('website_types/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getCategoriesRequest = async (): Promise<IdNameInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('categories/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getPlacementTypesRequest = async (): Promise<IdNameInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('placement_types/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getCurrenciesRequest = async (): Promise<CodeNameInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('currency/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getAppConfigRequest = async (): Promise<AppConfigInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('app_config/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getDealPartnerRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`users/${payload.routeParams.partnerId}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getMyBalanceRequest = async (): Promise<BalanceInterface | undefined> => {
    const sendRequestParams = createSendRequestParams(getApiRoute('my_balance/'));
    return await sendRequest<BalanceInterface>(sendRequestParams);
};

export const getFullSiteListRequest = async (payload: RequestPayloadType<any>): Promise<WebsitesResponseInterface | undefined> => {
    const queryParams: RequestPayloadType<any, any> = {
        queryParams: {limit: 100},
    };
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('websites/'), queryParams);
    return await sendRequest<WebsitesResponseInterface>(sendRequestParams);
};

export const postWebsiteRequest = async (payload: RequestPayloadType<any>): Promise<null | WebsiteFormErrorsType> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('websites/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getDealEventsRequest = async (): Promise<DealEventsInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('user_deals_events/'));
    return await sendRequest<any>(sendRequestParams);
};

export const postMarkAllEventsAsReadRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('user_deals_events/mark_all_as_read/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const postMarkDealEventsAsReadRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`user_deals_events/${payload.routeParams.dealId}/mark_deal_events_as_read/ `), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getOfferRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<OfferInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getRequestRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<RequestInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`placement_request/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const repeatDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`my_deals/${payload.routeParams.dealId}/repeat_deal/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};
