import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useParams, useSearchParams} from 'react-router-dom';
import {Button, EmptyComponent, LoaderWithOverlay, PageTitleComponent, PlacementInfoBoxComponent, TabsComponent, WebsiteNameTypeComponent} from 'components';
import {CatalogRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {useMedia} from 'hooks';
import {TabInterface} from 'typings';
import {DealCardComponent} from '../../componets';
import {changeVisibilityOfferDeal, getCatalogOfferDealList, getOffer, resetCatalogToInitialState} from '../../catalog.reducer';
import placementPageStyle from '../../styles/PlacementPage.module.scss';


const OfferDealList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {placementId} = useParams();
    const isMobile = useMedia();
    const {isLoading, dealList, placement} = useSelector((state: RootState) => state.catalogReducer);
    const [searchParams, setSearchParams] = useSearchParams();
    const [controller, setController] = useState<AbortController>(new AbortController());


    const tabs: Omit<TabInterface, 'linkValue'>[] = [
        {linkText: t('Responses'), paramValue: ''},
        {linkText: t('Offers only'), paramValue: 'false'},
        {linkText: t('Hidden offers'), paramValue: 'true'},
    ];

    const handleChangeVisibilityClick = (dealId, isHidden, placementId) => () => {
        dispatch(changeVisibilityOfferDeal({controller, dealId, isHidden, placementId, queryParams: searchParams}));
    };


    useEffect(() => {
        setController(new AbortController());
        if (!placementId) return;
        dispatch(getCatalogOfferDealList({controller, placementId, queryParams: searchParams}));
        dispatch(getOffer({placementId}));
        return () => {
            controller.abort();
        };
    }, [dispatch, placementId, searchParams]);

    useEffect(() => () => {
        dispatch(resetCatalogToInitialState());
    }, [dispatch]);


    return (
        <main className="page page_aside-left">
            <PageTitleComponent text={t('Publisher offer')}/>

            {!isMobile && <div className="blank page__aside page__aside_sticky page__content">
                {placement && <>
                    <PlacementInfoBoxComponent name={placement.placement_type_name}
                                               logo={placement.placement_type_logo}
                                               currency={placement.currency}
                                               daysToImplement={placement.days_to_implement}
                                               startDate={placement.placement_start_date}
                                               endDate={placement.placement_end_date}
                                               price={placement.price}
                                               priceInclFee={false}/>
                    <div className="controls controls_align_vertical">
                        <Button to={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId})}
                                theme={'stroke'}
                                textContent={t('Placement details')}/>
                    </div>
                </>}
            </div>}

            {placement && <>
                <div className="page__content page__blank">
                    <div className={`blank`}>
                        <h2 className={`textHeaderMedium_04 ${placementPageStyle.placementPageTitle}`}>{placement.name}</h2>
                        {placement.serialized_website && <WebsiteNameTypeComponent website={placement.serialized_website}/>}
                        <p className={placementPageStyle.placementPageDescription}>{placement.description}</p>
                        {isMobile && <>
                            <PlacementInfoBoxComponent name={placement.placement_type_name}
                                                       logo={placement.placement_type_logo}
                                                       currency={placement.currency}
                                                       daysToImplement={placement.days_to_implement}
                                                       startDate={placement.placement_start_date}
                                                       endDate={placement.placement_end_date}
                                                       price={placement.price}
                                                       priceInclFee={false}/>
                            <div className="controls controls_align_vertical">
                                <Button to={generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER}${CatalogRoutesPathEnum.CATALOG_PLACEMENT_INFO}`, {placementId})}
                                        theme={'stroke'}
                                        textContent={t('Placement details')}/>
                            </div>
                        </>}
                    </div>
                    <div className={`blank`}>
                        <h2 className={`textHeaderMedium_04 ${placementPageStyle.placementPageTitle}`}>{t('Responses to your placement')}</h2>
                        <div className="tabsBlock">
                            <TabsComponent className="tabsBlockTabs"
                                           defaultParamValue=""
                                           link={`${generatePath(`${MainRoutesEnum.CATALOG}${CatalogRoutesPathEnum.CATALOG_OFFER_DEAL_LIST}`, {placementId: `${placement.id}`})}`}
                                           paramName={'is_hidden'}
                                           tabs={tabs}/>
                        </div>
                        {placementId && dealList.map(deal => <DealCardComponent key={deal.id} deal={deal} hidePlacementMethod={handleChangeVisibilityClick(deal.id, !deal.is_hidden, placementId)}/>)}
                        {(placementId && !dealList.length) && <EmptyComponent/>}
                    </div>
                </div>
            </>}

            {isLoading && <LoaderWithOverlay/>}

        </main>
    );
};

export {OfferDealList};
export default OfferDealList;
