import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, Link} from 'react-router-dom';
import classNames from 'classnames/bind';
import {Button, PlacementInfoBoxComponent} from 'components';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {useMedia} from 'hooks';
import {DealInterface, DealStatusEnum, GtagCategoryEnum, GtagNameEnum, OfferInterface, RequestInterface} from 'typings';
import {gtagSendEvent} from 'utils';
import {getDealList} from '../../deals.reducer';
import {JobIsDoneBtnComponent} from '../btnJobIsDone/JobIsDoneBtn.component';
import {DealParticipantsComponent} from '../participants/DealParticipants.component';
import {DealLastSentenceComponent} from '../lastSentence/DealLastSentence.component';
import {DealFilterEnum} from '../../typings';
import {DealLastOfferComponent} from '../lastOffer/DealLastOffer.component';
import dealCardStyle from './DealCard.module.scss';


export const DealCardComponent: FC<{ deal: DealInterface<OfferInterface | RequestInterface>; searchParams: URLSearchParams; }> = ({deal, searchParams}) => {
    const {t} = useTranslation();
    const isMobile = useMedia('(max-width: 768)');
    const dealClassName = classNames(
        dealCardStyle.dealCard,
        {[dealCardStyle.dealCard_state_open]: [DealStatusEnum.FIRST_RESPONSE, DealStatusEnum.CORRECTION_REQUESTED].includes(deal.status)},
        {[dealCardStyle.dealCard_state_inProgress]: [DealStatusEnum.DONE, DealStatusEnum.IN_PROGRESS].includes(deal.status)},
        {[dealCardStyle.dealCard_state_completed]: [DealStatusEnum.CLOSED, DealStatusEnum.DECLINED].includes(deal.status)},
    );


    return (
        <article className={dealClassName}>
            <div className={dealCardStyle.dealCardAside}>
                <PlacementInfoBoxComponent startDate={deal.proposed_conditions.placement_start_date}
                                           endDate={deal.proposed_conditions.placement_end_date}
                                           name={(deal.placement || deal.placement_request).placement_type_name}
                                           logo={(deal.placement || deal.placement_request).placement_type_logo}
                                           price={deal.companion_info.id === deal.payer.id ? deal.proposed_conditions.price : deal.proposed_conditions.price_with_commission}
                                           priceInclFee={deal.companion_info.id !== deal.payer.id}
                                           currency={deal.currency}
                                           daysToImplement={(deal.placement || deal.placement_request).days_to_implement}
                                           isUpdatedPrice={deal.proposed_conditions.price !== (deal.placement || deal.placement_request).price}
                                           isUpdatedDate={deal.proposed_conditions.placement_start_date !== (deal.placement || deal.placement_request).placement_start_date || deal.proposed_conditions.placement_end_date !== (deal.placement || deal.placement_request).placement_end_date}
                                           compactView/>
            </div>
            <div className={dealCardStyle.dealCardContent}>

                <h2 className="textHeaderMedium_06">
                    <Link className={dealCardStyle.dealCardNameLink}
                          onClick={gtagSendEvent(`${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)}${GtagNameEnum.CLICK} name`, GtagCategoryEnum.DEALS, `${deal.name} - ${deal.id}`)}
                          to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}>
                        {(deal.placement || deal.placement_request).name}
                    </Link>
                </h2>
                <DealParticipantsComponent deal={deal} partnerLink={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_USER}`, {dealId: `${deal.id}`})}/>
                <DealLastSentenceComponent deal={deal} myRole={`${searchParams.get(DealFilterEnum.MY_ROLE)}`} state={`${searchParams.get(DealFilterEnum.STATE)}`}/>
                <div className={dealCardStyle.dealCardActions}>
                    <div className={`controls ${isMobile && 'controls_align_vertical'}`}>
                        {deal.is_possible_to_mark_as_ready && <JobIsDoneBtnComponent deal={deal}
                                                                                     eventCategory={GtagCategoryEnum.DEALS}
                                                                                     eventLabel={`${deal.name} - ${deal.id}`}
                                                                                     eventName={`${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)} -  job is done button`}
                                                                                     showConfirmDialog={deal.companion_info.id === deal.implementer.id}
                                                                                     textContent={deal?.status === DealStatusEnum.DONE ? t('Accept work') : t('Work complete')}
                                                                                     updateDataMethod={getDealList({queryParams: searchParams, controller: new AbortController()})}/>}
                        {deal.is_possible_to_create_change_request && <Button eventCategory={GtagCategoryEnum.DEALS}
                                                                              eventLabel={`${deal.name} - ${deal.id}`}
                                                                              eventName={`${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)} - add own offer button`}
                                                                              state={{isShowSuggestionForm: true}}
                                                                              textContent={t('+ Propose my terms')}
                                                                              theme={'confirm'}
                                                                              to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}/>}
                        <Button eventCategory={GtagCategoryEnum.DEALS}
                                eventLabel={`${deal.name} - ${deal.id}`}
                                eventName={`${GtagNameEnum.MY_DEALS} - ${searchParams.get(DealFilterEnum.MY_ROLE)} - ${searchParams.get(DealFilterEnum.STATE)} - chat button`}
                                textContent={t('Chat')}
                                theme={'stroke'}
                                to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${deal.id}`})}/>
                    </div>
                    <DealLastOfferComponent deal={deal}/>
                </div>
            </div>

        </article>
    );
};
