import React, {FC} from 'react';
import emptyImg from 'assets/images/empty.svg';
import emptyStyle from './Empty.module.scss';


export const EmptyComponent: FC<{ title?: string; text?: string; }> = ({title, text}) => {
    return (
        <div className={emptyStyle.emptyBlank}>
            <img className={emptyStyle.emptyImg} src={emptyImg} width={60} height={60} alt=""/>
            {!!title && <p className={`textHeaderMedium_06 ${emptyStyle.emptyTitle}`}>{title}</p>}
            {!!text && <p className={emptyStyle.emptyText}>{text}</p>}
        </div>
    );
};
