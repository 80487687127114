import React from 'react';
import {useTranslation} from 'react-i18next';
import {RouteObject, useRoutes} from 'react-router-dom';
import {PageTitleComponent} from 'components';
import {FinanceRoutesPathEnum, PageNotFound} from 'core';
import {useCommonSelector} from 'hooks';
import {UserInterface} from 'typings';
import {BalanceHistory, PaymentRequests} from './pages';
import {UserBalanceComponent} from './components';


const Finance = () => {
    const {t} = useTranslation();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const financeRoutes: RouteObject[] = [
        {element: <BalanceHistory/>, index: true},
        {element: <PaymentRequests user={user}/>, path: `${FinanceRoutesPathEnum.PAYMENT_REQUESTS}/*`},
        {element: <PageNotFound/>, path: '/*'},
    ];

    return (
        <>
            <main className="page page_aside-left">
                <PageTitleComponent text={t('Finance')}/>
                <UserBalanceComponent className="blank page__info" user={user}/>
                {useRoutes(financeRoutes)}
            </main>
        </>
    );
};

export {Finance};
export default Finance;
