import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {EmptyComponent, WebsiteCardComponent} from 'components';
import {RootState} from 'core';
import {getCatalogPlacementWebsiteList} from '../../catalog.reducer';
import websiteForPlacementCardStyle from './WebsiteForPlacementCard.module.scss';


export const WebsiteForPlacementCardComponent: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {placement, websiteList, isLoading} = useSelector((state: RootState) => state.catalogReducer);


    useEffect(() => {
        if (!placement) return;
        dispatch(getCatalogPlacementWebsiteList({partnerId: placement.created_by.id}));
    }, [dispatch, placement]);


    return (
        <>
            {!isLoading && <>
                {websiteList.length === 0
                    ? <EmptyComponent title={t('No sites added yet')} text={t('Unfortunately, the user has not yet added information about their sites')}/>
                    : <>
                        <h2 className={`textHeaderMedium_06 ${websiteForPlacementCardStyle.websiteForPlacementCard__title}`}>{t('User placement sites')}</h2>
                        {websiteList.map(website => <WebsiteCardComponent key={website.id} website={website}/>)}
                    </>
                }
            </>}
        </>
    );
};
