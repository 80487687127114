import React, {FC, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Clip} from 'assets/images/dropzone/clip.svg';
import {FileComponent} from '../File/File.component';
import fileDropStyle from './FileDrop.module.scss';
import {FileDropComponentInterface} from './FileDropComponent.interface';


export const FileDropComponent: FC<FileDropComponentInterface> = ({accept, files, handleDelete, handleDrop, hint, maxFiles, multiple = true}) => {
    const {t} = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach(file => handleDrop(file));
    }, []);
    const handleDeleteFile = (fileId) => {
        handleDelete(fileId);
    };
    const handleRefreshFile = (fileId, file) => {
        handleDelete(fileId);
        handleDrop(file);
    };
    const {fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({accept, onDrop, maxFiles, multiple});


    return (
        <div>
            <div {...getRootProps()} className={`${fileDropStyle.fileDropzone} ${isDragActive ? fileDropStyle.fileDropzone_dragActive : ''}`}>
                <input {...getInputProps()} />
                <Clip/>
                <div>{isDragActive ? t('Drag and drop files here') : t('Choose a file or drag it here')}</div>
            </div>
            {!!hint && <p className={`textBody_04 ${fileDropStyle.fileDropzone__hint}`}>{hint}</p>}

            {!!Object.keys(files).length && Object.entries(files).map(([fileKey, file]) => <FileComponent file={file} key={fileKey} onDelete={handleDeleteFile} onRefresh={handleRefreshFile}/>)}

            {!!fileRejections.length && fileRejections.map(({file, errors}) => (
                <p key={file.name} className="form__error">{file.name} — {errors.map(e => e.message).join(', ')}</p>
            ))}
        </div>
    );
};
