import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IDropdownOption} from '@design-system/ui-kit';
import GeosIcon from 'assets/images/icons/geos.svg';
import CategoriesIcon from 'assets/images/icons/categories.svg';
import {useCommonSelector} from 'hooks';
import {WebsiteInterface} from 'typings';
import {getStringArrByMatch} from 'utils';
import websiteCardStyle from './WebsiteCard.module.scss';
import {LinkComponent} from '../Link/Link';


export const WebsiteCardComponent: FC<{ website: WebsiteInterface; }> = ({website}) => {
    const {t} = useTranslation();
    const ref = useRef(null);
    const [category, setCategory] = useState<string[]>([]);
    const [geos, setGeos] = useState<string[]>([]);
    const [showCategory, setShowCategory] = useState<boolean>(false);
    const [showGeos, setShowGeos] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const websiteSubTypesMap = useCommonSelector<any>('websiteSubTypesMap');
    const websiteTypesMap = useCommonSelector<any>('websiteTypesMap');
    const [displayedCategoriesList, setDisplayedCategoriesList] = useState<string[]>([]);
    const [displayedGeosList, setDisplayedGeosList] = useState<string[]>([]);
    const [isShowToggleDescriptionBtn, setIsShowToggleDescriptionBtn] = useState<boolean>(false);
    const lowerArrayLength = 3;
    const arrayLength = 5;


    useEffect(() => {
        setGeos(getStringArrByMatch(geo, website.geos));
        setCategory(getStringArrByMatch(categories, website.categories));
    }, [categories, geo, website]);

    useEffect(() => {
        setDisplayedCategoriesList(category?.slice(0, category?.length > arrayLength ? lowerArrayLength : arrayLength));
    }, [category]);

    useEffect(() => {
        setDisplayedGeosList(geos?.slice(0, geos?.length > arrayLength ? lowerArrayLength : arrayLength));
    }, [geos]);

    useEffect(() => {
        if (!ref.current) return;
        // @ts-ignore
        setIsShowToggleDescriptionBtn(ref.current.scrollHeight > ref.current.clientHeight);
    }, []);


    const toggleGeos = () => {
        let arr = showGeos ? geos.slice(0, geos.length > arrayLength ? lowerArrayLength : arrayLength) : geos.slice();
        setDisplayedGeosList(arr);
        setShowGeos(!showGeos);
    };

    const toggleCategories = () => {
        let arr = showCategory ? category.slice(0, category.length > arrayLength ? lowerArrayLength : arrayLength) : category.slice();
        setDisplayedCategoriesList(arr);
        setShowCategory(!showCategory);
    };

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };


    return (
        <div className={websiteCardStyle.websiteCard}>
            <div className={websiteCardStyle.websiteCardHeader}>
                {!!website.website_type_logo && <img src={website.website_type_logo} alt=""/>}
                <span className={websiteCardStyle.websiteCardType}>{(website.subtype ? websiteSubTypesMap[website.subtype] : websiteTypesMap[website.type])?.text}</span>
                <a href={website.url} className={websiteCardStyle.websiteCardUrl} target="_blank">{website.url}</a>
            </div>
            <div className={websiteCardStyle.websiteCardBody}>
                <h3 className="textHeaderMedium_06">{website.name}</h3>
                <p ref={ref} className={`${websiteCardStyle.websiteCardDescription} ${!showDescription && websiteCardStyle.websiteCardDescription_clipped}`}>{website.description}</p>
                {isShowToggleDescriptionBtn && <LinkComponent onClick={toggleDescription} textContent={showDescription ? t('Show less') : t('Show more')}/>}
                <dl className={websiteCardStyle.websiteCardDl}>
                    <dt className={websiteCardStyle.websiteCardDt}><img className={websiteCardStyle.websiteCardDtIcon} src={GeosIcon} alt={t('Regions')}/>{t('Regions')}</dt>
                    <dd className={websiteCardStyle.websiteCardDd}>
                        {!!geos.length
                            ? <>
                                {displayedGeosList.join(', ')}
                                {geos.length > arrayLength && <LinkComponent onClick={toggleGeos}
                                                                             className={websiteCardStyle.websiteCardToggleBtn}
                                                                             textContent={showGeos ? t('Show less') : t('Show {{val}} more...', {val: geos.length - lowerArrayLength})}/>}
                            </>
                            : <>{t('Worldwide')}</>}
                    </dd>

                    {!!category.length && <>
                        <dt className={websiteCardStyle.websiteCardDt}><img className={websiteCardStyle.websiteCardDtIcon} src={CategoriesIcon} alt={t('Categories')}/>{t('Categories')}</dt>
                        <dd className={websiteCardStyle.websiteCardDd}>
                            {displayedCategoriesList.join(', ')}
                            {category.length > arrayLength && <LinkComponent onClick={toggleCategories}
                                                                             className={websiteCardStyle.websiteCardToggleBtn}
                                                                             textContent={showCategory ? t('Show less') : t('Show {{val}} more...', {val: category.length - lowerArrayLength})}/>}
                        </dd>
                    </>}
                </dl>
            </div>
        </div>
    );
};
