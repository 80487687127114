import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IDropdownOption} from '@design-system/ui-kit';
import {Button, WebsiteCreationFormComponent} from 'components';
import {clearAttachments, deleteAttachment, MainRoutesEnum, RootState, uploadFile, WebsitesRoutesPathsEnum} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, GtagNameEnum, WebsiteFormControlsEnum, WebsiteFormStateType} from 'typings';
import {clearWebsiteFormError, getWebsiteData, postWebsite, putWebsite, resetWebsitesToInitialState} from '../websites.reducer';


export const WebsitesFormComponent: FC<{ isEditMode: boolean, websiteId?: string, className?: string; }> = ({className, isEditMode, websiteId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const initFormState: WebsiteFormStateType = {
        [WebsiteFormControlsEnum.CATEGORIES]: [],
        [WebsiteFormControlsEnum.COVERAGE]: null as unknown as number,
        [WebsiteFormControlsEnum.DESCRIPTION]: '',
        [WebsiteFormControlsEnum.GEOS]: [],
        [WebsiteFormControlsEnum.IS_ACTIVE]: true,
        [WebsiteFormControlsEnum.LOGO]: null as unknown as number,
        [WebsiteFormControlsEnum.NAME]: '',
        [WebsiteFormControlsEnum.TYPE]: null as unknown as number,
        [WebsiteFormControlsEnum.SUBTYPE]: null as unknown as number,
        [WebsiteFormControlsEnum.URL]: '',
    };

    const {files} = useSelector((state: RootState) => state.commonReducer);
    const {currentWebsite, formErrors} = useSelector((state: RootState) => state.websitesReducer);
    const websiteSubTypes: { [key: number]: IDropdownOption[]; } = useCommonSelector<{ [key: number]: IDropdownOption[]; }>('websiteSubTypes');
    const [websiteSubTypesForDropdown, setWebsiteSubTypesForDropdown] = useState<IDropdownOption[]>([]);
    const [formState, setFormState] = useState<WebsiteFormStateType>(initFormState);


    useEffect(() => {
        handleInputChange(WebsiteFormControlsEnum.LOGO);
    }, [files]);

    useEffect(() => {
        if (!websiteId) return;
        dispatch(getWebsiteData({websiteId: websiteId}));
        return () => {
            dispatch(clearAttachments());
            dispatch(resetWebsitesToInitialState());
        };
    }, [dispatch, websiteId]);

    useEffect(() => {
        if (currentWebsite && Object.keys(currentWebsite).length) {
            setFormState({...formState, ...currentWebsite});
            setWebsiteSubTypesForDropdown(websiteSubTypes?.[+currentWebsite.type]);
        } else {
            setFormState(initFormState);
        }
    }, [currentWebsite]);


    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        const formData = formState;
        formData[WebsiteFormControlsEnum.LOGO] = Object.values(files).find(file => file.meta.success)?.uploadedAttachments?.file_id || null as unknown as number;
        return dispatch(isEditMode && websiteId ? putWebsite({data: formData, websiteId}) : postWebsite({data: formData}));
    };

    const handleInputChange = (controlName: WebsiteFormControlsEnum) => {
        dispatch(clearWebsiteFormError(controlName));
    };

    const handleDelete = (fileId) => dispatch(deleteAttachment(fileId));
    const handleDrop = (file) => dispatch(uploadFile({file: file}));


    return (
        <div className={`blank ${className}`}>
            <form className="form"
                  onSubmit={handleFormSubmit}
                  noValidate>
                <WebsiteCreationFormComponent files={files}
                                              formErrors={formErrors}
                                              formState={formState}
                                              handleDelete={handleDelete}
                                              handleDrop={handleDrop}
                                              handleInputChange={handleInputChange}
                                              setFormState={setFormState}
                                              setWebsiteSubTypesForDropdown={setWebsiteSubTypesForDropdown}
                                              websiteSubTypesForDropdown={websiteSubTypesForDropdown}/>
                <div className="controls">
                    <Button eventCategory={GtagCategoryEnum.SITES}
                            eventName={`${isEditMode ? GtagNameEnum.EDIT_WEBSITE : GtagNameEnum.CREATE_WEBSITE} - cancel edit site button`}
                            textContent={t('Cancel')}
                            theme={'stroke'}
                            to={`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.LIST}`}/>
                    <Button disabled={!!Object.keys(formErrors).length}
                            eventCategory={GtagCategoryEnum.SITES}
                            eventName={`${isEditMode ? GtagNameEnum.EDIT_WEBSITE : GtagNameEnum.CREATE_WEBSITE} - edit site button`}
                            textContent={isEditMode ? 'Edit site' : 'Add site'}/>
                </div>
            </form>
        </div>
    );
};
