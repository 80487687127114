import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {DealsRoutesPathEnum} from 'core';
import {PartnerComponent} from '../partner/Partner.component';
import {ChatComponent} from '../chat/Chat.component';
import {DealPlacementInfoComponent} from '../placementInfo/DealPlacementInfo.component';


export const ChatRouter: FC<{ handleRepeatDealMethod: any; updateDataMethod: any; }> = ({handleRepeatDealMethod, updateDataMethod}) => {
    const RoutesConfig = [
        {
            path: `${DealsRoutesPathEnum.DEAL_CHAT}`,
            index: true,
            element: <ChatComponent handleRepeatDealMethod={handleRepeatDealMethod} updateDataMethod={updateDataMethod}/>,
        },
        {
            path: `${DealsRoutesPathEnum.DEAL_PLACEMENT_INFO}`,
            index: true,
            element: <DealPlacementInfoComponent/>,
        },
        {
            path: `${DealsRoutesPathEnum.DEAL_USER}`,
            index: true,
            element: <PartnerComponent/>,
        },
        {
            path: '/*',
            element: <ChatComponent handleRepeatDealMethod={handleRepeatDealMethod} updateDataMethod={updateDataMethod}/>,
        },
    ];

    return useRoutes(RoutesConfig);
};
