import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, ETextAreaThemes, IDropdownOption, Input, TDropdownOptionValue, TextArea, ValidationTooltipWrapper} from '@design-system/ui-kit';
import {ReactComponent as AddIcon} from 'assets/images/icons/add.svg';
import linkIcon from 'assets/images/icons/link.svg';
import {Button, DatePickerInputComponent, FileDropComponent, ResetInputBtnComponent, TotalBlockComponent} from 'components';
import {nonFieldErrorsKey} from 'consts';
import {clearAttachments, deleteAttachment, MainRoutesEnum, PlacementRoutesPathEnum, RootState, uploadFile, WebsitesRoutesPathsEnum} from 'core';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, GtagCategoryEnum, GtagNameEnum, UploadedAttachmentInterface, WebsiteInterface} from 'typings';
import {OfferFormControlsEnum, RequestFormControlsEnum, RequestFormStateType} from '../../typings';
import {clearPlacementFormError, getRequestData, patchRequest, postRequest, resetPlacementToInitialState} from '../../placements.reducer';
import {AttentionModal} from '../attentionModal/AttentionModal.component';
import {PlacementSelectedWebsite} from '../selectedWebsite/PlacementSelectedWebsite.component';
import {PromotionalDateInterface} from './PromotionalDate.interface';


export const RequestFormComponent: FC<{ isEditMode: boolean; placementId?: string; }> = ({isEditMode, placementId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const nullDate: PromotionalDateInterface = {
        [RequestFormControlsEnum.PLACEMENT_START_DATE]: null,
        [RequestFormControlsEnum.PLACEMENT_END_DATE]: null,
    };

    const initFormState: RequestFormStateType = {
        [RequestFormControlsEnum.PLACEMENT_TYPE]: null as unknown as number,
        [RequestFormControlsEnum.ATTACHMENTS]: [],
        [RequestFormControlsEnum.NAME]: '',
        [RequestFormControlsEnum.COVERAGE]: null as unknown as number,
        [RequestFormControlsEnum.DESCRIPTION]: '',
        [RequestFormControlsEnum.PRICE]: '',
        [RequestFormControlsEnum.CURRENCY]: '',
        // [RequestFormControlsEnum.DAYS_TO_IMPLEMENT]: null as unknown as number,
        [RequestFormControlsEnum.PLACEMENT_START_DATE]: null,
        [RequestFormControlsEnum.PLACEMENT_END_DATE]: null,
        [RequestFormControlsEnum.GEOS]: [],
        [RequestFormControlsEnum.IS_PUBLISHED]: false,
        [RequestFormControlsEnum.CATEGORIES]: [],
        [RequestFormControlsEnum.WEBSITE]: null as unknown as number,
        [RequestFormControlsEnum.PLACEMENT_URL]: '',
        [RequestFormControlsEnum.PROMO_FILES_REQUIREMENTS]: '',
    };

    const {files} = useSelector((state: RootState) => state.commonReducer);
    const {websiteList, currentRequest, formErrors} = useSelector((state: RootState) => state.placementReducer);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const currencies: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('currencies');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const placementTypes: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('placementTypes');

    const [currencyForDropdown, setCurrencyForDropdown] = useState<IDropdownOption[]>([]);
    const [categoriesListForDropdown, setCategoriesListForDropdown] = useState<IDropdownOption[]>([]);
    const [placementTypesForDropdown, setPlacementTypesForDropdown] = useState<IDropdownOption[]>([]);
    const [websiteListForDropdown, setWebsiteListForDropdown] = useState<IDropdownOption[]>([]);
    const [supportedGeosList, setSupportedGeosList] = useState<IDropdownOption[]>([]);
    const [selectedWebsite, setSelectedWebsite] = useState<WebsiteInterface | null>(null);
    const [date, setDate] = useState<PromotionalDateInterface>(nullDate);
    const [showModal, setShowModal] = useState(false);
    const [formState, setFormState] = useState<RequestFormStateType>(initFormState);


    useEffect(() => {
        handleInputChange(RequestFormControlsEnum.ATTACHMENTS);
    }, [files]);

    useEffect(() => {
        setCategoriesListForDropdown(categories);
    }, [categories]);

    useEffect(() => {
        setSupportedGeosList(geo);
    }, [geo]);

    useEffect(() => {
        setPlacementTypesForDropdown(placementTypes);
    }, [placementTypes]);

    useEffect(() => {
        setCurrencyForDropdown(currencies);
    }, [currencies]);

    useEffect(() => {
        setFormState({
            ...formState,
            [OfferFormControlsEnum.CURRENCY]: `${currencyForDropdown?.[0]?.value}`,
        });
    }, [currencyForDropdown]);

    useEffect(() => {
        dispatch(getRequestData({placementId: placementId}));
        if (placementId) {
            setFormState({...formState, ...currentRequest});
        } else {
            setFormState(initFormState);
        }
        return () => {
            dispatch(clearAttachments());
            dispatch(resetPlacementToInitialState());
            setSelectedWebsite(null);
            setDate(nullDate);
            setFormState(initFormState);
        };
    }, [dispatch, placementId]);

    useEffect(() => {
        if (!currentRequest || !websiteList) return;

        const activeWebsiteList = websiteList.filter(website => website.is_active || website.id === currentRequest[RequestFormControlsEnum.WEBSITE]);
        setWebsiteListForDropdown(activeWebsiteList.map((website: WebsiteInterface) => ({value: Number(website.id), text: website.name})));

        if (Object.keys(currentRequest).length) {
            setFormState({...formState, ...currentRequest});
            setDate({
                [RequestFormControlsEnum.PLACEMENT_START_DATE]: currentRequest[RequestFormControlsEnum.PLACEMENT_START_DATE] ? new Date(currentRequest[RequestFormControlsEnum.PLACEMENT_START_DATE] as string) : null,
                [RequestFormControlsEnum.PLACEMENT_END_DATE]: currentRequest[RequestFormControlsEnum.PLACEMENT_END_DATE] ? new Date(currentRequest[RequestFormControlsEnum.PLACEMENT_END_DATE] as string) : null,
            });

            const newSelectedWebsite = websiteList.find(website => website.id === currentRequest.website);
            setSelectedWebsite(newSelectedWebsite ?? null);
        }
    }, [currentRequest, websiteList]);


    const handleSaveClick = () => {
        setShowModal(false);
        handleFormSubmit(undefined, true);
    };

    const handleCancelClick = () => {
        setShowModal(true);
    };

    const handleFormSubmit = (event?: FormEvent, draft?: boolean) => {
        event?.preventDefault();
        const formData = formState;
        formData[RequestFormControlsEnum.PLACEMENT_START_DATE] = date[RequestFormControlsEnum.PLACEMENT_START_DATE]?.toLocaleDateString('en-CA') || null;
        formData[RequestFormControlsEnum.PLACEMENT_END_DATE] = date[RequestFormControlsEnum.PLACEMENT_END_DATE]?.toLocaleDateString('en-CA') || null;
        formData[RequestFormControlsEnum.IS_PUBLISHED] = !draft;
        formData[RequestFormControlsEnum.ATTACHMENTS] = Object.values(files).filter(file => file.meta.success).map(fileData => (fileData.uploadedAttachments as UploadedAttachmentInterface).file_id);
        return dispatch(isEditMode && placementId ? patchRequest({data: formData, placementId}) : postRequest({data: formData}));
    };

    const handleInputChange = (controlName: RequestFormControlsEnum) => {
        dispatch(clearPlacementFormError(controlName));
    };

    const onInputChange = (controlName: RequestFormControlsEnum) => (newValue: string | TDropdownOptionValue | null | TDropdownOptionValue[]) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });

        handleInputChange(controlName);
    };

    const onInputWebsiteChange = (newValue: any) => {
        const newSelectedWebsite = websiteList.find(website => website.id === newValue);
        setFormState({
            ...formState,
            [RequestFormControlsEnum.WEBSITE]: newValue,
            [RequestFormControlsEnum.GEOS]: newSelectedWebsite?.geos || [],
            [RequestFormControlsEnum.PLACEMENT_URL]: newSelectedWebsite?.url || '',
        });
        setSelectedWebsite(newSelectedWebsite ?? null);

        handleInputChange(RequestFormControlsEnum.WEBSITE);
        dispatch(clearPlacementFormError(nonFieldErrorsKey));
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };

    const onDateChange = (value: any, controlName: RequestFormControlsEnum) => {
        setDate({
            ...date,
            [controlName]: value,
        });
        handleInputChange(controlName);
    };

    const handleClearUrlClick = () => {
        setFormState({
            ...formState,
            [RequestFormControlsEnum.PLACEMENT_URL]: '',
        });
    };

    const handleDelete = (fileId) => dispatch(deleteAttachment(fileId));
    const handleDrop = (file) => dispatch(uploadFile({file: file}));


    return (
        <div className="blank">
            <form className="form"
                  onSubmit={handleFormSubmit}
                  noValidate>
                <fieldset className="form__blank form__fieldset" id="placement-request-form-site">
                    <p className="textHeader_06 form__legend">{t('Site')}</p>
                    <div className="form__lineWithEndContent">
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[RequestFormControlsEnum.WEBSITE]}
                                      placeholder={t('Choose your site')}
                                      uncheckOptionByClick
                                      optionsListMaxHeight={websiteListForDropdown.length ? 300 : undefined}
                                      onChange={onInputWebsiteChange}
                                      options={websiteListForDropdown}/>
                            {formErrors?.[RequestFormControlsEnum.WEBSITE] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.WEBSITE]}</p>)}
                            {formErrors?.[nonFieldErrorsKey] && (<p className="form__error">{formErrors?.[nonFieldErrorsKey]}</p>)}
                        </div>
                        <Button eventCategory={GtagCategoryEnum.OFFERS}
                                {...isEditMode && {eventLabel: `${currentRequest.name} - ${currentRequest.id}`}}
                                eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.ADVERTISER_OFFERS} - add site button`}
                                Icon={AddIcon}
                                textContent={t('Add site')}
                                to={`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.CREATE}`}/>
                    </div>
                    {selectedWebsite && <PlacementSelectedWebsite selectedWebsite={selectedWebsite}/>}
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-request-form-general">
                    <p className="textHeader_06 form__legend">{t('General information')}</p>
                    <Input name={RequestFormControlsEnum.NAME}
                           placeholder={t('Offer title')}
                           required
                           value={formState[RequestFormControlsEnum.NAME]}
                           onChange={onInputChange(RequestFormControlsEnum.NAME)}
                           errorMessage={formErrors?.[RequestFormControlsEnum.NAME]?.toString()}
                           onBlur={onInputBlur}/>
                    <ValidationTooltipWrapper errorMessage={formErrors?.[RequestFormControlsEnum.DESCRIPTION]?.toString()}>
                        <TextArea placeholder={t('Describe the ad creatives to be used in the placement')}
                                  value={formState[RequestFormControlsEnum.DESCRIPTION]}
                                  theme={formErrors?.[RequestFormControlsEnum.DESCRIPTION] ? ETextAreaThemes.error : ETextAreaThemes.base}
                                  className="textarea"
                                  onChange={onInputChange(RequestFormControlsEnum.DESCRIPTION)}/>
                    </ValidationTooltipWrapper>
                    <div className="form__dropdownWrap">
                        <Dropdown value={formState[RequestFormControlsEnum.CATEGORIES]}
                                  placeholder={t('Categories')}
                                  uncheckOptionByClick
                                  multiple
                                  optionsListMaxHeight={categoriesListForDropdown.length ? 300 : undefined}
                                  onMultipleChange={onInputChange(RequestFormControlsEnum.CATEGORIES)}
                                  options={categoriesListForDropdown}/>
                        {formErrors?.[RequestFormControlsEnum.CATEGORIES] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.CATEGORIES]}</p>)}
                    </div>
                    {/*<div className="form__line">*/}
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[RequestFormControlsEnum.PLACEMENT_TYPE]}
                                      placeholder={t('Placement type')}
                                      required
                                      uncheckOptionByClick
                                      optionsListMaxHeight={placementTypesForDropdown.length ? 300 : undefined}
                                      onChange={onInputChange(RequestFormControlsEnum.PLACEMENT_TYPE)}
                                      options={placementTypesForDropdown}/>
                            {formErrors?.[RequestFormControlsEnum.PLACEMENT_TYPE] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.PLACEMENT_TYPE]}</p>)}
                        </div>
                    {/*    <Input type="number"*/}
                    {/*           name={RequestFormControlsEnum.DAYS_TO_IMPLEMENT}*/}
                    {/*           placeholder={t('Time for agreement (days)')}*/}
                    {/*           value={formState[RequestFormControlsEnum.DAYS_TO_IMPLEMENT]?.toString()}*/}
                    {/*           onChange={onInputChange(RequestFormControlsEnum.DAYS_TO_IMPLEMENT)}*/}
                    {/*           errorMessage={formErrors?.[RequestFormControlsEnum.DAYS_TO_IMPLEMENT]?.toString()}*/}
                    {/*           onBlur={onInputBlur}/>*/}
                    {/*</div>*/}
                    <div className="form__line">
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[RequestFormControlsEnum.GEOS]}
                                      placeholder={t('Countries')}
                                      uncheckOptionByClick
                                      optionsListMaxHeight={supportedGeosList.length ? 300 : undefined}
                                      multiple
                                      onMultipleChange={onInputChange(RequestFormControlsEnum.GEOS)}
                                      options={supportedGeosList}/>
                            {formErrors?.[RequestFormControlsEnum.GEOS] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.GEOS]}</p>)}
                        </div>
                        <Input type="number"
                               name={RequestFormControlsEnum.COVERAGE}
                               placeholder={t('Minimum site coverage required (visitors/mo.)')}
                               required
                               value={formState[RequestFormControlsEnum.COVERAGE]?.toString()}
                               onChange={onInputChange(RequestFormControlsEnum.COVERAGE)}
                               errorMessage={formErrors?.[RequestFormControlsEnum.COVERAGE]?.toString()}
                               onBlur={onInputBlur}/>
                    </div>
                    <Input name={RequestFormControlsEnum.PLACEMENT_URL}
                           placeholder={t('Placement URL')}
                           startContent={<img src={linkIcon} alt=""/>}
                           endContent={<ResetInputBtnComponent handleClick={handleClearUrlClick}/>}
                           value={formState[RequestFormControlsEnum.PLACEMENT_URL]}
                           onChange={onInputChange(RequestFormControlsEnum.PLACEMENT_URL)}
                           errorMessage={formErrors?.[RequestFormControlsEnum.PLACEMENT_URL]?.toString()}
                           onBlur={onInputBlur}/>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-request-form-materials">
                    <p className="textHeader_06 form__legend">{t('Placement creatives')}</p>
                    <ValidationTooltipWrapper errorMessage={formErrors?.[RequestFormControlsEnum.PROMO_FILES_REQUIREMENTS]?.toString()}>
                        <TextArea placeholder={t('Describe the ad creatives to be used in the placement')}
                                  value={formState[RequestFormControlsEnum.PROMO_FILES_REQUIREMENTS]}
                                  theme={formErrors?.[RequestFormControlsEnum.PROMO_FILES_REQUIREMENTS] ? ETextAreaThemes.error : ETextAreaThemes.base}
                                  className="textarea"
                                  onChange={onInputChange(RequestFormControlsEnum.PROMO_FILES_REQUIREMENTS)}/>
                    </ValidationTooltipWrapper>
                    <FileDropComponent files={files} handleDelete={handleDelete} handleDrop={handleDrop}
                                       hint={t('Maximum upload file size: 50 MB. You can upload no more than 10 files at a time')}
                                       maxFiles={10}/>
                    {formErrors?.[RequestFormControlsEnum.ATTACHMENTS] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.ATTACHMENTS]}</p>)}
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-request-form-period">
                    <p className="textHeader_06 form__legend">{t('Placement time')}</p>
                    <p className="form__text">{t('If you need your ad to be placed on exact dates, please specify them. If no dates are selected, placement will be on an ongoing basis.')}</p>
                    <div className="form__lineRange">
                        <DatePickerInputComponent inputName={RequestFormControlsEnum.PLACEMENT_START_DATE}
                                                  onInputChange={onInputChange(RequestFormControlsEnum.PLACEMENT_START_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, RequestFormControlsEnum.PLACEMENT_START_DATE)}
                                                  inputValue={date[RequestFormControlsEnum.PLACEMENT_START_DATE]}
                                                  inputPlaceholder={t('Start date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[RequestFormControlsEnum.PLACEMENT_START_DATE]?.toString()}/>
                        —
                        <DatePickerInputComponent inputName={RequestFormControlsEnum.PLACEMENT_END_DATE}
                                                  onInputChange={onInputChange(RequestFormControlsEnum.PLACEMENT_END_DATE)}
                                                  onDateChange={(newDate: any) => onDateChange(newDate, RequestFormControlsEnum.PLACEMENT_END_DATE)}
                                                  inputValue={date[RequestFormControlsEnum.PLACEMENT_END_DATE]}
                                                  inputPlaceholder={t('End date')}
                                                  onInputBlur={onInputBlur}
                                                  errorMessage={formErrors?.[RequestFormControlsEnum.PLACEMENT_END_DATE]?.toString()}/>
                    </div>
                </fieldset>
                <fieldset className="form__blank form__fieldset" id="placement-request-form-payment">
                    <p className="textHeader_06 form__legend">{t('Financial terms')}</p>
                    <div className="form__lineWithEndContent">
                        <Input type="number"
                               name={RequestFormControlsEnum.PRICE}
                               placeholder={t('Offer price (excl. fee)')}
                               required
                               value={formState[RequestFormControlsEnum.PRICE]}
                               onChange={onInputChange(RequestFormControlsEnum.PRICE)}
                               errorMessage={formErrors?.[RequestFormControlsEnum.PRICE]?.toString()}
                               onBlur={onInputBlur}/>
                        <div className="form__dropdownWrap">
                            <Dropdown value={formState[RequestFormControlsEnum.CURRENCY]}
                                      placeholder={t('Currency')}
                                      required
                                      uncheckOptionByClick
                                      optionsListMaxHeight={currencyForDropdown.length ? 300 : undefined}
                                      onChange={onInputChange(RequestFormControlsEnum.CURRENCY)}
                                      options={currencyForDropdown}/>
                            {formErrors?.[RequestFormControlsEnum.CURRENCY] && (<p className="form__error">{formErrors?.[RequestFormControlsEnum.CURRENCY]}</p>)}
                        </div>
                    </div>
                    {!!formState[RequestFormControlsEnum.PRICE] && <TotalBlockComponent text={t('Total, incl. {{val}}% fee', {val: appConfig.commission_percent * 100})}
                                                                                        currency={formState[RequestFormControlsEnum.CURRENCY]}
                                                                                        amount={Math.round(+formState[RequestFormControlsEnum.PRICE] * (1 + appConfig.commission_percent) * 100) / 100}/>}
                </fieldset>
                <div className="controls controls_align_justify">
                    <Button eventCategory={GtagCategoryEnum.OFFERS}
                            {...isEditMode && {eventLabel: `${currentRequest.name} - ${currentRequest.id}`}}
                            eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.ADVERTISER_OFFERS} - cancel ${isEditMode ? t('edit') : t('add')} offer button`}
                            onClick={handleCancelClick}
                            textContent={t('Cancel')}
                            theme={'stroke'}
                            type="button"/>
                    <Button disabled={!!Object.keys(formErrors).length}
                            eventCategory={GtagCategoryEnum.OFFERS}
                            {...isEditMode && {eventLabel: `${currentRequest.name} - ${currentRequest.id}`}}
                            eventName={`${isEditMode ? GtagNameEnum.CREATE_OFFER : GtagNameEnum.EDIT_OFFER}${GtagNameEnum.ADVERTISER_OFFERS} - ${isEditMode ? t('edit') : t('add')} offer button`}
                            textContent={isEditMode ? t('Save changes') : t('Add offer')}/>
                </div>
            </form>
            <AttentionModal handleSaveClick={handleSaveClick}
                            setShowModal={setShowModal}
                            showModal={showModal}
                            to={`${MainRoutesEnum.PLACEMENTS}${PlacementRoutesPathEnum.REQUEST_LIST}?status=active`}/>
        </div>
    );
};
