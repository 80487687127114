import React, {FC} from 'react';
import {Link, NavLink} from 'react-router-dom';
import classNames from 'classnames/bind';
import {ELoaderSizes} from '@design-system/ui-kit';
import {getEventDimension} from 'utils';
import {CounterComponent} from '../Counter/Counter.component';
import {LoaderWithOverlay} from '../LoaderWithOverlay/LoaderWithOverlay';
import {ButtonInterface} from './Button.interface';
import buttonStyle from './Button.module.scss';


export const Button: FC<ButtonInterface> = (props) => {
    const {className, counter,  eventCategory, eventLabel, eventName, href, Icon, iconRight, isNavLink = false, kind = 'square', isBtnLoading, size = 'xl', textContent, theme = 'fill', to, ...btnProps} = props;
    let template;
    const btnClassName = classNames(
        className,
        buttonStyle.btn,
        [buttonStyle[`btn_kind_${kind}`]],
        [buttonStyle[`btn_size_${size}`]],
        [buttonStyle[`btn_theme_${theme}`]],
    );

    const btnContent = <>
        {!!Icon && <Icon className={iconRight ? buttonStyle.btn__iconRight : buttonStyle.btn__iconLeft}/>}
        {textContent}
        {!!counter && <CounterComponent className={buttonStyle.btn__counter} counter={counter} theme={'stroke'}/>}
    </>;

    const navLinkClassName = (isActive) => classNames(
        buttonStyle.btn,
        className,
        [buttonStyle[`btn_size_${size}`]],
        [buttonStyle[`btn_kind_${kind}`]],
        {
            [buttonStyle.btn_theme_stroke]: !isActive,
            [buttonStyle.btn_theme_fill]: isActive,
        },
    );

    const gtagSendEvent = (e) => {
        gtag('event', eventName, getEventDimension(eventCategory, eventLabel));
        btnProps.onClick?.(e);
    };

    const loader = (isBtnLoading && <LoaderWithOverlay size={ELoaderSizes.s}/>);


    if (to) {
        template = isNavLink
            ? <NavLink to={to} className={({isActive}) => navLinkClassName(isActive)} {...btnProps} {...eventName && {onClick: gtagSendEvent}}>{btnContent}{loader}</NavLink>
            : <Link to={to} className={btnClassName} {...btnProps} {...eventName && {onClick: gtagSendEvent}}>{btnContent}{loader}</Link>;
    } else if (href) {
        template = <a href={href} className={btnClassName} {...btnProps} {...eventName && {onClick: gtagSendEvent}}>{btnContent}{loader}</a>;
    } else {
        template = <button className={btnClassName} {...btnProps} {...eventName && {onClick: gtagSendEvent}}>{btnContent}{loader}</button>;
    }


    return template;
};
