import React, {FC, useEffect} from 'react';
import {i18n} from 'i18n';
import {UserInterface} from 'typings';
import {clientid, getSessionId, getTimeStamp, referrer, uuidv4, webmasterOrAdvertiser} from 'utils';


export const GtagComponent: FC<{ user: UserInterface }> = ({user}) => {
    const pushDimensions = () => {
        let dimension1 = clientid();
        if (typeof dimension1 === 'undefined' || !dimension1) {
            setTimeout(() => {
                pushDimensions();
            }, 500);
        } else {
            gtag('config', gtagIdMain, {
                'custom_map': {
                    'dimension1': 'clientid',
                    'dimension2': 'referrer',
                    'dimension3': 'url_language',
                    'dimension4': 'affiliate_webmaster_id',
                    'dimension5': 'timeStamp',
                    'dimension6': 'sessionId',
                    'dimension8': 'webmaster_or_advertiser',
                    'dimension9': 'uuidv4',
                    'dimension15': 'keycloack_id',

                },
                'clientid': clientid(),
                'referrer': referrer(),
                'url_language': i18n.language,
                'affiliate_webmaster_id': user.id,
                'timeStamp': getTimeStamp(),
                'sessionId': getSessionId(),
                'webmaster_or_advertiser': webmasterOrAdvertiser(user),
                'keycloack_id': user.id,
                'uuidv4': uuidv4(),
                'debug_mode': process.env.REACT_APP_ENVIRONMENT_NAME !== 'prod',
            });
        }
    };

    const pushDimensions2 = () => {
        let dimension1 = clientid();
        if (typeof dimension1 === 'undefined' || !dimension1) {
            setTimeout(() => {
                pushDimensions2();
            }, 500);
        } else {
            gtag('config', gtagIdAdd, {
                'custom_map': {
                    'dimension1': 'clientid',
                    'dimension2': 'referrer',
                    'dimension3': 'url_language',
                    'dimension4': 'affiliate_webmaster_id',
                    'dimension5': 'timeStamp',
                    'dimension6': 'sessionId',
                    'dimension8': 'webmaster_or_advertiser',
                    'dimension15': 'keycloack_id',
                    'dimension9': 'uuidv4',
                },
                'clientid': clientid(),
                'referrer': referrer(),
                'url_language': i18n.language,
                'affiliate_webmaster_id': user.id,
                'timeStamp': getTimeStamp(),
                'sessionId': getSessionId(),
                'webmaster_or_advertiser': webmasterOrAdvertiser(user),
                'keycloack_id': user.id,
                'uuidv4': uuidv4(),
                'debug_mode': process.env.REACT_APP_ENVIRONMENT_NAME !== 'prod',
            });
        }
    };


    useEffect(() => {
        if (!user.id) return;
        if (['prod'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`)) {
            gtag('js', new Date());
            gtag('config', gtagIdMain, {
                    'linker': {
                        'domains': ['adgoal.de', 'admitad.academy', 'convertlink.com', 'admitad.expert', 'tapfiliate.com', 'getuniq.me', 'admitad.com', 'checkscan.ru', 'univibes.org'],
                    },
                    send_page_view: false,
                },
            );

            pushDimensions();

            gtag('config', gtagIdAdd, {
                    'linker': {
                        'domains': ['adgoal.de', 'admitad.academy', 'convertlink.com', 'admitad.expert', 'tapfiliate.com', 'getuniq.me', 'admitad.com', 'checkscan.ru', 'univibes.org'],
                    },
                    send_page_view: false,
                },
            );
            pushDimensions2();
        }
    }, [user]);


    return <></>;
};
