import {ENotificationsThemes} from '@design-system/ui-kit';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {FallbackErrorTextConst} from 'consts';
import {getUser, notificationListPushItem, store} from 'core';
import {i18n} from 'i18n/config';
import {SendRequestParamsInterface} from 'typings';


export const sendRequest = async <ResponseInterface, RequestBodyInterface = undefined>({
                                                                                           requestHeaders,
                                                                                           request,
                                                                                           callbacks,
                                                                                       }: SendRequestParamsInterface<RequestBodyInterface>) => {
    try {
        const requestParams: AxiosRequestConfig<RequestBodyInterface> = {
            headers: {
                'Content-Type': 'application/json',
                // 'Accept-Language': i18n.language,
                'Accept-Language': 'en',
                ...requestHeaders,
            },
            ...request,
        };
        const response: AxiosResponse<ResponseInterface> = await axios(requestParams);
        return response.data;
    } catch (error) {
        if (error.message === 'canceled') {
            //TODO: return
            return;
        }
        const {handleServiceError} = callbacks;

        if (error.response?.status === 401 || error.response?.status === 403) {
            store.dispatch(getUser());
        }

        if (error.response?.status > 400 && error.response?.status < 500) {
            handleServiceError();
        }

        if (error.response?.status === 400) {
            throw (error.response.data);
        }

        const errorText = error.response?.status === 500
            ? `${i18n.t('Service is unavailable')}: ${error.message})`
            : error?.request
                ? `${i18n.t('Could not process response from the server')}: ${error.message}`
                : `${i18n.t('Caught an internal unhandled exception')}: ${error.message || error}`;

        store.dispatch(notificationListPushItem({
            id: Date.now().toString(),
            title: FallbackErrorTextConst,
            description: `${errorText}`,
            theme: ENotificationsThemes.critical,
        }));
        throw new Error(errorText);
    }
};
