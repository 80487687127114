import {RequestPayloadType} from 'typings';
import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';
import {CatalogPlacementsResponseInterface} from './typings';


export const getCatalogOfferListRequest = async (payload: RequestPayloadType<any>): Promise<CatalogPlacementsResponseInterface | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('catalog/placements/'), payload);
    return await sendRequest<CatalogPlacementsResponseInterface>(sendRequestParams);
};

export const getCatalogOfferRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placements/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const createCatalogOfferDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placements/${payload.routeParams.id}/create_deal_v2/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getCatalogRequestListRequest = async (payload: RequestPayloadType<any>): Promise<CatalogPlacementsResponseInterface | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('catalog/placement_requests/'), payload);
    return await sendRequest<CatalogPlacementsResponseInterface>(sendRequestParams);
};

export const getCatalogRequestRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placement_requests/${payload.routeParams.id}/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const createCatalogRequestDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placement_requests/${payload.routeParams.id}/create_deal_v2/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getCatalogOfferDealListRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placements/${payload.routeParams.placementId}/deals/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getCatalogRequestDealListRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placement_requests/${payload.routeParams.placementId}/deals/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const changeVisibilityOfferDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'PATCH';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placements/${payload.routeParams.placementId}/deals/${payload.routeParams.dealId}/change_visibility/`), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const changeVisibilityRequestDealRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any> => {
    payload.method = 'PATCH';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/placement_requests/${payload.routeParams.placementId}/deals/${payload.routeParams.dealId}/change_visibility/`), payload);
    return await sendRequest<any>(sendRequestParams);
};
