import {INotificationsItem} from '@design-system/ui-kit';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NotificationListStoreInterface} from './typings';


const defaultNotificationState: NotificationListStoreInterface = {
    notificationIdList: [],
    notificationList: [],
};


export const notificationSlice = createSlice({
    name: 'notification',
    initialState: defaultNotificationState,
    reducers: {
        notificationListPushItem: (state, action: PayloadAction<INotificationsItem>) => {
            const newNotification = action.payload;
            return {
                ...state,
                notificationList: [
                    ...state.notificationList,
                    newNotification,
                ],
                notificationIdList: [
                    ...state.notificationIdList,
                    newNotification.id,
                ],
            };
        },
        notificationListSetItems: (state, action: PayloadAction<INotificationsItem[]>) => ({...state, notificationList: action.payload}),
        notificationListDeleteItem: (state, action: PayloadAction<string>) => {
            let notificationList = state.notificationList.filter(item => item.id !== action.payload);
            return {...state, notificationList};
        },
        notificationListDeleteItemId: (state, action: PayloadAction<string>) => {
            let notificationIdList = state.notificationIdList.filter(id => id !== action.payload);
            return {...state, notificationIdList};
        },
    },
});

export const {notificationListPushItem, notificationListSetItems, notificationListDeleteItem, notificationListDeleteItemId} = notificationSlice.actions;

export const notificationListReducer = notificationSlice.reducer;
