import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {LoaderWithOverlay, PageTitleComponent} from 'components';
import {RootState} from 'core';
import {WebsitesFormComponent} from '../../components';


export const WebsiteCreate: FC = () => {
    const {t} = useTranslation();
    const {websiteId} = useParams();
    const isEditMode: boolean = !!websiteId;
    const {isLoading} = useSelector((state: RootState) => state.websitesReducer);


    return (
        <main className="page page_aside-right">
            <PageTitleComponent text={isEditMode ? t('Edit my site') : t('Add my site')}/>
            <WebsitesFormComponent isEditMode={isEditMode} websiteId={websiteId} className="page__content"/>
            {/*<aside className="blank page__aside page__aside_sticky">*/}
            {/*    <h2 className="textHeader_04">{t('Any questions?')}</h2>*/}
            {/*    /!*TODO: href*!/*/}
            {/*    <Button href={'/'} textContent={t('More information')} theme={'stroke'}/>*/}
            {/*</aside>*/}
            {isLoading && <LoaderWithOverlay/>}
        </main>
    );
};
