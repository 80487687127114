import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IDropdownOption} from '@design-system/ui-kit';
import {ReactComponent as BellIcon} from 'assets/images/bell.svg';
import {useCommonSelector, useMedia} from 'hooks';
import {OfferInterface, PlacementType, RequestInterface, WebsiteInterface} from 'typings';
import {getStringArrByMatch} from 'utils';
import placementTabStyle from './PlacementTab.module.scss';
import {AttachmentsPreviewComponent} from '../AttachmentsPreview/AttachmentsPreview.component';
import {CardDescriptionListComponent} from './cardDescriptionList/CardDescriptionList.component';
import {LinkComponent} from '../Link/Link';


export const PlacementTabComponent: FC<{ attachmentsLink?: string; isRequest?: boolean; placement: PlacementType; }> = ({attachmentsLink, children, isRequest = false, placement}) => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const categories: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('categories');
    const geo: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('geo');
    const [geosPlacement, setGeosPlacement] = useState<string[]>([]);
    const [category, setCategory] = useState<string[]>([]);


    useEffect(() => {
        if (!placement) return;
        setGeosPlacement(getStringArrByMatch(geo, placement.geos));
        setCategory(getStringArrByMatch(categories, placement.categories || placement.website?.categories || []));//TODO
    }, [categories, geo, placement]);


    return (
        <>
            {!!placement && <>
                <p className={placementTabStyle.placementTabDescription}>{placement.description}</p>
                {isMobile && children}
                <CardDescriptionListComponent categoryList={category}
                                              geoList={geosPlacement}
                                              placementUrl={isRequest ? (placement as Omit<RequestInterface<WebsiteInterface>, 'website_name'>).placement_url : ''}/>
                {!!placement.promo_files_requirements && <>
                    <h3 className={`textHeaderMedium_06 ${placementTabStyle.placementTabSubtitle}`}>{t('Creatives required')}</h3>
                    {!isRequest && <div className={placementTabStyle.placementTabPromoRequired}>
                        <BellIcon/>
                        {(placement as Omit<OfferInterface<WebsiteInterface>, 'website_name'>).is_promo_files_required
                            ? t('Ad creatives are required')
                            : t('Ad creatives are not required')}
                    </div>}
                    <p className={placementTabStyle.placementTabDescription}>{placement.promo_files_requirements}</p>
                </>}
                {!!placement.uploaded_attachments.length && <>
                    <h3 className={`textHeaderMedium_06 ${placementTabStyle.placementTabSubtitle}`}>{isRequest ? t('Ad creatives') : t('Examples of creatives')}</h3>
                    <AttachmentsPreviewComponent attachments={placement.uploaded_attachments}/>
                    {isMobile && attachmentsLink && <div className={placementTabStyle.placementTabAttachmentsLink}>
                        <LinkComponent className="word-break" download href={attachmentsLink} textContent={`${placement.name}_attachments.zip`}/>
                    </div>}
                </>}
            </>}
        </>
    );
};
