export enum GtagNameEnum {
    ADVERTISER_OFFER = 'advertiser offers',
    CATALOG = 'catalog',
    CREATE_OFFER = 'create offer',
    CREATE_WEBSITE = 'create website',
    DEAL = 'deal',
    EDIT_OFFER = 'edit offer',
    EDIT_WEBSITE = 'edit website',
    FINANCE = 'finance',
    MY_DEALS = 'my deals',
    MY_OFFERS = 'my offers',
    MY_SITES = 'my sites',
    PLACEMENT = 'placement',
    WEBMASTER_OFFER = 'webmaster offers',

    ADVERTISER_OFFERS = ' - advertiser offers',
    BALANCE_HISTORY = ' - balance history',
    CHAT = ' - chat',
    FILTER = ' - filter',
    LEFT_MENU = ' - left menu',
    STATUS_OFFERS = ' - status offers',
    TYPE_DEALS = ' - type deals',
    TYPE_OFFERS = ' - type offers',
    WEBMASTER_OFFERS = ' - webmaster offers',

    CLICK = ' - click',
    SEARCH = ' - search',
    SORT_BY = ' - sort by',
}
