import {all, call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {MainRoutesEnum, notificationListPushItem, postWebsiteRequest, setInitialAttachments, WebsitesRoutesPathsEnum} from 'core';
import {i18n} from 'i18n';
import {history} from 'index';
import {GtagCategoryEnum, GtagNameEnum, RequestPayloadType, WebsitesResponseInterface} from 'typings';
import {getEventDimension} from 'utils';
import {deleteWebsiteRequest, getWebsiteListRequest, getWebsiteRequest, putWebsiteRequest} from './websites.api';
import {
    deleteWebsite,
    getWebsiteData,
    getWebsiteList,
    hideWebsitesError,
    hideWebsitesLoader,
    postWebsite,
    putWebsite,
    setWebsite,
    setWebsiteFormError,
    setWebsites,
    setWebsitesCount,
    showWebsitesError,
    showWebsitesLoader,
} from './websites.reducer';


function* createWebsiteWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideWebsitesError());
        yield put(showWebsitesLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        yield call(postWebsiteRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('The site has been added successfully'),
            theme: ENotificationsThemes.success,
        }));
        gtag('event', `${GtagNameEnum.CREATE_WEBSITE} - create success`, getEventDimension(GtagCategoryEnum.SITES));
        history.push(`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.LIST}`);
    } catch (error) {
        yield put(setWebsiteFormError(error));
    } finally {
        yield put(hideWebsitesLoader());
    }
}

function* deleteWebsiteWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideWebsitesError());
        yield put(showWebsitesLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.websiteId},
        };
        yield call(deleteWebsiteRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('The site has been deleted successfully'),
            theme: ENotificationsThemes.success,
        }));
        yield put(getWebsiteList({queryParams: payload.payload.queryParams, controller: new AbortController()}));
    } finally {
        yield put(hideWebsitesLoader());
    }
}

function* editWebsiteWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideWebsitesError());
        yield put(showWebsitesLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.websiteId},
            data: payload.payload.data,
        };
        yield call(putWebsiteRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('The site has been successfully changed'),
            theme: ENotificationsThemes.success,
        }));
        history.push(`${MainRoutesEnum.WEBSITES}${WebsitesRoutesPathsEnum.LIST}`);
    } catch (error) {
        yield put(setWebsiteFormError(error));
    } finally {
        yield put(hideWebsitesLoader());
    }
}

function* getWebsiteDataWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideWebsitesError());
        yield put(showWebsitesLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.websiteId},
        };
        const [websiteData] = yield all([
            ...(payload.payload.websiteId ? [payload.payload.websiteId && call(getWebsiteRequest, requestParams)] : []),
        ]);
        yield put(setWebsite(websiteData));
        yield put(setInitialAttachments({attachments: websiteData.uploaded_logo}));
    } finally {
        yield put(hideWebsitesLoader());
    }
}

function* getWebsiteListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideWebsitesError());
        yield put(showWebsitesLoader());
        const requestParams: RequestPayloadType<any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data: WebsitesResponseInterface = yield call(getWebsiteListRequest, requestParams);
        if (data) {
            yield put(setWebsitesCount(data.count));
            yield put(setWebsites(data.results));
            yield put(hideWebsitesLoader());
        }
    } catch (error) {
        yield put(showWebsitesError());
        yield put(hideWebsitesLoader());
    }
}


export function* websitesWatcher() {
    yield takeEvery(postWebsite.type, createWebsiteWorker);
    yield takeEvery(deleteWebsite.type, deleteWebsiteWorker);
    yield takeEvery(putWebsite.type, editWebsiteWorker);
    yield takeEvery(getWebsiteData.type, getWebsiteDataWorker);
    yield takeEvery(getWebsiteList.type, getWebsiteListWorker);
}
