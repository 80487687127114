import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IDropdownOption} from '@design-system/ui-kit';
import {ReactComponent as DoneIcon} from 'assets/images/done.svg';
import {ReactComponent as ErrorIcon} from 'assets/images/error.svg';
import {AttachmentsPreviewComponent, AvatarComponent, Button, LinkComponent, PartnerNameComponent} from 'components';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, BalanceInterface, ChangeRequestStatusEnum, CommentInterface, DealInterface, SystemMessageStyleEnum, WebSocketEventsEnum} from 'typings';
import {ChatMsgInterface, CommentTypeEnum} from '../../typings';
import {CommentComponent} from '../comment/Comment.component';
import {DealFormComponent} from '../dealForm/DealForm.component';
import {updateChat} from '../../deals.reducer';
import chatStyle from './Chat.module.scss';


export const ChatComponent: FC<{ handleRepeatDealMethod: any; updateDataMethod: any; }> = ({handleRepeatDealMethod, updateDataMethod}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const balances: BalanceInterface[] = useCommonSelector<BalanceInterface[]>('balances');
    const currencies: IDropdownOption[] = useCommonSelector<IDropdownOption[]>('currencies');
    const {files} = useSelector((state: RootState) => state.commonReducer);
    const {comments, deal, formErrors, isResetForm, isChatLoading, isShowForm} = useSelector((state: RootState) => state.dealsReducer);
    const {lastMsg} = useSelector((state: RootState) => state.commonReducer);

    const [chatMsgs, setChatMsgs] = useState<ChatMsgInterface[]>([]);

    const handleShowMsgClick = (elId: number) => {
        const targetEl = document.getElementById(`${elId}`);
        if (targetEl) targetEl.scrollIntoView({behavior: 'smooth'});
    };


    useEffect(() => {
        if (!comments) return;
        setChatMsgs(comments.reduce((arr: ChatMsgInterface[], comment: CommentInterface) => {
            const newComment: ChatMsgInterface = {...comment, day: null, type: null, user: null};
            const prevComment = arr.at(-1);
            const day = new Date(`${comment.created_at}Z`).toLocaleDateString();
            newComment.day = day;
            newComment.user = (prevComment?.created_by.id !== comment.created_by.id || prevComment?.day !== day) ? `${comment.created_by.first_name[0]}${comment.created_by.last_name[0]}` : null;
            if (prevComment?.day !== day) {
                arr.push({...newComment, type: CommentTypeEnum.DAY});
            }
            const systemMessageType = comment.system_message_style === SystemMessageStyleEnum.SUCCESS ? CommentTypeEnum.SYSTEM_SUCCESS : CommentTypeEnum.SYSTEM;
            newComment.type = comment.is_system ? systemMessageType : CommentTypeEnum.MESSAGE;

            arr.push(newComment);
            return arr;
        }, []).reverse());
    }, [comments]);

    useEffect(() => {
        if (!lastMsg) return;
        if (lastMsg.event === WebSocketEventsEnum.NEW_MESSAGE && lastMsg.event_payload?.deal_id === deal?.id) {
            dispatch(updateChat({dealId: String(deal?.id)}));
        }
    }, [lastMsg]);


    const msgsComponent = {
        [CommentTypeEnum.DAY]: comment => <p className={`textBodyMedium_02 ${chatStyle.commentDate}`} key={`DAY_${comment.id}`} id={`DAY_${comment.id}`}>{comment.day}</p>,
        [CommentTypeEnum.MESSAGE]: (comment, isImplementer) =>
            <article className={`${chatStyle.comment} ${comment.user ? chatStyle.comment_withUserLogo : ''}`} key={comment.id} id={comment.id}>
                {comment.user && <AvatarComponent className={chatStyle.commentName} isImplementer={comment.is_implementer} isResizable text={comment.user}/>}
                {comment.change_request
                    ? <CommentComponent attachments={comment.uploaded_attachments}
                                        className={chatStyle.commentChangeRequest}
                                        currency={deal?.currency || ''}
                                        deal={deal as DealInterface}
                                        status={comment.change_request.status}
                                        placementEndDate={comment.change_request.suggested_placement_end_date}
                                        placementStartDate={comment.change_request.suggested_placement_start_date}
                                        price={isImplementer ? comment.change_request.suggested_price : comment.change_request.suggested_price_with_commission}
                                        priceInclFee={!isImplementer}
                                        showMakeDealBtn={!comment.is_mine && comment.change_request.status === ChangeRequestStatusEnum.PENDING}
                                        text={comment.text}
                                        updateDataMethod={updateDataMethod}/>
                    : <div className={chatStyle.commentMsg}>
                        <AttachmentsPreviewComponent attachments={comment.uploaded_attachments}/>
                        {comment.text}
                    </div>}
                <span className={`textBody_04 ${chatStyle.commentTime}`}>{new Date(`${comment.created_at}Z`).getHours()}.{String(new Date(`${comment.created_at}Z`).getMinutes()).padStart(2, '0')}</span>
            </article>,
        [CommentTypeEnum.SYSTEM]: comment => <div className={chatStyle.commentSystem} key={comment.id} id={comment.id}>{comment.text}</div>,
        [CommentTypeEnum.SYSTEM_SUCCESS]: comment => <div className={chatStyle.commentSystemAccepted} key={comment.id} id={comment.id}>
            {comment.text}
            {comment.related_message && <LinkComponent onClick={() => handleShowMsgClick(comment.related_message)} textContent={t('Show message')}/>}
            <DoneIcon className={chatStyle.commentSystemAccepted__icon}/>
        </div>,
    };


    return (
        <>
            {deal && <>
                <PartnerNameComponent className={chatStyle.chatPartnerInfo}
                                      avatarText={`${deal.companion_info.first_name[0]}${deal.companion_info.last_name[0]}`}
                                      name={`${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`}
                                      isImplementer={deal.companion_info.id === deal.implementer.id}/>
                <div className={chatStyle.chat}>
                    {deal.is_possible_to_repeat_deal && <div className={chatStyle.commentSystemRepeat}>
                        <ErrorIcon width={24} height={24}/>
                        <p className={chatStyle.commentSystemRepeat__title}>
                            {t('Did you like the work of the {{name}}?', {name: `${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`})}
                        </p>
                        <p className={chatStyle.commentSystemRepeat__text}>
                            {t('If you enjoyed working with this {{name}}, you can afford a new deal.', {name: `${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`})}
                        </p>
                        <Button className={chatStyle.commentSystemRepeat__btn} onClick={handleRepeatDealMethod} textContent={t('Offer a new deal')}/>
                    </div>}
                    {!chatMsgs.length && <div className={chatStyle.commentSystemEmpty}>
                        {t('This is the beginning of your conversation with {{name}}', {name: `${deal.companion_info.first_name} ${deal.companion_info.last_name[0]}.`})}
                    </div>}
                    {chatMsgs.map((comment) => msgsComponent[comment.type as CommentTypeEnum](comment, deal.companion_info.id === deal.payer.id))}
                </div>
                <DealFormComponent appConfig={appConfig}
                                   balances={balances}
                                   currencies={currencies}
                                   deal={deal}
                                   files={files}
                                   formErrors={formErrors}
                                   isResetForm={isResetForm}
                                   isChatLoading={isChatLoading}
                                   isShowForm={isShowForm}/>
            </>}
        </>
    );
};
