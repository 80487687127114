import React, {FC} from 'react';
import {Trans} from 'react-i18next';
import WelcomeImg from 'assets/images/onboarding/welcome.svg';
import {useCommonSelector} from 'hooks';
import {AppConfigInterface, ProjectNameEnum} from 'typings';
import headerStyle from './OnboardingHeader.module.scss';


export const OnboardingHeaderComponent: FC<{ text: string; }> = ({text}) => {
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const headerTransMap = {
        [ProjectNameEnum.I_NEED_COLLAB]: 'onBoardingWelcomeINeedCollab',
        [ProjectNameEnum.MARKETPLACE]: 'onBoardingWelcomeMarketplace',
    };


    return (
        <div className={headerStyle.header}>
            <img className={headerStyle.headerImg} src={WelcomeImg} alt=""/>
            <h1 className={`textHeaderMedium_04 ${headerStyle.headerTitle}`}>
                <Trans i18nKey={headerTransMap[appConfig.project_name || ProjectNameEnum.MARKETPLACE]}>Welcome to <strong>Marketplace X</strong></Trans>
            </h1>
            <p className={headerStyle.headerText}>{text}</p>
        </div>
    );
};
