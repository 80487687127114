import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, Link} from 'react-router-dom';
import {DealsRoutesPathEnum, MainRoutesEnum} from 'core';
import {useCommonSelector} from 'hooks';
import {DealEventsInterface} from 'typings';
import {Button} from '../../../Button/Button';
import {CounterComponent} from '../../../Counter/Counter.component';
import style from './TooltipEventsContent.module.scss';
import {TooltipEventsContentComponentInterface} from './TooltipEventsContentComponent.interface';


export const TooltipEventsContentComponent: FC<TooltipEventsContentComponentInterface> = ({handleMarkAllAsReadOnclick, handleMsgOnclick}) => {
    const {t} = useTranslation();
    const dealEventsList: DealEventsInterface[] = useCommonSelector<DealEventsInterface[]>('dealEventsList');


    return (
        <div className={style.tooltipEvent__content}>
            {dealEventsList.length
                ? <>
                    <div className={style.tooltipEvent__wrap}>
                        {dealEventsList.map(dealEvents => <Link key={dealEvents.id}
                                                                className={`word-break ${style.tooltipEvent}`}
                                                                to={generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: `${dealEvents.id}`})}
                                                                onClick={handleMsgOnclick(dealEvents)}>
                            <CounterComponent className={style.tooltipEvent__counter} counter={dealEvents.events.length}/>
                            <span className={`textBodyMedium_03 ${style.tooltipEvent__title}`}>{dealEvents.name}</span>
                            <span className={`textBody_04 ${style.tooltipEvent__partnerName}`}>{dealEvents.companion_info.first_name} {dealEvents.companion_info.last_name[0]}.</span>
                            <span className={`textBody_03 ${style.tooltipEvent__text}`}>{dealEvents.events[0].title}</span>
                        </Link>)}
                    </div>
                    <Button onClick={handleMarkAllAsReadOnclick}
                            theme={'ghost_cancel'}
                            textContent={t('Mark all as read')}
                            size={'l'}/>
                </>
                : <div className={style.tooltipEvent__empty}>There are no new events</div>}
        </div>
    );
};
