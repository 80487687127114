import {generatePath} from 'react-router-dom';
import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from '@design-system/ui-kit';
import {FallbackErrorTextConst} from 'consts';
import {DealsRoutesPathEnum, getDealPartnerRequest, getOfferRequest, getRequestRequest, MainRoutesEnum, notificationListPushItem} from 'core';
import {i18n} from 'i18n';
import {history} from 'index';
import {RequestPayloadType} from 'typings';
import {CatalogPlacementsResponseInterface} from './typings';
import {
    changeVisibilityOfferDealRequest,
    changeVisibilityRequestDealRequest,
    createCatalogOfferDealRequest,
    createCatalogRequestDealRequest,
    getCatalogOfferDealListRequest,
    getCatalogOfferListRequest,
    getCatalogOfferRequest,
    getCatalogRequestDealListRequest,
    getCatalogRequestListRequest,
    getCatalogRequestRequest,
} from './catalog.api';
import {
    changeVisibilityOfferDeal,
    changeVisibilityRequestDeal,
    createCatalogOfferDeal,
    createCatalogRequestDeal,
    getCatalogOffer,
    getCatalogOfferDealList,
    getCatalogOfferList,
    getCatalogPlacementWebsiteList,
    getCatalogRequest,
    getCatalogRequestDealList,
    getCatalogRequestList,
    getOffer,
    getRequest,
    hideCatalogError,
    hideCatalogLoader,
    setCatalogOffer,
    setCatalogOfferDealList,
    setCatalogOfferList,
    setCatalogOfferListCount,
    setCatalogPlacementWebsiteList,
    setCatalogRequest,
    setCatalogRequestDealList,
    setCatalogRequestList,
    setCatalogRequestListCount,
    showCatalogError,
    showCatalogLoader,
} from './catalog.reducer';


function* changeVisibilityOfferDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId, placementId: payload.payload.placementId},
            data: {is_hidden: payload.payload.isHidden},
        };
        yield call(changeVisibilityOfferDealRequest, requestParams);
        yield put(getCatalogOfferDealList(payload.payload));
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Deal visibility has been successfully changed'),
            theme: ENotificationsThemes.success,
        }));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideCatalogLoader());
    }
}


function* changeVisibilityRequestDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {dealId: payload.payload.dealId, placementId: payload.payload.placementId},
            data: {is_hidden: payload.payload.isHidden},
        };
        yield call(changeVisibilityRequestDealRequest, requestParams);
        yield put(getCatalogRequestDealList(payload.payload));
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Deal visibility has been successfully changed'),
            theme: ENotificationsThemes.success,
        }));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideCatalogLoader());
    }
}


function* createOfferDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(createCatalogOfferDealRequest, requestParams);
        history.push(generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: data.deal_id}), {isShowSuggestionForm: payload.payload.isShowSuggestionForm});
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* createRequestDealWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(createCatalogRequestDealRequest, requestParams);
        history.push(generatePath(`${MainRoutesEnum.DEALS}${DealsRoutesPathEnum.DEAL}${DealsRoutesPathEnum.DEAL_CHAT}`, {dealId: data.deal_id}), {isShowSuggestionForm: payload.payload.isShowSuggestionForm});
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(getCatalogOfferRequest, requestParams);
        yield put(setCatalogOffer(data));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogOfferDealListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            routeParams: {placementId: payload.payload.placementId},
            signal: payload.payload.controller?.signal,
        };
        const data: any = yield call(getCatalogOfferDealListRequest, requestParams);
        yield put(setCatalogOfferDealList(data.results));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogOfferListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data: CatalogPlacementsResponseInterface = yield call(getCatalogOfferListRequest, requestParams);
        if (data) {
            yield put(setCatalogOfferList(data.results as any));
            yield put(setCatalogOfferListCount(data.count));
            yield put(hideCatalogLoader());
        }
    } catch (error) {
        yield put(showCatalogError());
        yield put(hideCatalogLoader());
    }
}

function* getPlacementWebsiteListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {partnerId: payload.payload.partnerId},
        };
        const data: any = yield call(getDealPartnerRequest, requestParams);
        yield put(setCatalogPlacementWebsiteList(data.websites));
        // } catch (error) {
        //   yield put(showDealsError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogRequestWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(getCatalogRequestRequest, requestParams);
        yield put(setCatalogRequest(data));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogRequestDealListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            routeParams: {placementId: payload.payload.placementId},
            signal: payload.payload.controller?.signal,
        };
        const data: any = yield call(getCatalogRequestDealListRequest, requestParams);
        yield put(setCatalogRequestDealList(data.results));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getCatalogRequestListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const data: CatalogPlacementsResponseInterface = yield call(getCatalogRequestListRequest, requestParams);
        if (data) {
            yield put(setCatalogRequestList(data.results as any));
            yield put(setCatalogRequestListCount(data.count));
            yield put(hideCatalogLoader());
        }
    } catch (error) {
        yield put(showCatalogError());
        yield put(hideCatalogLoader());
    }
}

function* getOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(getOfferRequest, requestParams);
        yield put(setCatalogOffer(data));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}

function* getRequestWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(hideCatalogError());
        yield put(showCatalogLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            routeParams: {id: payload.payload.placementId},
        };
        const data: any = yield call(getRequestRequest, requestParams);
        yield put(setCatalogRequest(data));
    } catch (error) {
        yield put(showCatalogError());
    } finally {
        yield put(hideCatalogLoader());
    }
}


export function* catalogWatcher() {
    yield takeEvery(changeVisibilityOfferDeal.type, changeVisibilityOfferDealWorker);
    yield takeEvery(changeVisibilityRequestDeal.type, changeVisibilityRequestDealWorker);
    yield takeEvery(createCatalogOfferDeal.type, createOfferDealWorker);
    yield takeEvery(createCatalogRequestDeal.type, createRequestDealWorker);
    yield takeEvery(getCatalogOffer.type, getCatalogOfferWorker);
    yield takeEvery(getCatalogOfferDealList.type, getCatalogOfferDealListWorker);
    yield takeEvery(getCatalogOfferList.type, getCatalogOfferListWorker);
    yield takeEvery(getCatalogPlacementWebsiteList.type, getPlacementWebsiteListWorker);
    yield takeEvery(getCatalogRequest.type, getCatalogRequestWorker);
    yield takeEvery(getCatalogRequestDealList.type, getCatalogRequestDealListWorker);
    yield takeEvery(getCatalogRequestList.type, getCatalogRequestListWorker);
    yield takeEvery(getOffer.type, getOfferWorker);
    yield takeEvery(getRequest.type, getRequestWorker);
}
