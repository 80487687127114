import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WebsiteFormControlsEnum, WebsiteFormErrorsType, WebsiteInterface} from 'typings';
import {WebsitesStoreInterface} from './typings';


const defaultWebsitesState: WebsitesStoreInterface = {
    currentWebsite: {} as WebsiteInterface,
    formErrors: {},
    isError: false,
    isLoading: false,
    websites: [],
};

export const websiteSlice = createSlice({
    name: 'website',
    initialState: defaultWebsitesState,
    reducers: {
        resetWebsitesToInitialState: () => defaultWebsitesState,
        setWebsites: (state, action: PayloadAction<WebsiteInterface[]>) => ({...state, websites: action.payload}),
        setWebsitesCount: (state, action: PayloadAction<number>) => ({...state, websitesCount: action.payload}),
        setWebsite: (state, action: PayloadAction<WebsiteInterface>) => {
            return {...state, currentWebsite: action.payload};
        },
        showWebsitesError: state => ({...state, isError: true}),
        hideWebsitesError: state => ({...state, isError: false}),
        showWebsitesLoader: state => ({...state, isLoading: true}),
        hideWebsitesLoader: state => ({...state, isLoading: false}),
        setWebsiteFormError: (state, action: PayloadAction<WebsiteFormErrorsType>) => ({...state, formErrors: action.payload}),
        clearWebsiteFormError: (state, action: PayloadAction<WebsiteFormControlsEnum>) => {
            const controlName: WebsiteFormControlsEnum = action.payload;
            const newFormError = controlName ? {...state.formErrors} : {};
            delete newFormError?.[controlName];
            return {...state, formErrors: newFormError};
        },

        getWebsiteList(state, action: PayloadAction<{ queryParams: URLSearchParams, controller: AbortController }>) {},
        getWebsiteData(state, action: PayloadAction<{ websiteId: string }>) {},
        postWebsite(state, action: PayloadAction<{ data: any; }>) {},
        putWebsite(state, action: PayloadAction<{ data: any; websiteId: string; }>) {},
        deleteWebsite(state, action: PayloadAction<{ websiteId: string, queryParams: any }>) {},
    },
});

export const {
    clearWebsiteFormError,
    deleteWebsite,
    getWebsiteData,
    getWebsiteList,
    hideWebsitesError,
    hideWebsitesLoader,
    postWebsite,
    putWebsite,
    resetWebsitesToInitialState,
    setWebsite,
    setWebsiteFormError,
    setWebsites,
    setWebsitesCount,
    showWebsitesError,
    showWebsitesLoader,
} = websiteSlice.actions;

export const websitesReducer = websiteSlice.reducer;
