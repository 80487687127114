import {RouteObject} from 'react-router-dom';
import {PageNotFound} from 'core/PageNotFound/PageNotFound';
import {CatalogRoutesPathEnum} from 'core/Routes/typings/enums/CatalogRoutesPath.enum';
import {CatalogOfferList, CatalogRequestList, OfferDealList, OfferPage, RequestDealList, RequestPage} from '../pages';


export const catalogRoutes: RouteObject[] = [
    {
        element: <CatalogOfferList/>,
        index: true,
    },
    {
        element: <OfferPage/>,
        path: `${CatalogRoutesPathEnum.CATALOG_OFFER}/*`,
    },
    {
        element: <CatalogRequestList/>,
        path: CatalogRoutesPathEnum.CATALOG_REQUEST_LIST,
    },
    {
        element: <RequestPage/>,
        path: `${CatalogRoutesPathEnum.CATALOG_REQUEST}/*`,
    },
    {
        element: <OfferDealList/>,
        path: CatalogRoutesPathEnum.CATALOG_OFFER_DEAL_LIST,
    },
    {
        element: <RequestDealList/>,
        path: CatalogRoutesPathEnum.CATALOG_REQUEST_DEAL_LIST,
    },
    {element: <PageNotFound/>, path: '/*'},
];
