import React, {FC} from 'react';
import FilePlaceholderIcon from 'assets/images/filePlaceholder.svg';
import {UploadedAttachmentInterface} from 'typings';
import {formatBytes} from 'utils';
import placementAttachmentsPreviewStyle from './AttachmentsPreview.module.scss';


export const AttachmentsPreviewComponent: FC<{ attachments: UploadedAttachmentInterface[]; }> = ({attachments}) => {
    return (
        <div className={placementAttachmentsPreviewStyle.attachmentContainer}>
            {attachments.map(file => <a href={file.file_url} download key={file.file_id} className={placementAttachmentsPreviewStyle.attachmentBlock}>
                {file.type?.includes('image')
                    ? <img className={placementAttachmentsPreviewStyle.attachmentImg} src={file.file_url} loading="lazy" alt={file.name}/>
                    : <img className={placementAttachmentsPreviewStyle.attachmentImgPlaceholder} src={FilePlaceholderIcon} alt={file.name}/>
                }
                <div className={placementAttachmentsPreviewStyle.attachmentBottom}>
                    <span className={`textBodyMedium_04 ${placementAttachmentsPreviewStyle.attachmentName}`}>{file.name}</span>
                    <span className={`textBody_04 ${placementAttachmentsPreviewStyle.attachmentSize}`}>{formatBytes(file.size)}</span>
                </div>
            </a>)}
        </div>
    );
};
