import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ELoaderSizes, Loader} from '@design-system/ui-kit';
import {Button, EmptyComponent} from 'components';
import {RootState} from 'core';
import {useCommonSelector} from 'hooks';
import {GtagCategoryEnum, UserInterface, UserRoleEnum} from 'typings';
import {getSiteList, setCurrentStep} from '../../onboarding.reducer';
import onboardingStyle from '../../styles/Onboarding.module.scss';
import {StepEnum} from '../../typings';
import {ProgressComponent} from '../progress/Progress.component';
import {SiteListComponent} from '../siteList/SiteList.component';


export const Step1Component: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user: UserInterface = useCommonSelector<UserInterface>('user');
    const {isLoading, role, siteList} = useSelector((state: RootState) => state.onboardingReducer);

    const handleToggleStepClick = (step) => () => {
        dispatch(setCurrentStep(step));
    };

    useEffect(() => {
        const controller = new AbortController();
        dispatch(getSiteList({controller}));
        return () => {
            controller.abort();
        };
    }, [dispatch]);


    return (
        <>
            <h1 className={`textHeaderMedium_05 ${onboardingStyle.title}`}>{t('That\'s an easy start!')}</h1>
            <ProgressComponent step={1}/>
            <h2 className={`textBodyMedium_01 ${onboardingStyle.subtitle}`}>{t('Great! Sites moved')}</h2>
            <p className={onboardingStyle.text}>{t('They just need to be moderated, and we can move on. You can delete some of them.')}</p>

            {isLoading && <Loader inline noMargin={false} size={ELoaderSizes.s}/>}

            {(!!siteList?.length && !isLoading) && <SiteListComponent/>}
            {(!siteList?.length && !isLoading) && <EmptyComponent text={t('Oops. We haven\'t found any sites')}/>}

            <div className={`controls controls_align_justify ${onboardingStyle.controls}`}>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME} ${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'}`}
                        eventLabel="back button"
                        eventName="move website - add website"
                        onClick={handleToggleStepClick(user.id ? StepEnum.WELCOME_USER : StepEnum.WELCOME)}
                        textContent={t('Back')}
                        theme={'stroke'}/>
                <Button eventCategory={`${GtagCategoryEnum.WELCOME} ${role === UserRoleEnum.PAYER ? 'advertiser' : 'webmaster'}`}
                        eventLabel="next button"
                        eventName="move website - add website"
                        onClick={handleToggleStepClick(StepEnum.STEP_2)}
                        textContent={t('Next')}/>
            </div>
        </>
    );
};
